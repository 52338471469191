import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';

import OrientationBlocker from '../components/checkOrientation';

import { AppStyled, Top, Middle, Bottom, Container } from '../utils/common-styles';
import { colors } from '../utils/variables';
import Context from '../utils/context';
import gameData from '../utils/gameData';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';
import Button from '../components/button';
import Icon from '../components/Icon';

import illuScoreLeft from '../assets/images/score-left.svg';
import illuScoreRight from '../assets/images/score-right.svg';

const Scores = () => {

  const { gameProgress } = useContext(Context);
  const [totalScore, setTotalScore] = useState(0);
  const [totalMaxScore, setTotalMaxScore] = useState(0);
  const [scores, setScores] = useState({});
  const [scoresCategories, setScoresCategories] = useState({});

  const [detailInformations, setDetailInformations] = useState(false);
  const [detailCommunication, setDetailCommunication] = useState(false);
  const [detailProduction, setDetailProduction] = useState(false);
  const [detailResolution, setDetailResolution] = useState(false);
  const [detailConnaissance, setDetailConnaissance] = useState(false);

  const computeMaxScore = () => {
    let totalMaxScoreTemp = 0;
    let totalMaxScoreObjectTemp = {
      "rechercher": {
        "max": 0,
        "points": 0,
      },
      "naviguer": {
        "max": 0,
        "points": 0,
      },
      "gerer": {
        "max": 0,
        "points": 0,
      },
      "interagir": {
        "max": 0,
        "points": 0,
      },
      "partager": {
        "max": 0,
        "points": 0,
      },
      "creer": {
        "max": 0,
        "points": 0,
      },
      "modifier": {
        "max": 0,
        "points": 0,
      },
      "creatif": {
        "max": 0,
        "points": 0,
      },
      "critique": {
        "max": 0,
        "points": 0,
      },
      "numerique": {
        "max": 0,
        "points": 0,
      }
    };

    gameData.map(chapter => {
      chapter.games.map(game => {
        game.orders.map(order => {
          order.score.map(score => {
            totalMaxScoreObjectTemp[score.label].max = totalMaxScoreObjectTemp[score.label].max + score.points;
            totalMaxScoreTemp = totalMaxScoreTemp + score.points;
          });
        });
      });
    });

    setTotalMaxScore(totalMaxScoreTemp);
    // console.log('totalMaxScoreTemp', totalMaxScoreTemp, totalMaxScoreObjectTemp);
    return totalMaxScoreObjectTemp;
  }

  const computeScore = (maxScoreObj) => {
    // console.log('computeScore gameProgress', gameProgress);
    // console.log('computeScore gameData', gameData);
    let totalScoreTemp = 0;
    let totalMaxScoreTemp = 0;

    const categoriesScore = {
      "informations": {
        "max": 0,
        "points": 0
      },
      "communication": {
        "max": 0,
        "points": 0
      },
      "production": {
        "max": 0,
        "points": 0
      },
      "resolution": {
        "max": 0,
        "points": 0
      },
      "connaissance": {
        "max": 0,
        "points": 0
      }
    };
    const computedScore = maxScoreObj;
    // const computedScore = {
    //   "rechercher": {
    //     "max": 55,
    //     "points": 0,
    //   },
    //   "naviguer": {
    //     "max": 55,
    //     "points": 0,
    //   },
    //   "gerer": {
    //     "max": 55,
    //     "points": 0,
    //   },
    //   "interagir": {
    //     "max": 40,
    //     "points": 0,
    //   },
    //   "partager": {
    //     "max": 40,
    //     "points": 0,
    //   },
    //   "creer": {
    //     "max": 0,
    //     "points": 0,
    //   },
    //   "modifier": {
    //     "max": 10,
    //     "points": 0,
    //   },
    //   "creatif": {
    //     "max": 40,
    //     "points": 0,
    //   },
    //   "critique": {
    //     "max": 40,
    //     "points": 0,
    //   },
    //   "numerique": {
    //     "max": 40,
    //     "points": 0,
    //   }
    // };


    
    gameProgress.map((chapter, chapterIndex) => {
      if(chapter.status === "progress" || chapter.status === "completed") {
        chapter.games.map((game, gameIndex) => {
          if(game.status === "progress" || game.status === "completed") {
            game.orders.map((order, orderIndex) => {
              if(order.status === "success") {
                const orderData = gameData[chapterIndex].games[gameIndex].orders[orderIndex];
                const orderProgress = order;

                const timeTotal = orderData.allowedTime;
                const timeLeft = orderProgress.timeLeft;
                const timeUsed = timeTotal - timeLeft;

                const scoreDivisor = (timeUsed < timeTotal / 6) ? 1 : (timeUsed < timeTotal / 4) ? 1.5 : 3;

                const orderScores = orderData.score;

                orderScores.map((orderScoreItem) => {
                  computedScore[orderScoreItem.label].points = computedScore[orderScoreItem.label].points + (orderScoreItem.points / scoreDivisor);
                  totalScoreTemp = totalScoreTemp + (orderScoreItem.points / scoreDivisor);
                });
                
              }
            })
          }
        });
      }

      setTotalScore(totalScoreTemp);
    });

    categoriesScore["informations"].points = computedScore["rechercher"].points + computedScore["naviguer"].points +  computedScore["gerer"].points;
    categoriesScore["informations"].max = computedScore["rechercher"].max + computedScore["naviguer"].max + computedScore["gerer"].max;

    categoriesScore["communication"].points = computedScore["interagir"].points + computedScore["partager"].points;
    categoriesScore["communication"].max = computedScore["interagir"].max + computedScore["partager"].max;

    categoriesScore["production"].points = computedScore["creer"].points + computedScore["modifier"].points;
    categoriesScore["production"].max = computedScore["creer"].max + computedScore["modifier"].max;

    categoriesScore["resolution"].points = computedScore["creatif"].points + computedScore["critique"].points;
    categoriesScore["resolution"].max = computedScore["creatif"].max + computedScore["critique"].max;

    categoriesScore["connaissance"].points = computedScore["numerique"].points;
    categoriesScore["connaissance"].max = computedScore["numerique"].max;

    setScoresCategories(categoriesScore);
    setScores(computedScore);
  };

  useEffect(() => {
    computeScore(computeMaxScore());
  }, []);

  

  return (
    <OrientationBlocker>
      <Top>
        <Container>
          <Header />
        </Container>
      </Top>
      <Middle>
        <Container>
          
          <ScoreHeader>
            <h2 className="title">Votre digiscore est actuellement de</h2>
            <span className="score">{totalScore} <small>/{totalMaxScore}</small><Icon name="star" /></span>
            {/* <Button fn={() => (alert('download badge'))}>
              Télécharger mon badge de score
            </Button> */}
          </ScoreHeader>

          <ScoreCategory showDetail={detailInformations}>
            <div className="header">
              <div className="item">
                <div className="item-inner">
                  <h3 className="item-title">Information</h3>
                  <span className="item-score"><span>{(scoresCategories["informations"] && scoresCategories["informations"].points) ? scoresCategories["informations"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scoresCategories["informations"] && scoresCategories["informations"].max && scoresCategories["informations"].points) ? (scoresCategories["informations"].points/scoresCategories["informations"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>
            </div>
            <div className="detail">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Rechercher</h3>
                  <span className="item-score"><span>{(scores["rechercher"] && scores["rechercher"].points) ? scores["rechercher"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["rechercher"] && scores["rechercher"].max && scores["rechercher"].points) ? (scores["rechercher"].points/scores["rechercher"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Naviguer</h3>
                  <span className="item-score"><span>{(scores["naviguer"] && scores["naviguer"].points) ? scores["naviguer"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["naviguer"] && scores["naviguer"].max && scores["naviguer"].points) ? (scores["naviguer"].points/scores["naviguer"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Gérer</h3>
                  <span className="item-score"><span>{(scores["gerer"] && scores["gerer"].points) ? scores["gerer"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["gerer"] && scores["gerer"].max && scores["gerer"].points) ? (scores["gerer"].points/scores["gerer"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="toggle" onClick={() => {setDetailInformations(!detailInformations)}}>
              <span className="hided">Afficher</span><span className="showed">Cacher</span> le détail <Icon name="arrow-down" />
            </div>
          </ScoreCategory>

          <ScoreCategory showDetail={detailCommunication}>
            <div className="header">

              <div className="item">
                <div className="item-inner">
                  <h3 className="item-title">Communication</h3>
                  <span className="item-score"><span>{(scoresCategories["communication"] && scoresCategories["communication"].points) ? scoresCategories["communication"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scoresCategories["communication"] && scoresCategories["communication"].max && scoresCategories["communication"].points) ? (scoresCategories["communication"].points/scoresCategories["communication"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="detail">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Interagir</h3>
                  <span className="item-score"><span>{(scores["interagir"] && scores["interagir"].points) ? scores["interagir"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["interagir"] && scores["interagir"].max && scores["interagir"].points) ? (scores["interagir"].points/scores["interagir"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Partager</h3>
                  <span className="item-score"><span>{(scores["partager"] && scores["partager"].points) ? scores["partager"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["partager"] && scores["partager"].max && scores["partager"].points) ? (scores["partager"].points/scores["partager"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="toggle" onClick={() => {setDetailCommunication(!detailCommunication)}}>
              <span className="hided">Afficher</span><span className="showed">Cacher</span> le détail <Icon name="arrow-down" />
            </div>
          </ScoreCategory>

          <ScoreCategory showDetail={detailProduction}>
            <div className="header">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Production</h3>
                  <span className="item-score"><span>{(scoresCategories["production"] && scoresCategories["production"].points) ? scoresCategories["production"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scoresCategories["production"] && scoresCategories["production"].max && scoresCategories["production"].points) ? (scoresCategories["production"].points/scoresCategories["production"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="detail">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Créer</h3>
                  <span className="item-score"><span>{(scores["creer"] && scores["creer"].points) ? scores["creer"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["creer"] && scores["creer"].max && scores["creer"].points) ? (scores["creer"].points/scores["creer"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Modifier</h3>
                  <span className="item-score"><span>{(scores["modifier"] && scores["modifier"].points) ? scores["modifier"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["modifier"] && scores["modifier"].max && scores["modifier"].points) ? (scores["modifier"].points/scores["modifier"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="toggle" onClick={() => {setDetailProduction(!detailProduction)}}>
              <span className="hided">Afficher</span><span className="showed">Cacher</span> le détail <Icon name="arrow-down" />
            </div>
          </ScoreCategory>

          <ScoreCategory showDetail={detailResolution}>
            <div className="header">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Résolution de problèmes</h3>
                  <span className="item-score"><span>{(scoresCategories["resolution"] && scoresCategories["resolution"].points) ? scoresCategories["resolution"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scoresCategories["resolution"] && scoresCategories["resolution"].max && scoresCategories["resolution"].points) ? (scoresCategories["resolution"].points/scoresCategories["resolution"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="detail">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Usage créatif</h3>
                  <span className="item-score"><span>{(scores["creatif"] && scores["creatif"].points) ? scores["creatif"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["creatif"] && scores["creatif"].max && scores["creatif"].points) ? (scores["creatif"].points/scores["creatif"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Usage critique</h3>
                  <span className="item-score"><span>{(scores["critique"] && scores["critique"].points) ? scores["critique"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["critique"] && scores["critique"].max && scores["critique"].points) ? (scores["critique"].points/scores["critique"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="toggle" onClick={() => {setDetailResolution(!detailResolution)}}>
              <span className="hided">Afficher</span><span className="showed">Cacher</span> le détail <Icon name="arrow-down" />
              
            </div>
          </ScoreCategory>

          <ScoreCategory showDetail={detailConnaissance}>
            <div className="header">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Connaissance</h3>
                  <span className="item-score"><span>{(scoresCategories["connaissance"] && scoresCategories["connaissance"].points) ? scoresCategories["connaissance"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scoresCategories["connaissance"] && scoresCategories["connaissance"].max && scoresCategories["connaissance"].points) ? (scoresCategories["connaissance"].points/scoresCategories["connaissance"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="detail">

              <div className="item">
                
                <div className="item-inner">
                  <h3 className="item-title">Connaissances numériques</h3>
                  <span className="item-score"><span>{(scores["numerique"] && scores["numerique"].points) ? scores["numerique"].points : ""}</span> <Icon name="star" /></span>
                  <span className="item-bar">
                    <span className="item-progress" style={
                      {width: ((scores["numerique"] && scores["numerique"].max && scores["numerique"].points) ? (scores["numerique"].points/scores["numerique"].max*100) : 0) + '%' }
                    }></span>
                  </span>
                </div>
              </div>

            </div>
            <div className="toggle" onClick={() => {setDetailConnaissance(!detailConnaissance)}}>
              <span className="hided">Afficher</span><span className="showed">Cacher</span> le détail <Icon name="arrow-down" />
              
            </div>
          </ScoreCategory>

        </Container>
      </Middle>
      <Bottom>
        <Container>
          <Navigation />
        </Container>
      </Bottom>
      
    </OrientationBlocker>
  );
};

const ScoreHeader = styled.div`
  border-radius: 15px;
  background: ${colors.yellow};
  padding: 10px;
  margin-bottom: 30px;

  .title {
    display: block;
    text-align: center;
    color: ${colors.darkblue};
    font-size: 17px;
    font-weight: 500;
  }

  .score {
    display: flex;
    text-align: center;
    color: ${colors.darkblue};
    font-size: 26px;
    font-weight: 600;
    padding: 18px 0;
    align-items: center;
    justify-content: center;

    small {
      font-size: 19px;
      margin-left: 6px;
    }

    svg {
      margin-top: -8px;
      margin-left: 5px;
      color: ${colors.lightblue};
    }

    /* &:before,
    &:after {
      content: '';
      display: inline-block;
      background: transparent none no-repeat 50% 50%;
      background-size: 100% 100%;
    }

    &:before {
      background-image: url(${illuScoreLeft});
      height: 47px;
      width: 27px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    &:after {
      background-image: url(${illuScoreRight});
      height: 50px;
      width: 35px;
      margin-left: 5px;
    } */
  }

  a {
    font-size: 17px;
  }
`;

const ScoreCategory = styled.div`
  border-radius: 15px;
  border: 1px solid ${colors.line};
  box-shadow: 0 5px 0 ${colors.line};
  margin-bottom: 20px;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    &-icon {
      display: block;
      width: 50px;
      height: 50px;
      background: ${colors.line};
      margin-right: 10px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      flex: 1;
    }

    &-title {
      font-size: 16px;
      font-weight: 500;
      color: ${colors.darkblue};
      margin: 0;
    }

    &-bar {
      position: relative;
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background: ${colors.line} none;
      margin-top: 15px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: white;
      }

      &::before {
        left: calc(100% / 3);
      }

      &::after {
        right: calc(100% / 3);
      }
    }

    &-progress {
      display: block;
      border-radius: 4px;
      background: ${colors.yellow} none;
      height: 8px;
      width: 0%;
      transition: all .4s .3s ease-in-out;
    }

    &-score {
      display: inline-flex;
      align-items: center;
      color: ${colors.darkblue};

      > span {
        margin-top: 3px;

        &:empty {
          &::before {
            content: '---';
            color: ${colors.darkblue20};
          }
        }
      }

      svg {
        height: auto;
        fill: ${colors.lightblue};
        margin-left: 4px;
      }
    }
  }

  .header {
    border-bottom: 1px solid ${colors.line};
    padding: 20px 20px 0;
    
    .item-title {
      font-size: 17px;
    }
  }

  .detail {
    padding: 20px 20px 0;
    overflow: hidden;
    transition: all .4s ease-in-out;
    max-height: ${props => props.showDetail ? '600px' : '0px'};
    padding-top: ${props => props.showDetail ? '20px' : '0px'};
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkblue};
    border-top: 1px solid ${colors.line};
    margin-top: -1px;

    svg {
      height: 12px;
      margin-left: 5px;

      transform: rotate(${props => props.showDetail ? '180deg' : '0deg'});
    }

    span {
      margin-right: 4px;
    }

    .hided {
      display: ${props => props.showDetail ? 'none' : 'inline-block'};
    }

    .showed {
      display: ${props => props.showDetail ? 'inline-block' : 'none'};
    }
  }
`;

export default Scores;

import React from 'react';

const clickableArea = {
    "order1Screen1": (
        <svg width="375" height="655" viewBox="0 0 375 655" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0" width="375" height="57" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen1": (
        <svg width="375" height="592" viewBox="0 0 375 592" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0" width="375" height="110" fill="#05FF00"  data-none="none"/>
            <rect x="0" y="200" width="375" height="76" fill="#05FF00" data-none="none" />
            <rect y="315" width="375" height="44" fill="#FF0000" />
        </svg>
    ),
    "order3Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="561" width="375" height="93" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="223" width="375" height="210" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="283" width="375" height="44" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen2": (
        <svg width="375" height="648" viewBox="0 0 375 648" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="400" width="375" height="48" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="73" width="375" height="62" fill="#D9D9D9"/>
            <rect y="150" width="375" height="250" fill="#D9D9D9" data-none="none"/>
        </svg>
    )
};

const Area = (props) => {
    const child = clickableArea[props.name];
    const newChild = React.cloneElement(child, {
        className: `clickable-area`,
        preserveAspectRatio: 'xMinYMin meet',
        onClick: props.clickOutOfArea,
        children: React.Children.map(child.props.children, (subchild => {
            return React.cloneElement(subchild, {
                className: 'area',
                onClick: props.handleClick
            })
        }))
    });
    
    return newChild;
}

export default Area;
import { useContext } from 'react';
import styled from 'styled-components';

import Context from '../utils/context';
import gameData from '../utils/gameData';
import { colors } from '../utils/variables';
import Button from './button';

const GameOrder = ({chapterIndex, gameIndex, orderIndex, fnStartOrder}) => {
    const { gameProgress } = useContext(Context);

    return (
        <GameOrderStyled>
            <h2 className="title">{gameData[chapterIndex].games[gameIndex].orders[orderIndex].title}</h2>
            <Button 
                fn={() => {
                    fnStartOrder();
                }}
            >
                Démarrer le test
            </Button>
        </GameOrderStyled>
    )
};

const GameOrderStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .title {
        font-size: 26px;
        font-weight: 600;
        color: ${colors.darkblue};
        text-align: center;
        padding: 0 20px;
        line-height: 1.5em;
    }


`;

export default GameOrder;
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import useCountDown from 'react-countdown-hook';
import { useNavigate } from "react-router-dom";

import {sendEvent} from '../../utils/ga';
import OrientationBlocker from '../../components/checkOrientation';

import Context from '../../utils/context';
import { AppStyled, GameTop, GameMiddle, Container } from '../../utils/common-styles';
import { colors } from '../../utils/variables';
import gameData from '../../utils/gameData';
import GameHeader from '../../components/Layout/GameHeader';
import Button from '../../components/button';
import Icon from '../../components/Icon';
import Toast from '../../components/toast';

import GameOrder from '../../components/gameOrder';
import GameTimerBar from '../../components/gameTimerBar';

const GameHoc = ({chapterIndex, gameIndex, GameComponent}) => {
  const navigate = useNavigate();
  const { updateStatus, reduceTries, gameProgress } = useContext(Context);

  const [orderIndex, setOrderIndex] = useState(0);
  const [remainingOrderIndexs, setRemainingOrderIndexs] = useState(false);
  const [gameProgression, setGameProgression] = useState(false);

  const [gameSuccessToast, setGameSuccessToast] = useState(false);
  const [gameFailedToast, setGameFailedToast] = useState(false);

  const [gameHelpToast, setGameHelpToast] = useState(false);
  const [showHelpIcon, setShowHelpIcon] = useState(false);

  const purcentageHurryUp = 40;

  let initialTime;

  if(gameData[chapterIndex].games[gameIndex].orders[orderIndex]) {
    initialTime = gameData[chapterIndex].games[gameIndex].orders[orderIndex].allowedTime || 0;
  }
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime || 0, 200);
  
  
  useEffect(() => {
    setOrderIndex(nextOrder());

  }, []);

  useEffect(() => {

    const remainingOrders = gameProgress[chapterIndex].games[gameIndex].orders.map((o, oi) => {
      //return (o.status === "none" || o.status === "passed" || (o.status === "failed" && (o.remainingTries === undefined || o.remainingTries > 0))) ? oi : false;
      //return (o.status === "none" || (o.status === "failed" && (o.remainingTries === undefined || o.remainingTries > 0))) ? oi : false;
      return o.status === "none" ? oi : null;
    }).filter((o) => o !== null);

    // console.log('remainingOrders ICI', remainingOrders, remainingOrders.length === 0,remainingOrders === false, remainingOrders.length === 0 || remainingOrders === false);
    if(remainingOrders.length === 0 || remainingOrders === false) {
      // console.log('ICI 1');
      updateStatus('completed', chapterIndex, gameIndex);
      getNextGame();
    } else {
      // console.log('ICI 2');
      initialTime = gameData[chapterIndex].games[gameIndex].orders[orderIndex].allowedTime;
      updateStatus('progress', chapterIndex);
      updateStatus('progress', chapterIndex, gameIndex);
      resetGameState();
      setGameProgression(false);
    }
    // console.log('useEffect orderIndex', orderIndex);
    // updateStatus('progress', chapterIndex);
    // updateStatus('progress', chapterIndex, gameIndex);
  }, [orderIndex]);
  
  useEffect(() => {
    if(gameProgression === true && timeLeft === 0) {
      timeout();
    }
  }, [timeLeft]);

  const resetGameState = () => {
    setGameSuccessToast(false);
    setGameFailedToast(false);
  }

  const setAllRemainingOrders = () => {
    
    const remainingOrders = gameProgress[chapterIndex].games[gameIndex].orders.map((o, oi) => {
      //return (o.status === "none" || o.status === "passed" || (o.status === "failed" && (o.remainingTries === undefined || o.remainingTries > 0))) ? oi : false;
      //return (o.status === "none" || (o.status === "failed" && (o.remainingTries === undefined || o.remainingTries > 0))) ? oi : false;
      return o.status === "none" ? oi : null;
    }).filter((o) => o !== null);
    // console.log('setAllRemainingOrders', remainingOrders);
    setRemainingOrderIndexs(remainingOrders);
    if(remainingOrders.length === 0) {
      // console.log('ICI completed');
      updateStatus('completed', chapterIndex, gameIndex);
      // updateStatus('completed', chapterIndex);
      getNextGame();
      // navigate('/', { replace: true });
    }
    return remainingOrders;
  }

  const nextOrder = (force) => {
    const remainingOrderIndexsTemps = setAllRemainingOrders();
    if(!force) {
      return (remainingOrderIndexsTemps.length > 0) ? remainingOrderIndexsTemps[0] : false;
    } else {
      if(remainingOrderIndexsTemps.indexOf(orderIndex) + 1 !== undefined) {
        return remainingOrderIndexsTemps[remainingOrderIndexsTemps.indexOf(orderIndex) + 1];
      } else {
        return false
      }
    }
    
  }

  const timeout = () => {
    updateStatus('failed', chapterIndex, gameIndex, orderIndex, timeLeft);
    setGameFailedToast(true);
    sendEvent({
      event:"end",
      chapter_id: chapterIndex,
      game_id: gameIndex,
      question_id: orderIndex,
      time_limit: initialTime,
      time_spent: initialTime - timeLeft,
      timeout: true,
      success: false,
      skip: false
    });
  }

  const getNextGame = () => {
    // console.log('====> getNextGame', chapterIndex, gameIndex);
    if(gameData[chapterIndex].games[gameIndex + 1] && gameProgress[chapterIndex].games[gameIndex + 1].status !== "completed") {
      // console.log('====> A');
      updateStatus('progress', chapterIndex, gameIndex + 1);
      navigate("/endgame", {
        replace: true, 
        state: { 
          chapterEnded: false,
          gameEnded: true,
          currentChapter: chapterIndex,
          currentGame: gameIndex,
          nextGame: {
            chapter: chapterIndex,
            game: gameIndex + 1
          },
        } 
      });
      // return gameData[chapterIndex].games[gameIndex + 1];
    } else if (gameData[chapterIndex + 1] && gameData[chapterIndex + 1].games[0]) {
      // console.log('====> B');
      updateStatus('completed', chapterIndex, gameIndex);
      updateStatus('completed', chapterIndex);
      // console.log('update');
      navigate("/endgame", {
        replace: true, 
        state: { 
          chapterEnded: true,
          gameEnded: true,
          currentChapter: chapterIndex,
          currentGame: gameIndex,
          nextGame: {
            chapter: chapterIndex + 1,
            game: 0
          },
        } 
      });
      
    } else {
      // console.log('====> C');
      updateStatus('completed', chapterIndex, gameIndex);
      updateStatus('completed', chapterIndex);
      navigate("/endgame", {
        replace: true, 
        state: { 
          chapterEnded: true,
          gameEnded: true,
          currentChapter: chapterIndex,
          currentGame: gameIndex,
          nextGame: false,
        } 
      });
    }
  }

  const onWinOrderWithoutFeedback = () => {
    sendEvent({
      event:"end",
      chapter_id: chapterIndex,
      game_id: gameIndex,
      question_id: orderIndex,
      time_limit: initialTime,
      time_spent: initialTime - timeLeft,
      timeout: false,
      success: true,
      skip: false
    });
    pause();
    
    updateStatus('success', chapterIndex, gameIndex, orderIndex, timeLeft);
    setOrderIndex(nextOrder());
  }

  const onWinOrder = () => {
    sendEvent({
      event:"end",
      chapter_id: chapterIndex,
      game_id: gameIndex,
      question_id: orderIndex,
      time_limit: initialTime,
      time_spent: initialTime - timeLeft,
      timeout: false,
      success: true,
      skip: false
    });
    pause();
    
    updateStatus('success', chapterIndex, gameIndex, orderIndex, timeLeft);
    setGameSuccessToast(true);
  }

  const onFailOrderWithoutFeedback = () => {
    sendEvent({
      event:"end",
      chapter_id: chapterIndex,
      game_id: gameIndex,
      question_id: orderIndex,
      time_limit: initialTime,
      time_spent: initialTime - timeLeft,
      timeout: false,
      success: false,
      skip: false
    });
    pause();
    
    updateStatus('failed', chapterIndex, gameIndex, orderIndex, timeLeft);
    setOrderIndex(nextOrder(true));
  }

  const onFailOrder = () => {
    sendEvent({
      event:"end",
      chapter_id: chapterIndex,
      game_id: gameIndex,
      question_id: orderIndex,
      time_limit: initialTime,
      time_spent: initialTime - timeLeft,
      timeout: false,
      success: false,
      skip: false
    });
    pause();
    
    updateStatus('failed', chapterIndex, gameIndex, orderIndex, timeLeft);
    setGameFailedToast(true);
  }

  const generateGameInterface = () => {
    if(initialTime === undefined && orderIndex === undefined) {
      // console.log('generateGameInterface no initialTime and no orderIndex');
      //navigate("/", { replace: true });
      // alert('BUUUUUUUUGGGGGGG');
      return null;
    } else {
      // console.log('generateGameInterface all existing');
      // updateStatus('progress', chapterIndex);
      // updateStatus('progress', chapterIndex, gameIndex);
    }
      

    return (
      <GameWrapper className={(timeLeft*100/initialTime) < purcentageHurryUp ? 'skip-showed' : ''}>
        <GameContainer>
            <GameComponent 
              orderIndex={orderIndex}
              onWinOrderWithoutFeedback={onWinOrderWithoutFeedback}
              onWinOrder={onWinOrder}
              onFailOrderWithoutFeedback={onFailOrderWithoutFeedback}
              onFailOrder={onFailOrder}
            />
        </GameContainer>

        <GameTimerBar purcentageLeft={(timeLeft*100/initialTime)} timeLeft={Math.ceil(timeLeft/1000)} purcentageHurryUp={purcentageHurryUp}/>
        {(timeLeft*100/initialTime) < purcentageHurryUp ? (
          <div  className="btn-skip">
          <Button fn={() => {
            updateStatus('passed', chapterIndex, gameIndex, orderIndex);
            setOrderIndex(nextOrder(true));
            // sendEvent({
            //     category: `game-${chapterIndex}-${gameIndex}-${orderIndex}`,
            //     action: `skip`,
            // });
            sendEvent({
              event:"end",
              chapter_id: chapterIndex,
              game_id: gameIndex,
              question_id: orderIndex,
              time_limit: initialTime,
              time_spent: initialTime - timeLeft,
              timeout: true,
              success: false,
              skip: true
            });
          }}>
            <Icon name="chevron-right" />
          </Button>
          </div>
        ) : null}

        <Toast show={gameSuccessToast} titleText={gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.success.title || "Bravo ! Tu as réussi."}>
        {gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.success.text ? 
          <p>{gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.success.text}</p>
        : null}
        <Button fn={() => {
            setOrderIndex(nextOrder());
          }}>
            {gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.success.buttonNext || "Etape suivante"}
          </Button>
        </Toast>

        <Toast show={gameFailedToast} titleText={gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.failed.title || "Dommage, ce n’est pas réussi."}>
          {gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.failed.text ? 
            <p>{gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.failed.text}</p> 
          : null}
          <Button fn={() => {
            if(gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries > 0) {
              updateStatus('passed', chapterIndex, gameIndex, orderIndex);
            } else {
              updateStatus('failed', chapterIndex, gameIndex, orderIndex);
            }
            
            setOrderIndex(nextOrder(true));
          }}>
            {gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.failed.buttonNext || "Vers l’étape suivante"}
          </Button>
          <Button 
            marginTop={true} 
            disabled={
              gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries === 0
            }
            fn={() => {
              // sendEvent({
              //   category: `game-${chapterIndex}-${gameIndex}-${orderIndex}`,
              //   action: `retry`,
              // })
              reduceTries(chapterIndex, gameIndex, orderIndex);
              setGameProgression(false);
              resetGameState();
          }}>
            {gameData[chapterIndex].games[gameIndex].orders[orderIndex].feedbacks.failed.buttonRetry || "Recommencer"}
            &nbsp;({(gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries === undefined) ? 3 : (gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries + ' essai')})
          </Button>
        </Toast>

        <Toast 
          show={gameHelpToast} 
          titleText={gameData[chapterIndex].games[gameIndex].orders[orderIndex].title}
          buttonText="Ok, reprendre le jeu "
          onHide={() => {
            // sendEvent({
            //   category: `game-${chapterIndex}-${gameIndex}-${orderIndex}`,
            //   action: `unpause`,
            // })
            resume();
            setGameHelpToast(false);
          }}
        >
        </Toast>
      </GameWrapper>
    )
  }

  return (
    <OrientationBlocker>
      <GameTop>
        <Container>
          <GameHeader 
            chapterIndex={chapterIndex} 
            gameIndex={gameIndex}
            orderIndex={orderIndex} 
            showHelpIcon={gameProgression}
            showHelp={() => {
              // sendEvent({
              //   category: `game-${chapterIndex}-${gameIndex}-${orderIndex}`,
              //   action: `pause`,
              // })
              pause();
              setGameHelpToast(true);
            }}
          />
        </Container>
      </GameTop>
      <GameMiddle>
          
          {gameProgression ? 
            generateGameInterface({
              chapterIndex: chapterIndex,
              gameIndex: gameIndex,
              orderIndex: orderIndex
            })
          : 
            <GameOrder 
              chapterIndex={chapterIndex} 
              gameIndex={gameIndex} 
              orderIndex={orderIndex} 
              fnStartOrder={() => {
                if(orderIndex === 0) {
                  sendEvent({
                    event:"start",
                    chapter_id:chapterIndex,
                    game_id:gameIndex,
                  });
                }
                sendEvent({
                  event:"begin",
                  chapter_id:chapterIndex,
                  game_id:gameIndex,
                  question_id:orderIndex
                })
                resetGameState();
                setGameProgression(true);
                start(initialTime);
              }}
            /> 
          }

      </GameMiddle>
      
    </OrientationBlocker>
  );
};

const GameWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .btn-skip {
      position: absolute;
      bottom: 19px;
      right: 20px;

      svg {
        height: auto;
        margin-right: 0 !important;
      }
    }
`;

const GameContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 8px);
    margin-top: 8px;
    overflow: hidden;
`;

export default GameHoc;
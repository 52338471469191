import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from './button';
import Icon from './Icon';
import { colors } from '../utils/variables';
import WelcomeVisual from '../assets/images/welcome.png';

const ToastWelcome = ({large, show, onHide}) => {
    const [showed, setShowed] = useState(false);

    useEffect(() => {
        setShowed(show);
    });

    return (
        <ToastStyled large={large} showed={showed}>
            <div className="backdrop"/>
            <div className="box">
                <h1 className="title">Bienvenue dans<br />le Digi Challenge !</h1>
                <div className="content">
                    <p><img src={WelcomeVisual} /></p>
                    <p>Nous t’invitons à évaluer tes compétences numériques de base de manière ludique à partir d’un certain nombre d’actions à réaliser et de mini jeux.</p>
                    <p>Dans les menus, tu auras également la possibilité de réafficher les instructions avec l’icône d’aide <Icon name="help-circle" />, revenir à l’accueil à tout moment <Icon name="home" />, consulter le centre de documentation <Icon name="ressource" /> ou encore revoir tes scores <Icon name="score" />. </p>
                    <Button fn={() => {
                        setShowed(false);
                        onHide();
                    }}>
                        Accéder à l’application
                    </Button>
                </div>
            </div>
        </ToastStyled>
    );
}

const ToastStyled = styled.div`
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1D3C8A;
        transition: all .4s ease-in-out;

        ${props => props.showed === false ? `
             opacity: 0;
             z-index: -1;
             pointer-events: none;
        ` : `
            opacity: .9;
             z-index: 1000;
             pointer-events: all;
        `}
    }

    .title {
        font-size: 32px;
        font-weight: 600;
        color: ${colors.darkblue};
        text-align: center;
    }

    .box {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - 40px);
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;

        height: ${props => props.large ? `90%` : 'auto'};

        background: #fff;
        border-radius: 8px 8px 0 0;
        padding: 20px;

        transition: all .4s ease-in-out;

        ${props => props.showed === false ? `
             opacity: 0;
             z-index: -1;
             pointer-events: none;
             transform: translate(0, 100%);
        ` : `
            opacity: 1;
             z-index: 1000;
             pointer-events: all;
             transform: translate(0, 0);
        `}

        .content {
            
        }

        p {
            font-size: 17px;
            color: ${colors.darkblue};
            margin: 0 0 25px;
            line-height: 1.5em;

            img {
                display: block; 
                width: 80%;
                margin: 0 auto;
            }

            svg {
                color: ${colors.lightblue}
            }
        }
    }

`;

export default ToastWelcome;

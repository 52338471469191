import { useContext } from 'react';
import styled from 'styled-components';

import Context from '../utils/context';
import gameData from '../utils/gameData';
import { colors } from '../utils/variables';
import Icon from './Icon';

const GameProgress = ({chapterIndex, gameIndex, orderIndex, theme}) => {
  
    const { gameProgress } = useContext(Context);
    // console.log('chapterIndex', chapterIndex);
    // console.log('gameIndex', gameIndex);
    // console.log('orderIndex', orderIndex);
    // console.log('===> ', gameProgress[chapterIndex], gameProgress[chapterIndex].games[gameIndex]);
    if(!gameProgress[chapterIndex].games[gameIndex]) {
      return <></>;
    }
    if(gameProgress[chapterIndex].games[gameIndex].orders.filter(item => item.status === 'none').length === gameProgress[chapterIndex].games[gameIndex].orders.length) {
      return <></>;
    }

    return (
        <ProgressionStyled theme={theme}>
            {gameProgress[chapterIndex].games[gameIndex].orders.map((orderProgressData, orderI) => {
              
                if(orderProgressData.status === 'success') {
                    return (<div className={"icon icon-success " + ((orderIndex === orderI) ? ' icon-active' : '')} key={`progress-${chapterIndex}-${gameIndex}-${orderI}`}><Icon name="check" /></div>);
                } else if(orderProgressData.status === 'failed' || orderProgressData.status === 'passed') {
                    return (<div className={"icon icon-failed" + ((orderIndex === orderI) ? ' icon-active' : '')} key={`progress-${chapterIndex}-${gameIndex}-${orderI}`}><Icon name="cross" /></div>);
                } else {
                    return (<div className={"icon" + ((orderIndex === orderI) ? ' icon-active' : '')} key={`progress-${chapterIndex}-${gameIndex}-${orderI}`}></div>);
                }
            })}
        </ProgressionStyled>
    )
};

const ProgressionStyled = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;

    svg {
      width: 12px;
      height: 10px;
      color: ${colors.darkblue};
    }

    .icon {
      position: relative;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border: 1px solid ${colors.darkblue};
      border-radius: 50%;
      background: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, .4)'} none;
      margin: 0;

      & + .icon {
        margin-left: 14px;

        &::before {
          content: '';
          position: absolute;
          right: 100%;
          display: block;
          width: 13px;
          height: 0;
          border-top: 1px dashed ${props => props.theme === 'dark' ? `${colors.darkblue}` : `rgba(255, 255, 255, .4)`};
          ${props => props.theme === 'light' ? `
            right: calc(100% + 1px);
            width: 14px;
          `: ``};
        }

        &-success::before,
        &-failed::before,
        &-active::before {
          border-top: 1px solid ${props => props.theme === 'dark' ? `${colors.darkblue}` : `rgba(255, 255, 255, 1)`};
        }
      }

      svg {
        height: auto;
        path {
          fill: ${colors.darkblue};
        }
      }

      &-success {
        background-color: ${colors.success};
        ${props => props.theme === 'light' ? `
            border-color: ${colors.success};
        ` : ''}
      }

      &-failed {
        background-color: ${colors.failed};
        
        ${props => props.theme === 'light' ? `
            border-color: ${colors.failed};
        ` : ''}
      }

      &-active {
        background: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, .4)'} none;
        ${props => props.theme === 'light' ? `
            border-color: white;
        ` : ''}

        svg {
          opacity: 0;
        }
      }
    }
`;

export default GameProgress;
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import Icon from '../../Icon';

const Navigation = () => {
    const location = useLocation();
  return (
    <NavigationStyled>
        <ul>
            <li>
                <Link to="/" className={(location.pathname === "/" ? 'active' : '')}>
                    <Icon name="home" />
                    <span className="label">
                        Accueil
                    </span>
                </Link>
            </li>
            <li>
                <Link to="/scores" className={(location.pathname === "/scores" ? 'active' : '')}>
                    <Icon name="score" />
                    <span className="label">
                        Score
                    </span>
                </Link>
            </li>
            <li>
                <Link to="/challenges" className={(location.pathname === "/challenges" ? 'active' : '')}>
                    <Icon name="challenge" />
                    <span className="label">
                        Défis
                    </span>
                </Link>
            </li>
            <li>
                <Link to="/ressources" className={(location.pathname === "/ressources" ? 'active' : '')}>
                    <Icon name="ressource" />
                    <span className="label">
                        Ressources
                    </span>
                </Link>
            </li>
        </ul>
    </NavigationStyled>
  );
}

const NavigationStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    font-family: "Sofia-Pro";

    ul {
        display: flex;
        list-style-type: none;
        width: 100%;
        padding: 0;
        margin: 0;
        justify-content: space-around;
        align-items: center;

        li {
            flex: 1;
            text-align: center;
            margin: 0 10px;
        }
    }

    a {
        display: block;
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: rgba(29, 60, 138, 0.25);

        .label {
            margin-top: 10px;
            display: block;
            font-weight: 500;
            font-size: 13px;
        }

        svg {
            fill: rgba(29, 60, 138, 0.25);
            transition: fill .4s ease-in-out;
            height: 27px;

            .fill {
                opacity: 0;
                fill: #3FA2F7;
                transition: opacity .4s ease-in-out;
            }
        }
        
        &:hover,
        &.active {
            .label {
                color: #1D3C8A;
            }
            svg {
                path {
                    fill: #3FA2F7;
                }
                .fill {
                    opacity: 1;
                }
            }
        }
    }
`;

export default Navigation;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Draggable from 'react-draggable';
import useSpeechToText from 'react-hook-speech-to-text';
// import * as faceapi from 'face-api.js';

import useLongPress from '../../../utils/useLongPress';
import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';

import faceIdStatic from '../../../assets/images/faceid.png';
import faceIdAnimated from '../../../assets/images/faceid.gif';

const Game1b = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);
  const [order2SwitchWidth, setOrder2SwitchWidth] = useState(false);
  const [order2SwitchDragPosition, setOrder2SwitchDragPosition] = useState(0);
  const [order2SwitchCursorClassName, setOrder2SwitchCursorClassName] = useState('');

  const order2Switch = useRef(null);
  const order2SwitchCursor = useRef(null);

  const order3FingerprintProgress = useRef(null);

  

  // const {
  //   error,
  //   interimResult,
  //   isRecording,
  //   results,
  //   startSpeechToText,
  //   stopSpeechToText,
  // } = useSpeechToText({

  //   continuous: true,
  //   crossBrowser: true,
  //   googleApiKey: 'a3e3cc4dbd654cd3ae7140b9c8b4134586a14699',
  //   timeout: 10000,
  //   useLegacyResults: false,
  // });

  const [order4TextCorrect, order4SetTextCorrect] = useState(false);
  const [order4TextTranscript, order4SetTextTranscript] = useState('');

  const previewimage = useRef(null);
  const canvasRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [order5SelectedFile, order5SetSelectedFile] = useState();
	const [order5IsFilePicked, order5SetIsFilePicked] = useState(false);

  const [order5Clicked, setOrder5Clicked] = useState(false);

  const [order5FakeRecording, order5SetFakeRecording] = useState(false);
  const [order5FakeRecordingTranscript1, order5SetFakeRecordingTranscript1] = useState(false);
  const [order5FakeRecordingTranscript2, order5SetFakeRecordingTranscript2] = useState(false);
  const [order5FakeRecordingTranscript3, order5SetFakeRecordingTranscript3] = useState(false);

  const order5DhangeHandler = (event) => {
    if(
      event.target.files[0].type === 'image/jpeg' || 
      event.target.files[0].type === 'image/jpg' || 
      event.target.files[0].type === 'image/png') {
        order5SetSelectedFile(event.target.files[0]);
        // console.log('event.target.files[0]', event.target.files[0]);
        order5SetIsFilePicked(true);


        setTimeout(() => {
          // console.log('previewimage (ue)', previewimage);
          //searchFace();

          setShowCustomFeedback("success");
        }, 1500);

        // setTimeout(() => {
        //   setShowCustomFeedback("success");
        // }, 1500);
      }
	};

  useEffect(() => {
    // console.log('update previewimage', previewimage);
    // searchFace();
  }, [order5IsFilePicked]);

  // const searchFace = async () => {
  //   let checkPicture = setInterval(async () => {
  //     if (previewimage && previewimage.current && canvasRef && canvasRef.current) {
  //       clearInterval(checkPicture);
  //       canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(previewimage.current);
  //       const displaySize = {
  //         width: 500,
  //         height: 500
  //       }

  //       faceapi.matchDimensions(canvasRef.current, displaySize);
  //       // console.log('previewimage.current', previewimage.current);
  //       const detections = await faceapi.detectAllFaces(previewimage.current, new faceapi.TinyFaceDetectorOptions());
  //       // console.log('detections', detections);

  //       if(detections.length > 0) {
  //         setShowCustomFeedback("success");
  //       } else {
  //         onFailOrder();
  //       } 

  //       const resizedDetections = faceapi.resizeResults(detections, displaySize);
  //       // console.log('resizedDetections', resizedDetections);

  //       canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, 500, 500);
  //       canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
  //       // canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
  //       // canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
  //     }
  //   }, 100);
  // }


  const onLongPress = (e) => {
    // console.log('onLongPress');
    setOrder2SwitchCursorClassName('completed success');
    setShowCustomFeedback("success");
  }

  const onShortPress = (e) => {
    // console.log('onShortPress');
  }

  const onLongPressRelease = (e) => {
    order3FingerprintProgress.current.classList.add('release');
    order3FingerprintProgress.current.style.width = '0%';
      order3FingerprintProgress.current.style.height = '0%';
  }
  
  const longPressEvent = useLongPress(onLongPress, onShortPress, {
    shouldPreventDefault: true,
    delay: 800,
    onProgress: (e) => {
      order3FingerprintProgress.current.classList.remove('release');
      order3FingerprintProgress.current.style.width = e + '%';
      order3FingerprintProgress.current.style.height = e + '%';
    }
  }, onLongPressRelease);

  // const order5DetectCorrectText = () => {
  //   stopSpeechToText();
  //   setTimeout(() => {
  //     setShowCustomFeedback("success");
  //   }, 1500);
  // }

  // useEffect(() => {
  //   // console.log('useeffect interimResult', interimResult);
  //   if(interimResult && interimResult.trim().toLowerCase()) {
  //     order4SetTextTranscript(
  //       <>
  //         {(
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille')) ||
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon')) ||
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon téléphone'))
  //         ) ? <span className="transcript-ok">Déverrouille&nbsp;</span> : <span className="transcript-nok">Déverrouille&nbsp;</span>}
  //         {(
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon')) ||
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon téléphone'))
  //         ) ? <span className="transcript-ok">mon&nbsp;</span> : <span className="transcript-nok">mon&nbsp;</span>}
  //         {(
  //           (interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon téléphone'))
  //         ) ? <span className="transcript-ok">téléphone&nbsp;</span> : <span className="transcript-nok">téléphone&nbsp;</span>}
  //       </>
  //     );
  //   }
  //   if(interimResult && interimResult.trim().toLowerCase().endsWith('déverrouille mon téléphone')) {
  //     order5DetectCorrectText();
  //   }
  // }, [isRecording, interimResult]);

  
  // useEffect(() => {
  //   const loadModels = async () => {
  //     const MODEL_URL = process.env.PUBLIC_URL + '/models';

  //     Promise.all([
  //       faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
  //       faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
  //       faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
  //       faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
  //     ]).then(setModelsLoaded(true));
  //   }
  //   loadModels();
  // }, []);

  const orderRender = [
    {
      render: () => (
        <GameContainer className="game-container-order1">
          <div className="fake-modal">
            <div className="fake-close" onClick={() => {
              setShowCustomFeedback("success");
            }}>
              <Icon name="cross" />
            </div>
            <div className="fake-box"></div>
            <div className="fake-box"></div>
            <div className="fake-box"></div>
          </div>
        </GameContainer>
      ),
      afterRender: () => {
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order2">
          <div className="fake-switch" ref={order2Switch}>
            <Draggable 
              ref={order2SwitchCursor}
              axis="x"
              position={{y: 0, x: order2SwitchDragPosition}}
              bounds={{top: 0, left: 0, right: order2SwitchWidth - 22 - 68, bottom: 0}}
              onStart={() => {
                setOrder2SwitchCursorClassName('');
              }}
              onDrag={(e, position) => {
                setOrder2SwitchCursorClassName('');
                setOrder2SwitchDragPosition(position.x);
              }}
              onStop={(e, position) => {
                // console.log('dragStop', e, position, order2SwitchWidth - 22 - 68, position.x >= order2SwitchWidth - 22 - 68 - (order2SwitchWidth/5));

                if(position.x >= order2SwitchWidth - 22 - 68 - (order2SwitchWidth/5)) {
                  setOrder2SwitchDragPosition(order2SwitchWidth - 22 - 68);
                  setOrder2SwitchCursorClassName('completed');
                  setTimeout(()=>{
                    setOrder2SwitchCursorClassName('completed success');
                    setShowCustomFeedback("success");
                  }, 750);
                } else {
                  setOrder2SwitchDragPosition(0);
                  setOrder2SwitchCursorClassName('move');
                }
              }
            }>
              <div className={`fake-switch-cursor ${order2SwitchCursorClassName}`}>
                <Icon name="lock" className="icon-lock"/>
                <Icon name="unlock" className="icon-unlock" />
                <Icon name="check" className="icon-check" />
              </div>
            </Draggable>
          </div>
        </GameContainer>
      ),
      afterRender: () => {
        setOrder2SwitchWidth(order2Switch.current.offsetWidth);
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order3">
          <div 
            className="fake-fingerprint"
            {...longPressEvent}
          >
            <div className="progress" ref={order3FingerprintProgress} />
            <Icon name="fingerprint" />
          </div>
        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order3 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order4">
          <div className={`transcript ${order5FakeRecording ? 'is-recording' : ''}`}>&nbsp;
            <>
              {(order5FakeRecordingTranscript1) ? <span className="transcript-ok">Déverrouille&nbsp;</span> : <span className="transcript-nok">Déverrouille&nbsp;</span>}
              {(order5FakeRecordingTranscript2) ? <span className="transcript-ok">mon&nbsp;</span> : <span className="transcript-nok">mon&nbsp;</span>}
              {(order5FakeRecordingTranscript3) ? <span className="transcript-ok">téléphone&nbsp;</span> : <span className="transcript-nok">téléphone&nbsp;</span>}
            </>
          </div>
          <div 
            className={`fake-micro ${order5FakeRecording ? 'is-recording' : ''}`}
            onClick={() => {
              // startSpeechToText();
              order5SetFakeRecording(true);

              setTimeout(() => {
                order5SetFakeRecordingTranscript1(true);
              }, 1000);

              setTimeout(() => {
                order5SetFakeRecordingTranscript2(true);
              }, 1500);

              setTimeout(() => {
                order5SetFakeRecordingTranscript3(true);
              }, 2000);

              setTimeout(() => {
                setShowCustomFeedback("success");
              }, 3000);
            }}
          >
            <div className="pulse1" />
            <div className="pulse2" />
            <div className="pulse3" />
            <div className="mask"><Icon name="micro" /></div>
          </div>
        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order4 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order5">
          <div className="fake-face" onClick={() => {
            setOrder5Clicked(true);
            setTimeout(() => {
              setShowCustomFeedback("success");
            }, 1500);
            
          }}>
            
            {order5IsFilePicked ? (
            <div className="preview">
              <canvas ref={canvasRef} style={{ position: 'absolute' }} />
              <img ref={previewimage} src={URL.createObjectURL(order5SelectedFile)} style={{width: '100%', }}/>
            </div>
            ) : (
              // <Icon name="face" className="icon" />
              <img src={order5Clicked ? faceIdAnimated : faceIdStatic} className="image-animation" />
            )}

            <img src={faceIdAnimated} className="preload-image" />
          </div>

          
          {/* <div 
            className="fake-camera"
            onClick={() => {
              setShowCustomFeedback("success");
            }}
          >
            {/* <Icon name="camera" /> */}
            {/* <input type="file" name="order5file" id="order5file" accept="image/jpeg" capture="user" onChange={order5DhangeHandler} />
            <label htmlFor="order5file"></label> */}
            
          {/* </div> */}
        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order5 rended');
      }
    },
  ];

  useEffect(() => {
    orderRender[orderIndex].afterRender();
  }, []);

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          onFailOrderWithoutFeedback();
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  return (
    <>
      {orderRender[orderIndex].render()}

      <CustomFeedback show={showCustomFeedback}>
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </>
  );
};

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &.game-container-order1 {
    width: 100%;
    height: 100%;
    background: ${colors.darkblue90};

    .fake-modal {
      width: calc(100% - 40px);
      height: calc(100% - 120px);
      margin-top: 80px;
      border-radius: 15px 15px 0 0;
      background: ${colors.line};
      padding: 20px;
      overflow:hidden;

      .fake-close {
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background: ${colors.lightblue};
        margin-bottom: 20px;
        color: white;
        font-size: 24px;
      }

      .fake-box {
        clear: both;
        background: white;
        height: 25%;
        margin: 0 0 20px 0;
        border-radius: 15px;
        box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);
      }

    }
  }

  &.game-container-order2 {
    display: flex;
    justify-content: center;
    align-items: center;

    .fake-switch {
      width: calc(100% - 40px);
      height: 90px;
      margin: 0 auto;
      border: 1px solid ${colors.line};
      border-radius: 45px;
      box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);

      &-cursor {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 68px;
        height: 68px;
        margin: 11px;
        background: ${colors.lightblue};
        color: white;
        border-radius: 50%;

        .icon-lock,
        .icon-unlock,
        .icon-check {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: opacity .4s ease-in-out;
          opacity: 0;
          font-size: 24px;
        }

        .icon-check {
          width: 22px;
          height: 16px;
        }

        .icon-lock {
          opacity: 1;
        }

        .icon-unlock {
          opacity: 0;
        }
        &.move {
          transition: transform .8s ease-in-out;
        }
        &.completed {
          transition: transform .4s ease-in-out, background-color .4s ease-in-out;

          .icon-lock {
            opacity: 0;
          }

          .icon-unlock {
            opacity: 1;
          }
        }

        &.success {
          background-color: ${colors.success};
          color: ${colors.darkblue};

          .icon-lock,
          .icon-unlock {
            opacity: 0;
          }

          .icon-check {
            opacity: 1;
          }
        }

        svg {
          height: auto;
        }
      }
    }
  }

  &.game-container-order3 {
    display: flex;
    justify-content: center;
    align-items: center;

    .fake-fingerprint {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      color: ${colors.lightblue};
      border: 1px solid ${colors.line};
      border-radius: 50%;
      box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);
      background: rgba(63, 162, 247, .2);

      .progress {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: white;

        &.release {
          transition: all .8s ease-in-out;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 76px;
        height: 75px;
      }
    }
  }

  &.game-container-order4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .transcript {
      margin: 0 0 35px;
      font-size: 25px;
      font-weight: 500;
      color: ${colors.darkblue};

      .transcript-nok {
        opacity: .2;
      }

      .transcript-ok {
        opacity: 1;
      }
    }

    .fake-micro {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      color: ${colors.lightblue};
      border: 1px solid ${colors.line};
      border-radius: 50%;
      box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);
      background: white;

      .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .pulse1,
      .pulse2,
      .pulse3 {
        display: none;
      }

      &.is-recording {
        .pulse1,
        .pulse2,
        .pulse3 {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border: 20px solid ${colors.lightblue};
          border-radius: 50%;
          animation: pulse 2s linear infinite;
          opacity: 0;
          z-index: 0;
        }

        .pulse2 {
          animation: pulse 2s .4s linear infinite;
        }

        .pulse3 {
          animation: pulse 2s .8s linear infinite;
        }

        .mask {
          z-index: 1;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 76px;
        height: 75px;
      }

    }
  }

  &.game-container-order5 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .fake-face {
      position: relative;
      width: calc(100% - 40px);
      margin-bottom: 35px;
      /* background: rgba(233, 236, 244, .4); */
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      overflow: hidden;
      border: 1px solid rgba(29, 60, 138, 0.2);
      box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);

      &::before {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      .icon {
        width: 46px;
        height: 39px;
        color: ${colors.lightblue};
      }

      .preview {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        canvas {
          width: 100%;
          height: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .fake-camera {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      color: ${colors.lightblue};
      border: 1px solid ${colors.line};
      border-radius: 50%;
      box-shadow: 0 5px 0 rgba(29, 60, 138, 0.2);
      background: white;

      input {
        display: none;
      }

      input + label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 50%;
      }
      
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 76px;
        height: 75px;
      }
    }

    .image-animation {
      display: block;
      width: 100px;
      height: 100px;
    }

    .preload-image {
      position: absolute;
      top: -9999px;
      left: -9999px;
      pointer-events: none;
      display: block;
      width: 1px;
      height: 1px;
    }
  }
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  z-index: 50;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    `}
  }
`;

export default Game1b;
import React from 'react';
import styled from 'styled-components';
import iconsLib from './icons';
import { colors } from '../../utils/variables';

const Icon = (props) => {
  const child = iconsLib[props.name];
  return React.cloneElement(child, {
    name: `${props.name}`,
    className: `${child.props.className ? child.props.className + ' ' : ''}${props.className}`
  })
}

export default Icon;

import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';


import { colors } from '../../../utils/variables';
import { arrayMove, insertAtIndex, removeAtIndex } from '../../../utils/array';
import Icon from '../../../components/Icon';
import Button from '../../../components/button';

import {
  DndContext,
  useDraggable,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  closestCenter,
  rectIntersection
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import {Draggable} from './dragItem';
import {Droppable} from './dropArea';


const Game2c = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [renderOrder, setRenderOrder] = useState();
  const [dropLists, setDropLists] = useState({});
  const [refreshLists, setRefreshLists] = useState(true);

  const orders = [
    {
      items: [
        'digitalwallonia',
        '.pdf',
        'www.',
        '.be',
        'https://',
        '.ru',
      ],
      validLists: {
        "drop": [
          'https://',
          'www.',
          'digitalwallonia',
          '.be'
        ]
      }
    },
    {
      items: [
        '+32',
        '.be',
        'http://',
        '@digitalwallonia',
        'www.',
        'jeanne.dupont'
      ],
      validLists: {
        "drop": [
          'jeanne.dupont',
          '@digitalwallonia',
          '.be'
        ]
      }
    },
    {
      items: [
        'fleur123',
        'mdpS4Y0!28',
        'mdpP4!5',
        'motdepasse',
      ],
      validLists: {
        "drop": [
          'mdpS4Y0!28'
        ]
      }
    },
    {
      items: [
        'lise@gmail.com',
        'rose@outlook.com',
        'carl@gmail.com',
        'Consignes en mon absence',
        'Cordialement',
        'Livraison colis',
      ],
      validLists: {
        "to": [
          'rose@outlook.com'
        ],
        "cc": [
        ],
        "cci": [
          'carl@gmail.com'
        ],
        "object": [
          'Consignes en mon absence'
        ],
      }
    },
    {
      items: [
        '+32 496 325 765',
        '24/03',
        'Avenue St José',
        'Jean Dumont',
        'BE13 4532 7643 3454 3453',
        'jean.dumont@gmail.com',
      ],
      validLists: {
        "identification": [
          'Jean Dumont',
        ],
        "email": [
          'jean.dumont@gmail.com',
        ],
        "adresse": [
        ],
        "iban": [
        ],
      }
    }
  ]

  const orderRender = [
    {
      render: () => (
        <>
          <div className="top-area">
            <SortableContext id={"drop"} items={dropLists["drop"] || []}>
              <Droppable id="drop">
                <div className="droppable-inner">
                  <div className="droppable-values">
                    {dropLists["drop"] && dropLists["drop"].length > 0 ? dropLists["drop"].map((item, itemIndex) => <Draggable key={`drag-item-drop-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>
          </div>
          <div className="bottom-area">
            <SortableContext id={"origin"} items={dropLists["origin"] || []}>
              <Droppable id="origin" className="droppable-origin">
                {dropLists["origin"] ? dropLists["origin"].map((item, itemIndex) => <Draggable key={`drag-item-origin-${itemIndex}`} id={item}>{item}</Draggable>) : null}
              </Droppable>
            </SortableContext>
          </div>
        </>
      )
    },
    {
      render: () => (
        <>
          <div className="top-area">
            <SortableContext id={"drop"} items={dropLists["drop"] || []}>
              <Droppable id="drop">
                <div className="droppable-inner">
                  <div className="droppable-values">
                    {dropLists["drop"] && dropLists["drop"].length > 0 ? dropLists["drop"].map((item, itemIndex) => <Draggable key={`drag-item-drop-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>
          </div>
          <div className="bottom-area">
            <SortableContext id={"origin"} items={dropLists["origin"] || []}>
              <Droppable id="origin" className="droppable-origin">
                {dropLists["origin"] ? dropLists["origin"].map((item, itemIndex) => <Draggable key={`drag-item-origin-${itemIndex}`} id={item}>{item}</Draggable>) : null}
              </Droppable>
            </SortableContext>
          </div>
        </>
      )
    },
    {
      render: () => (
        <>
          <div className="top-area">
            <SortableContext id={"drop"} items={dropLists["drop"] || []}>
              <Droppable id="drop">
                <div className="droppable-inner">
                  <div className="droppable-values">
                    {dropLists["drop"] && dropLists["drop"].length > 0 ? dropLists["drop"].map((item, itemIndex) => <Draggable key={`drag-item-drop-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>
          </div>
          <div className="bottom-area">
            <SortableContext id={"origin"} items={dropLists["origin"] || []}>
              <Droppable id="origin" className="droppable-origin">
                {dropLists["origin"] ? dropLists["origin"].map((item, itemIndex) => <Draggable key={`drag-item-origin-${itemIndex}`} id={item}>{item}</Draggable>) : null}
              </Droppable>
            </SortableContext>
          </div>
        </>
      )
    },
    {
      render: () => (
        <>
          <div className="top-area">
            <SortableContext id={"to"} items={dropLists["to"] || []}>
              <Droppable id="to">
                <div className="droppable-inner">
                  <div className="droppable-label">à:</div>
                  <div className="droppable-values">
                    {dropLists["to"] && dropLists["to"].length > 0 ? dropLists["to"].map((item, itemIndex) => <Draggable key={`drag-item-to-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"cc"} items={dropLists["cc"] || []}>
              <Droppable id="cc">
                <div className="droppable-inner">
                  <div className="droppable-label">cc:</div>
                  <div className="droppable-values">
                    {dropLists["cc"] && dropLists["cc"].length > 0 ? dropLists["cc"].map((item, itemIndex) => <Draggable key={`drag-item-cc-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"cci"} items={dropLists["cci"] || []}>
              <Droppable id="cci">
                <div className="droppable-inner">
                  <div className="droppable-label">cci:</div>
                  <div className="droppable-values">
                    {dropLists["cci"] && dropLists["cci"].length > 0 ? dropLists["cci"].map((item, itemIndex) => <Draggable key={`drag-item-cci-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"object"} items={dropLists["object"] || []}>
              <Droppable id="object">
                <div className="droppable-inner">
                  <div className="droppable-label">sujet:</div>
                  <div className="droppable-values">
                    {dropLists["object"] && dropLists["object"].length > 0 ? dropLists["object"].map((item, itemIndex) => <Draggable key={`drag-item-object-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>
          </div>
          <div className="bottom-area">
            <SortableContext id={"origin"} items={dropLists["origin"] || []}>
              <Droppable id="origin" className="droppable-origin">
                {dropLists["origin"] ? dropLists["origin"].map((item, itemIndex) => <Draggable key={`drag-item-origin-${itemIndex}`} id={item}>{item}</Draggable>) : null}
              </Droppable>
            </SortableContext>
          </div>
        </>
      )
    },
    {
      render: () => (
        <>
          <div className="top-area">
            <SortableContext id={"identification"} items={dropLists["identification"] || []}>
              <Droppable id="identification">
                <div className="droppable-inner">
                  <div className="droppable-label">Nom: <span className="mandatory">*</span></div>
                  <div className="droppable-values">
                    {dropLists["identification"] && dropLists["identification"].length > 0 ? dropLists["identification"].map((item, itemIndex) => <Draggable key={`drag-item-identification-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"email"} items={dropLists["email"] || []}>
              <Droppable id="email">
                <div className="droppable-inner">
                  <div className="droppable-label">E-mail: <span className="mandatory">*</span></div>
                  <div className="droppable-values">
                    {dropLists["email"] && dropLists["email"].length > 0 ? dropLists["email"].map((item, itemIndex) => <Draggable key={`drag-item-email-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"adresse"} items={dropLists["adresse"] || []}>
              <Droppable id="adresse">
                <div className="droppable-inner">
                  <div className="droppable-label">Adresse:</div>
                  <div className="droppable-values">
                    {dropLists["adresse"] && dropLists["adresse"].length > 0 ? dropLists["adresse"].map((item, itemIndex) => <Draggable key={`drag-item-adresse-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>

            <SortableContext id={"iban"} items={dropLists["iban"] || []}>
              <Droppable id="iban">
                <div className="droppable-inner">
                  <div className="droppable-label">IBAN:</div>
                  <div className="droppable-values">
                    {dropLists["iban"] && dropLists["iban"].length > 0 ? dropLists["iban"].map((item, itemIndex) => <Draggable key={`drag-item-iban-${itemIndex}`} id={item}>{item}</Draggable>) : <span className="placeholder">glisser-déposer vos réponses dans cette fenêtre</span>}
                  </div>
                </div>
              </Droppable>
            </SortableContext>
          </div>
          <div className="bottom-area">
            <SortableContext id={"origin"} items={dropLists["origin"] || []}>
              <Droppable id="origin" className="droppable-origin">
                {dropLists["origin"] ? dropLists["origin"].map((item, itemIndex) => <Draggable key={`drag-item-origin-${itemIndex}`} id={item}>{item}</Draggable>) : null}
              </Droppable>
            </SortableContext>
          </div>
        </>
      )
    },
  ];

  useEffect(() => {
    const newDropLists = dropLists;
    
    for (const [key, value] of Object.entries(orders[orderIndex].validLists)) {
      newDropLists[key] = [];
    }
    newDropLists["origin"] = [];
    orders[orderIndex].items.map((item, itemIndex) => {
      newDropLists["origin"].push(item);
    });
    
    setDropLists(newDropLists);
  }, [orderIndex]);

  useEffect(() => {
    if(refreshLists === true){
      // console.log('useEffect droplists');
      // const newListsRender = {};
      // for (const [key, value] of Object.entries(dropLists)) {
      //   newListsRender[key] = value.map((item, itemIndex) => (<Draggable key={`drag-item-${key}-${itemIndex}`} id={item.id} data={item}>{item.label}</Draggable>))
      // }
      // setDropListsRender(newListsRender);
      setRefreshLists(false);
    }
    
  }, [dropLists, refreshLists]);
  

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          //onFailOrderWithoutFeedback();
          // console.log('send fail');
          onFailOrder();
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  const validateLists = () => {
    let countSameLists = 0;
    let countValidLists = 0;
    for (const [key, value] of Object.entries(orders[orderIndex].validLists)) {
      countValidLists++;
      const listName = key;
      const listArray = value;
      // console.log('dropLists[listName]', dropLists[listName]);
      // console.log('listArray', listArray);
      if(dropLists[listName] && listArray) {
        let sameArray = JSON.stringify(dropLists[listName]) === JSON.stringify(listArray);
        countSameLists = (JSON.stringify(dropLists[listName]) === JSON.stringify(listArray)) ? countSameLists + 1 : countSameLists;
      }
    }

    if(countSameLists === countValidLists) {
      // console.log('Success');
      setShowCustomFeedback("success");
    } else {
      // console.log('Failed');
      setShowCustomFeedback("failed");
    }
  }

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    overContainer,
    overIndex,
    item
  ) => {
    return {
      ...items,
      [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
      [overContainer]: insertAtIndex(items[overContainer], overIndex, item)
    };
  };

  const handleDragOver = ({ over, active }) => {
    // console.log('---------------- start dragOver');
    // console.log('handleDragOver', over, active);
    const overId = over?.id;
    
    if (!overId) {
      return;
    }

    // console.log('activeContainer', active.data.current.sortable.containerId);
    // console.log('overContainer', over.data.current?.sortable.containerId);

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || overId;
    if (!overContainer) {
      return;
    }

    if (activeContainer !== overContainer) {
      setDropLists((items) => {
        const activeIndex = active.data.current.sortable.index;
        // console.log('activeIndex',activeIndex);
        const overIndex = over.data.current?.sortable.index !== undefined ? over.data.current?.sortable.index : 99;
        // console.log('overIndex',overIndex, over.data.current?.sortable.index);

        return moveBetweenContainers(
          items,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          active.id
        );
      });
      
    }
    // if (over && active.id !== over.id && ( activeContainer === overContainer && activeContainer != over.id)) {
    //   setDropLists((items) => {
    //     // console.log('move in same container items', items[activeContainer]);
    //     const oldIndex = items[activeContainer].indexOf(active.id);
    //     const newIndex = items[activeContainer].indexOf(over.id);

    //     items[activeContainer] = arrayMove(items[activeContainer], oldIndex, newIndex);
    //     return items
    //   });
    // }

    // console.log('---------------- end dragOver');
  }

  const handleDragEnd = ({ active, over }) => {
    // console.log('handleDragEnd active', active);
    // console.log('handleDragEnd over', over);

    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      // console.log('end active.data.current.sortable.index', active.data.current.sortable, active.data.current.sortable.index);
      const activeIndex = active.data.current.sortable.index;
      // console.log('end over.data.current?.sortable.index', over.data.current?.sortable.index);
      const overIndex = over.data.current?.sortable.index !== undefined ? over.data.current?.sortable.index : 99;

      setDropLists((items) => {
        let newItems = items;
        if (activeContainer === overContainer) {
          // newItems = items;
          newItems = {
            ...items,
            [overContainer]: arrayMove(
              items[overContainer],
              activeIndex,
              overIndex
            )
          };
        } else {
          newItems = moveBetweenContainers(
            items,
            activeContainer,
            activeIndex,
            overContainer,
            overIndex,
            active.id
          );
        }

        return newItems;
      });
      
    }
  }



  return (
    <GameContainer>
      <DndContext 
        sensors={sensors} 
        collisionDetection={closestCenter} 
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        
        {orderRender[orderIndex].render()}
        
        <div className="button-area">
        <Button fn={validateLists}>Valider</Button>
        </div>
        
      </DndContext>
      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 40px);
  height: 100%;
  overflow: auto;
  padding: 0 20px;

  .skip-showed & .button-area {
    padding-right: 100px;
  }

  .top-area {
    flex: 1;
  }
  .button-area {
    padding-bottom: 20px;
    /* flex: 1; */
  }
  .bottom-area {
    /* flex: 1; */
  }
  .droppable-area {
    padding: 3px 0;
    //border-bottom: 1px solid ${colors.darkblue20};
    
    margin-top: 15px;
    width: 100%;
    min-height: 48px;

    
    
  }
  .droppable-item {
    display: inline-block;
    border: 1px solid ${colors.darkblue20};
    border-radius: 15px;
    margin: 5px 2px 10px 2px;
    padding: 10px 5px;
    user-select: none;
    cursor: grab;
    box-sizing: border-box;
    background-color: 'white';
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .droppable-inner {
    display: flex;
    .droppable-label {
      min-width: 20%;
      font-size: 20px;
      line-height: 57px;
      color: rgba(29, 60, 138, 0.6);
      pointer-events: none;
      white-space: nowrap;

      .mandatory {
        color: ${colors.failed};
      }
    }
    .droppable-values {
      
      flex: 1;
      background: #fff;
      border: 1px solid ${colors.darkblue20};
      border-radius: 10px;
      padding: 0;
      min-height: 57px;

      /* display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      max-width: 100%;
      overflow: hidden; */

      .placeholder {
        color: ${colors.darkblue20};
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: calc(100% - 40px);
        height: calc(100% - 10px);
        padding: 5px 20px;
      }
    }

    .droppable-label + .droppable-values {
      margin-left: 10px;
    }
  }

  .droppable-origin {
    border: 0;
    background: transparent;
    margin-top: 0;

  }
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game2c;
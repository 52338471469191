import React from 'react';
import {useDroppable} from '@dnd-kit/core';
import { colors } from '../../../utils/variables';

export function Droppable(props) {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });

  const droppableStyle = {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // borderColor: `${isOver ? colors.lightblue : colors.darkblue20}`,
  };
  
  
  return (
    <div ref={setNodeRef} style={droppableStyle} className={`droppable-area ${props.className || ''}`}>
      {props.children}
    </div>
  );
}
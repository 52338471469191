import { Link } from "react-router-dom";
import styled from 'styled-components';

import { Container } from '../../../utils/common-styles';
import { colors } from '../../../utils/variables';
import gameData from '../../../utils/gameData';
import Icon from '../../Icon';
import GameProgress from '../../../components/gameProgress';


const GameHeader = ({chapterIndex, gameIndex, orderIndex, showHelp, showHelpIcon}) => {
  return (
    <GameHeaderStyled>
        <div className="top">
            <Link to="/">
                <Icon name="home" />
            </Link>
            <h1 className="title">
                {gameData[chapterIndex].games[gameIndex].gameTitle}
            </h1>
            <div className="help-wrapper">
            {showHelpIcon ? (
                <div className="help" onClick={showHelp}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.08334 13C1.08334 19.5814 6.41862 24.9166 13 24.9166C19.5814 24.9166 24.9167 19.5814 24.9167 13C24.9167 6.41859 19.5814 1.08331 13 1.08331C6.41862 1.08331 1.08334 6.41859 1.08334 13ZM22.75 13C22.75 18.3848 18.3848 22.75 13 22.75C7.61523 22.75 3.25001 18.3848 3.25001 13C3.25001 7.6152 7.61523 3.24998 13 3.24998C18.3848 3.24998 22.75 7.6152 22.75 13ZM13.0004 18.4149C13.5989 18.4149 14.0841 17.9298 14.0841 17.3315C14.0841 16.7332 13.5989 16.2482 13.0004 16.2482C12.4019 16.2482 11.9167 16.7332 11.9167 17.3315C11.9167 17.9298 12.4019 18.4149 13.0004 18.4149ZM11.9167 15.1666H14.0833C14.0833 14.3018 14.2192 14.1432 15.1095 13.6981C16.6567 12.9245 17.3333 12.1351 17.3333 10.2916C17.3333 7.93066 15.4747 6.49998 13 6.49998C10.6068 6.49998 8.66668 8.44008 8.66668 10.8333H10.8333C10.8333 9.6367 11.8034 8.66665 13 8.66665C14.3836 8.66665 15.1667 9.26942 15.1667 10.2916C15.1667 11.1565 15.0308 11.315 14.1405 11.7602C12.5933 12.5338 11.9167 13.3232 11.9167 15.1666Z"/>
                    </svg>
                </div>
            ) : null}
            </div>
        </div>

        <div className="progress">
            <GameProgress theme="light" chapterIndex={chapterIndex} gameIndex={gameIndex} orderIndex={orderIndex} />
        </div>
    </GameHeaderStyled>
  );
}

const GameHeaderStyled = styled.div`
    position: relative;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    background: ${colors.darkblue};
    color: white;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            width: 22px;
            height: 22px;
            fill: white;
        }

        .title {
            margin: 0;
            font-size: 19px;
            font-weight: 500;
            color: white;

            white-space: nowrap;
            margin: 0 20px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .progress {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
    }

    .help-wrapper {
        width: 26px;
        height: 26px;
    }
`;

export default GameHeader;

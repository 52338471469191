import './index.css'
import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Context, { contextDefaultValue, getGameProgression } from './utils/context';

import ReactGA from './utils/ga';

import Home from './pages/Home';
import Scores from './pages/Scores';
import Challenges from './pages/Challenges';
import Ressources from './pages/Ressources';

import EndGame from './pages/EndGame';
import EndChapter from './pages/EndChapter';
import GameHoc from './pages/Games/GameHoc';
import NotFound from './pages/NotFound';

import Game1a from './pages/Games/Game1a';
import Game1b from './pages/Games/Game1b';
import Game1c from './pages/Games/Game1c';
import Game2a from './pages/Games/Game2a';
import Game2b from './pages/Games/Game2b';
import Game2c from './pages/Games/Game2c';
import Game3a from './pages/Games/Game3a';
import Game3b from './pages/Games/Game3b';
import Game3c from './pages/Games/Game3c';
import Game4a from './pages/Games/Game4a';
import Game4b from './pages/Games/Game4b';



const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log('getGameProgression()', getGameProgression());

document.addEventListener('contextmenu', event => event.preventDefault());

root.render(
    <Context.Provider value={getGameProgression()}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="scores" element={<Scores />} />
          <Route exact path="challenges" element={<Challenges />} />
          <Route exact path="ressources" element={<Ressources />} />
          <Route exact path="endgame" element={<EndGame />} />
          <Route exact path="endchapter" element={<EndChapter />} />
          <Route exact path="game1a" element={<GameHoc chapterIndex={0} gameIndex={0} GameComponent={Game1a} />} />
          <Route exact path="game1b" element={<GameHoc chapterIndex={0} gameIndex={1} GameComponent={Game1b} />} />
          <Route exact path="game1c" element={<GameHoc chapterIndex={0} gameIndex={2} GameComponent={Game1c} />} />
          <Route exact path="game2a" element={<GameHoc chapterIndex={1} gameIndex={0} GameComponent={Game2a} />} />
          <Route exact path="game2b" element={<GameHoc chapterIndex={1} gameIndex={1} GameComponent={Game2b} />} />
          <Route exact path="game2c" element={<GameHoc chapterIndex={1} gameIndex={2} GameComponent={Game2c} />} />
          <Route exact path="game3a" element={<GameHoc chapterIndex={2} gameIndex={0} GameComponent={Game3a} />} />
          <Route exact path="game3b" element={<GameHoc chapterIndex={2} gameIndex={1} GameComponent={Game3b} />} />
          <Route exact path="game3c" element={<GameHoc chapterIndex={2} gameIndex={2} GameComponent={Game3c} />} />
          <Route exact path="game4a" element={<GameHoc chapterIndex={3} gameIndex={0} GameComponent={Game4a} />} />
          <Route exact path="game4b" element={<GameHoc chapterIndex={3} gameIndex={1} GameComponent={Game4b} />} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </Context.Provider>
);

import { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import {sendEvent, sendSetUP} from '../utils/ga';
import OrientationBlocker from '../components/checkOrientation';

import Context from '../utils/context';
import { colors } from '../utils/variables';
import gameData from '../utils/gameData';

import { AppStyled, EndGameTop, EndGameMiddle, Container } from '../utils/common-styles';
import EndGameHeader from '../components/Layout/EndGameHeader';
import Button from '../components/button';
import Icon from '../components/Icon';

import endGameSuccess from '../assets/images/endgame-success.png';
import endGameFailed from '../assets/images/endgame-failed.png';
import endChapter from '../assets/images/endchapter.png';


const EndGame = () => {
    const navigate = useNavigate();
    let {state} = useLocation();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    // console.log('state', state, !state);

    const [chapterFormIndex, setChapterFormIndex] = useState(0);

    const { gameProgress } = useContext(Context);
    const [totalScore, setTotalScore] = useState(0);
    const [totalMaxScore, setTotalMaxScore] = useState(0);
    const [scores, setScores] = useState({});
    const [scoresCategories, setScoresCategories] = useState({});
    const [totalGameScoreState, setTotalGameScoreState] = useState(0);
    const [skillsToShowState, setSkillsToShowState] = useState([]);
    const [newScoreGame, setNewScoreGame] = useState({})
    const [gameSuccess, setGameSuccess] = useState(false);

    const [formSocioSubmitted, setFormSocioSubmitted] = useState(false);
    const [formSocioSubmittedInstant, setFormSocioSubmittedInstant] = useState(false);
    const [formSocioSkipped, setFormSocioSkipped] = useState(false);

    useEffect(() => {
        if(!state) {
        // if(false) { // DEBUG ONLY
            navigate('/', { replace: true });
        }

        // console.log('formSocioSubmitted ls', localStorage.getItem('formSocioSubmitted'), new Boolean(localStorage.getItem('formSocioSubmitted')));

        setFormSocioSubmitted(localStorage.getItem('formSocioSubmitted') === 'true' ? true : false);

        computeScore(computeMaxScore());

        // console.log('current', state.currentChapter, state.currentGame, gameData[state.currentChapter].games[state.currentGame].orders);

        const newScores = {};
        const skillsToShow = [];
        let totalGameScore = 0;
        let countSuccesOrder = 0;

        gameData[state.currentChapter].games[state.currentGame].orders.map((order, orderIndex) => {
            // console.log('order.status', order.status, gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex]);
            if(gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex].status === "success"){

                const timeTotal = order.allowedTime;
                const timeLeft = gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex].timeLeft;
                const timeUsed = timeTotal - timeLeft;
                countSuccesOrder = countSuccesOrder + 1;
                const scoreDivisor = (timeUsed < timeTotal / 6) ? 1 : (timeUsed < timeTotal / 4) ? 1.5 : 3;

                // console.log('scoreDivisor', scoreDivisor);

                order.score.map(score => {
                    // console.log('score test', skillsToShow.indexOf(score.label) === -1);
                    if(skillsToShow.indexOf(score.label) === -1) {
                        // console.log('add skill',score.label );
                        skillsToShow.push(score.label)
                    }
                    newScores[score.label] = (newScores[score.label]) ? newScores[score.label] + (score.points / scoreDivisor) : (score.points / scoreDivisor);
                    totalGameScore = totalGameScore + (score.points / scoreDivisor);
                });
            }
        });
        // console.log('newScores', newScores, skillsToShow, totalGameScore);
        setTotalGameScoreState(totalGameScore);
        setSkillsToShowState(skillsToShow);
        setNewScoreGame(newScores);
        setGameSuccess(countSuccesOrder > 2);

        // sendEvent({
        //     category: `game-${state.currentChapter}-${state.currentGame}`,
        //     action: `end`,
        //     label: (countSuccesOrder > 2) ? 'success' : 'failed',
        //     value: totalGameScore,
        // });
    }, []);


    const computeMaxScore = () => {
        let totalMaxScoreTemp = 0;
        let totalMaxScoreObjectTemp = {
          "rechercher": {
            "max": 0,
            "points": 0,
          },
          "naviguer": {
            "max": 0,
            "points": 0,
          },
          "gerer": {
            "max": 0,
            "points": 0,
          },
          "interagir": {
            "max": 0,
            "points": 0,
          },
          "partager": {
            "max": 0,
            "points": 0,
          },
          "creer": {
            "max": 0,
            "points": 0,
          },
          "modifier": {
            "max": 0,
            "points": 0,
          },
          "creatif": {
            "max": 0,
            "points": 0,
          },
          "critique": {
            "max": 0,
            "points": 0,
          },
          "numerique": {
            "max": 0,
            "points": 0,
          }
        };
    
        gameData.map(chapter => {
          chapter.games.map(game => {
            game.orders.map(order => {
              order.score.map(score => {
                totalMaxScoreObjectTemp[score.label].max = totalMaxScoreObjectTemp[score.label].max + score.points;
                totalMaxScoreTemp = totalMaxScoreTemp + score.points;
              });
            });
          });
        });
    
        setTotalMaxScore(totalMaxScoreTemp);
        // console.log('totalMaxScoreTemp', totalMaxScoreTemp, totalMaxScoreObjectTemp);
        return totalMaxScoreObjectTemp;
    }

    const computeScore = (maxScoreObj) => {
    // console.log('computeScore gameProgress', gameProgress);
    // console.log('computeScore gameData', gameData);
    let totalScoreTemp = 0;
    let totalMaxScoreTemp = 0;

    const categoriesScore = {
        "informations": {
        "max": 0,
        "points": 0
        },
        "communication": {
        "max": 0,
        "points": 0
        },
        "production": {
        "max": 0,
        "points": 0
        },
        "resolution": {
        "max": 0,
        "points": 0
        },
        "connaissance": {
        "max": 0,
        "points": 0
        }
    };
    const computedScore = maxScoreObj;
    
    gameProgress.map((chapter, chapterIndex) => {
        if(chapter.status === "progress" || chapter.status === "completed") {
        chapter.games.map((game, gameIndex) => {
            if(game.status === "progress" || game.status === "completed") {
            game.orders.map((order, orderIndex) => {
                if(order.status === "success") {
                const orderData = gameData[chapterIndex].games[gameIndex].orders[orderIndex];
                const orderProgress = order;

                const timeTotal = orderData.allowedTime;
                const timeLeft = orderProgress.timeLeft;
                const timeUsed = timeTotal - timeLeft;

                const scoreDivisor = (timeUsed < timeTotal / 6) ? 1 : (timeUsed < timeTotal / 4) ? 1.5 : 3;

                const orderScores = orderData.score;

                orderScores.map((orderScoreItem) => {
                    computedScore[orderScoreItem.label].points = computedScore[orderScoreItem.label].points + (orderScoreItem.points / scoreDivisor);
                    totalScoreTemp = totalScoreTemp + (orderScoreItem.points / scoreDivisor);
                });
                
                }
            })
            }
        });
        }

        setTotalScore(totalScoreTemp);
    });

    categoriesScore["informations"].points = computedScore["rechercher"].points + computedScore["naviguer"].points +  computedScore["gerer"].points;
    categoriesScore["informations"].max = computedScore["rechercher"].max + computedScore["naviguer"].max + computedScore["gerer"].max;

    categoriesScore["communication"].points = computedScore["interagir"].points + computedScore["partager"].points;
    categoriesScore["communication"].max = computedScore["interagir"].max + computedScore["partager"].max;

    categoriesScore["production"].points = computedScore["creer"].points + computedScore["modifier"].points;
    categoriesScore["production"].max = computedScore["creer"].max + computedScore["modifier"].max;

    categoriesScore["resolution"].points = computedScore["creatif"].points + computedScore["critique"].points;
    categoriesScore["resolution"].max = computedScore["creatif"].max + computedScore["critique"].max;

    categoriesScore["connaissance"].points = computedScore["numerique"].points;
    categoriesScore["connaissance"].max = computedScore["numerique"].max;

    setScoresCategories(categoriesScore);
    setScores(computedScore);
    };


  const onSubmit = (data) => {
    // console.log('data', data);

    const obj = {
      age: data.age ? data.age : 'n/a',
      gender: data.sex ? data.sex : 'n/a',
      occupation: data.occupation ? data.occupation : 'n/a',
      formation: data.formation ? data.formation : 'n/a',
      transition: data.transition ? data.transition : 'n/a',
    }

    // console.log('ga send', {
    //   event:"profile-update",
    //   ...obj
    // });

    // sendEvent({
    //   event:"profile-update",
    //   ...obj
    // });
    sendSetUP(obj);

    localStorage.setItem('formSocioSubmitted', 'true');
    setFormSocioSubmitted(true);
    setFormSocioSubmittedInstant(true);

    // navigate('/', { replace: true });
  }


  return (
    <OrientationBlocker>
      <EndGameTop>
        <Container>
          <EndGameHeader />
        </Container>
      </EndGameTop>
      <EndGameMiddle>
        <EndGameContainer>
            {state ? (
                <>
                    <div className="content">
                      <>
                          <h1>Merci d'avoir complété la section "{gameData[state.currentChapter].chapterTitle}"!</h1>
                          <img src={endChapter} className="game-visual" />
                      </>

                    {state.currentChapter === chapterFormIndex && formSocioSubmitted === true && formSocioSubmittedInstant === true ? (
                      <p>Merci de nous avoir partagé vos informations</p>
                    ) : null}
                    {state.currentChapter === chapterFormIndex && (formSocioSubmitted === false && formSocioSkipped === false)  ? (
                      <FormWrapper>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <p>Pour nous aider à améliorer nos services, pourriez-vous répondre à ces quelques questions ?</p>

                            <div className="form-item">
                                <label>Quel est votre genre ?</label>
                                <div className="radios">
                                    <div className="radio-wrapper">
                                        <input type="radio" name="sex" id="sex-f" value="f" {...register("sex")} />
                                        <label htmlFor="sex-f">Femme</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="sex" id="sex-m" value="m" {...register("sex")} />
                                        <label htmlFor="sex-m">Homme</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="sex" id="sex-o" value="o" {...register("sex")} />
                                        <label htmlFor="sex-o">Autre</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-item">
                                <label>Quelle est votre tranche d'âge ?</label>
                                <div className="radios radios-list">
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-moins-18" value="moins-18" {...register("age")} />
                                        <label htmlFor="age-moins-18">Moins de 18 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-18-24" value="18-24" {...register("age")} />
                                        <label htmlFor="age-18-24">18 à 24 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-25-34" value="25-34" {...register("age")} />
                                        <label htmlFor="age-25-34">25 à 34 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-35-44" value="35-44" {...register("age")} />
                                        <label htmlFor="age-35-44">35 à 44 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-45-54" value="45-54" {...register("age")} />
                                        <label htmlFor="age-45-54">45 à 54 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-55-64" value="55-64" {...register("age")} />
                                        <label htmlFor="age-55-64">55 à 64 ans</label>
                                    </div>
                                    <div className="radio-wrapper">
                                        <input type="radio" name="age" id="age-plus-65" value="plus-65" {...register("age")} />
                                        <label htmlFor="age-plus-65">Plus de 65 ans</label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-item">
                              <label>Quelle est votre occupation actuelle ?</label>
                              <div className="radios radios-list">
                                  <div className="radio-wrapper">
                                      <input type="radio" name="occupation" id="occupation-je-travaille" value="je-travaille" {...register("occupation")} />
                                      <label htmlFor="occupation-je-travaille">Je travaille</label>
                                  </div>
                                  <div className="radio-wrapper">
                                      <input type="radio" name="occupation" id="occupation-je-ne-travaille-pas" value="je-ne-travaille-pas" {...register("occupation")} />
                                      <label htmlFor="occupation-je-ne-travaille-pas">Je ne travaille pas</label>
                                  </div>
                                  <div className="radio-wrapper">
                                      <input type="radio" name="occupation" id="occupation-je-suis-en-incapacite-temporaire" value="je-suis-en-incapacite-temporaire" {...register("occupation")} />
                                      <label htmlFor="occupation-je-suis-en-incapacite-temporaire">Je suis en incapacité temporaire</label>
                                  </div>

                                  <div className="radio-wrapper">
                                      <input type="radio" name="occupation" id="occupation-je-suis-en-incapacite-permanente" value="je-suis-en-incapacite-permanente" {...register("occupation")} />
                                      <label htmlFor="occupation-je-suis-en-incapacite-permanente">Je suis en incapacité permanente</label>
                                  </div>

                                  <div className="radio-wrapper">
                                      <input type="radio" name="occupation" id="occupation-je-suis-retraite" value="je-suis-retraite" {...register("occupation")} />
                                      <label htmlFor="occupation-je-suis-retraite">Je suis retraité</label>
                                  </div>
                              </div>
                          </div>

                          <div className="form-item">
                              <label>Etes-vous en transition/reconversion ?</label>
                              <div className="radios radios-list">
                                  <div className="radio-wrapper">
                                      <input type="radio" name="transition" id="transition-oui" value="oui" {...register("transition")} />
                                      <label htmlFor="transition-oui">Oui</label>
                                  </div>
                                  <div className="radio-wrapper">
                                      <input type="radio" name="transition" id="transition-non" value="non" {...register("transition")} />
                                      <label htmlFor="transition-non">Non</label>
                                  </div>
                              </div>
                          </div>

                          <div className="form-item">
                              <label>Quand vous êtes-vous formé pour la dernière fois ?</label>
                              <div className="radios radios-list">
                                  <div className="radio-wrapper">
                                      <input type="radio" name="formation" id="formation-moins-6-mois" value="moins-6-mois" {...register("formation")} />
                                      <label htmlFor="formation-moins-6-mois">Il y a moins de 6 mois</label>
                                  </div>
                                  <div className="radio-wrapper">
                                      <input type="radio" name="formation" id="formation-plus-6-mois" value="plus-6-mois" {...register("formation")} />
                                      <label htmlFor="formation-plus-6-mois">Il y a plus de 6 mois</label>
                                  </div>
                                  <div className="radio-wrapper">
                                      <input type="radio" name="formation" id="formation-en-cours" value="en-cours" {...register("formation")} />
                                      <label htmlFor="formation-en-cours">Je commence (ou je suis) une formation</label>
                                  </div>
                              </div>
                          </div>

                          {/* <div className="form-item">
                              <label>Pouvez-vous apporter plus de précisions ?</label>
                              <div className="select-wrapper">
                                  <select name="other" {...register("other")}>
                                      <option value="" disabled selected>Sélectionnez une réponse</option>
                                      <option value="1">...1...</option>
                                      <option value="2">...2...</option>
                                      <option value="salarie">...3...</option>
                                      <option value="independant">...4...</option>
                                  </select>
                              </div>
                          </div> */}

                          <Button type="submit">Soumettre</Button>
                          <Button 
                              type="button"
                              fn={() => {
                                setFormSocioSkipped(true);
                              }}
                          >
                              Ne pas envoyer
                          </Button>

                      </form>
                      </FormWrapper>
                    ) : null}



                    </div>
                    <div className="footer">
                      {((state.currentChapter === chapterFormIndex && formSocioSubmitted === true) || (state.currentChapter === chapterFormIndex && formSocioSkipped === true) || state.currentChapter !== chapterFormIndex) ? (
                        <>
                          <Button 
                            fn={() => {
                                navigate(`/ressources`, { replace: true })
                            }}
                        >
                            Aller vers le centre de ressources
                        </Button>
                        <Button 
                            fn={() => {
                                navigate(`/`, { replace: true })
                            }}
                        >
                            Retourner à l'accueil
                        </Button>
                        </>
                      ) : null}
                      
                    </div>
                </>
            ) : null} 
                
        </EndGameContainer>
      </EndGameMiddle>
      
    </OrientationBlocker>
  );
};

const EndGameContainer = styled.div` 
    padding: 30px 20px;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
        .game-visual {
            display: block;
            width: 70%;
            margin: 0 auto 30px;
        }
    }

    .footer {

    }

    h1 {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        color: ${colors.darkblue};
        line-height: 120%;
    }

    p {
        font-size: 17px;
        font-weight: 500;
        line-height: 160%;
        margin-bottom: 20px;
        color: ${colors.darkblue90};
    }

    .btn {
        margin-top: 20px;
    }
`;

const ScoreCategory = styled.div`
  border-radius: 15px;
  border: 1px solid ${colors.line};
  box-shadow: 0 5px 0 ${colors.line};
  margin-bottom: 20px;
  overflow: hidden;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    &-icon {
      display: block;
      width: 50px;
      height: 50px;
      background: ${colors.line};
      margin-right: 10px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      flex: 1;
    }

    &-title {
      font-size: 16px;
      font-weight: 500;
      color: ${colors.darkblue};
      margin: 0;
      text-transform: capitalize;

      .additionnal {
        color: ${colors.lightblue};
        font-weight: 600;
        margin-left: 10px;
      }

      svg {
        height: auto;
        fill: ${colors.lightblue};
        margin-left: 4px;
        margin-bottom: -3px;
      }
    }

    &-bar {
      position: relative;
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background: ${colors.line} none;
      margin-top: 15px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: white;
      }

      &::before {
        left: calc(100% / 3);
      }

      &::after {
        right: calc(100% / 3);
      }
    }

    &-progress {
      display: block;
      border-radius: 4px;
      background: ${colors.yellow} none;
      height: 8px;
      width: 0%;
      transition: all .4s .3s ease-in-out;
    }

    &-score {
      display: inline-flex;
      align-items: center;
      color: ${colors.darkblue};

      > span {
        margin-top: 3px;

        &:empty {
          &::before {
            content: '---';
            color: ${colors.darkblue20};
          }
        }
      }

      svg {
        height: auto;
        fill: ${colors.lightblue};
        margin-left: 4px;
      }
    }
  }

  .header {
    border-bottom: 1px solid ${colors.line};
    padding: 20px 20px 0;
    background: ${colors.yellow};
    
    .item-title {
      font-size: 17px;
      text-transform: none;

      strong {
        font-weight: 600;
      }
    }

    .item-inner {
        justify-content: center;
    }
  }

  .detail {
    padding: 20px 20px 0;
    overflow: hidden;
    transition: all .4s ease-in-out;
    max-height: ${props => props.showDetail ? '600px' : '0px'};
    padding-top: ${props => props.showDetail ? '20px' : '0px'};
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkblue};
    border-top: 1px solid ${colors.line};
    margin-top: -1px;

    svg {
      height: 12px;
      margin-left: 5px;

      transform: rotate(${props => props.showDetail ? '180deg' : '0deg'});
    }

    span {
      margin-right: 4px;
    }

    .hided {
      display: ${props => props.showDetail ? 'none' : 'inline-block'};
    }

    .showed {
      display: ${props => props.showDetail ? 'inline-block' : 'none'};
    }
  }
`;

const FormWrapper = styled.div`
    p {
        font-size: 17px;
        font-weight: 500;
        line-height: 160%;
        margin-bottom: 20px;
        color: ${colors.darkblue90};
    }
    .form-item {

        label {
            display: block;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
            color: ${colors.darkblue};
            margin-bottom: 20px;
        }
    }

    .radios {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .radio-wrapper {
        position: relative;
        width: calc(100% / 2 - 25px);

        input[type=radio] {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            left: -20px;
            top: -20px;

            + label {
                position: relative;
                display: block;
                width: calc(100% - 70px);
                padding: 15px 20px 10px 50px;
                border-radius: 15px;
                background: white;
                font-size: 17px;
                font-weight: 400;
                color: ${colors.darkblue};
                border: 1px solid ${colors.line};
                box-shadow: 0 5px 0 ${colors.line};

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(50% + 2px);
                    left: 15px;
                    transform: translate(0, -50%);
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 1px solid ${colors.line};
                    background: white;

                }
            }

            &:checked + label {
                background: ${colors.darkblue};
                border-color: ${colors.darkblue};
                box-shadow: 0 5px 0 ${colors.darkblue};
                color: white;

                &::before {
                    border-color: ${colors.darkblue};
                    background-color: ${colors.lightblue};
	                background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00729 0.69738L3.53154 5.24304L1.49271 3.17236L1.43926 3.11808L1.38582 3.17236L0.696558 3.87239L0.644746 3.92501L0.696558 3.97763L3.4781 6.80262L3.53154 6.8569L3.58499 6.80262L8.80344 1.50265L8.85525 1.45003L8.80344 1.39741L8.11418 0.69738L8.06074 0.643102L8.00729 0.69738Z' fill='white' stroke='white' stroke-width='0.15'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }

        }
    }

    .radios.radios-list {
      .radio-wrapper {
        width: 100%;
      }
    }

    .select-wrapper {
        background-color: white;
	    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.39307 5.79904L11.4185 0.77356L12.3931 1.74808L6.39307 7.74808L0.393066 1.74808L1.36759 0.773559L6.39307 5.79904Z' fill='%231D3C8A' stroke='%231D3C8A' stroke-width='0.3'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - 20px) 50%;
        select {
            position: relative;
            display: block;
            width: 100%;
            padding: 15px 20px 10px 20px;
            margin-bottom: 30px;
            border-radius: 15px;
            background: transparent;
            border: 1px solid ${colors.line};
            box-shadow: 0 5px 0 ${colors.line};
            font-size: 17px;
            font-weight: 400;
            color: ${colors.darkblue};
            -moz-appearance:none; /* Firefox */
            -webkit-appearance:none; /* Safari and Chrome */
            appearance:none;

            option {
            }
        }
    }
`;


export default EndGame;

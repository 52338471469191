import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';

import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';

// import mapSample from '../../../assets/images/game1c-map.svg';
import mapSample from '../../../assets/images/game1c-map.png';
import visualSample from '../../../assets/images/game1c-visual.png';

const Game1c = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {

  const boxOrder1 = useRef(null);
  const wrapperOrder1 = useRef(null);

  const boxOrder2 = useRef(null);
  const wrapperOrder2 = useRef(null);

  const boxOrder3 = useRef(null);
  const wrapperOrder3 = useRef(null);

  const boxOrder4 = useRef(null);
  const wrapperOrder4 = useRef(null);
  const maskOrder4 = useRef(null);

  const boxOrder5 = useRef(null);
  const wrapperOrder5 = useRef(null);

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [order1BoxClassName, order1SetBoxClassName] = useState('');
  const [order1Scale, order1SetScale] = useState(1);
  const [order1ScaleStart, order1SetScaleStart] = useState(1);

  const [order2BoxClassName, order2SetBoxClassName] = useState('');
  const [order2Scale, order2SetScale] = useState(1);
  const [order2ScaleStart, order2SetScaleStart] = useState(1);

  const [order3BoxClassName, order3SetBoxClassName] = useState('');
  const [order3Scale, order3SetScale] = useState(1);
  const [order3ScaleStart, order3SetScaleStart] = useState(1);

  const [order4BoxClassName, order4SetBoxClassName] = useState('');
  const [order4Scale, order4SetScale] = useState(1);
  const [order4ScaleStart, order4SetScaleStart] = useState(1);

  const [order5BoxClassName, order5SetBoxClassName] = useState('');

  const [order5Rotation, order5setRotation] = useState(0);
  const [order5RotationStart, order5setRotationStart] = useState(0);

  useEffect(() => {
    if(boxOrder1.current) {
      boxOrder1.current.style.transform = `scale(${order1Scale}) rotate(0deg)`;
    }
  }, [order1Scale]);

  useEffect(() => {
    if(boxOrder2.current) {
      boxOrder2.current.style.transform = `scale(${order2Scale}) rotate(0deg)`;
    }
  }, [order2Scale]);

  useEffect(() => {
    if(boxOrder3.current) {
      boxOrder3.current.style.transform = `scale(${order3Scale}) rotate(0deg)`;
    }
  }, [order3Scale]);

  useEffect(() => {
    if(boxOrder4.current) {
      boxOrder4.current.style.transform = `scale(${order4Scale}) rotate(0deg)`;
    }
  }, [order4Scale]);

  useEffect(() => {
    if(boxOrder5.current) {
      boxOrder5.current.style.transform = `scale(1) rotate(${order5Rotation}deg)`;
    }
  }, [order5Rotation]);

  

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          onFailOrderWithoutFeedback();
          // console.log('send fail');
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  const options = {
    touchAction:'compute',
    recognizers: {
        tap: { enable: false },
        pinch: { enable: true },
        rotate: { enable: false },
    }
  };

  /// Order 1
  const onPinchOrder1  = (e) => {
    const newScale = order1ScaleStart * e.scale;
    order1SetScale((newScale > 1) ? newScale : 1);
  };

  const onPinchEndOrder1  = () => {
    order1SetScaleStart(order1Scale);

    if(order1Scale > 1) {
      order1SetBoxClassName('completed');
      setShowCustomFeedback("success");
    }
  };

  const onPinchStartOrder1  = (e) => {
    order1SetBoxClassName('');
    order1SetScaleStart(order1Scale);
  };


  /// Order 2
  const onPinchOrder2  = (e) => {
    const newScale = order2ScaleStart * e.scale;
    order2SetScale((newScale > 1) ? newScale : 1);
  };

  const onPinchEndOrder2  = () => {
    order2SetScaleStart(order2Scale);
  };

  const onPinchStartOrder2  = (e) => {
    order2SetBoxClassName('');
    order2SetScaleStart(order1Scale);
  };

  const onClickAreaOrder2  = (e) => {
    if(order2Scale > 1.2) {
      order3SetBoxClassName('completed');
      setShowCustomFeedback("success");
    }
  };

  /// Order 3
  const onPinchOrder3  = (e) => {
    const wrapperWidth = wrapperOrder3.current.offsetWidth;
    const correctScale = wrapperWidth / boxOrder3.current.offsetWidth;

    const newScale = order3ScaleStart * e.scale;

    order3SetScale((newScale < correctScale) ? newScale : correctScale);
    // setRotation(order3rotationStart + e.rotation);
  };

  const onPinchEndOrder3  = () => {
    order3SetScaleStart(order3Scale);
    // setRotationStart(order3rotation);

    const wrapperWidth = wrapperOrder3.current.offsetWidth;
    const boxWidth = boxOrder3.current.offsetWidth * order3Scale;
    const tolerance = wrapperOrder3.current.offsetWidth / 5;

    if((boxWidth > (wrapperWidth - tolerance)) && (boxWidth < (wrapperWidth + tolerance))) {
      const correctScale = wrapperWidth / boxOrder3.current.offsetWidth;
      // console.log('Success');
      order3SetBoxClassName('completed');
      order3SetScale(correctScale);
      setShowCustomFeedback("success");
    }
  };

  const onPinchStartOrder3  = (e) => {
    order3SetBoxClassName('');
    order3SetScaleStart(order3Scale);
  };


  /// Order 4
  const onPinchOrder4  = (e) => {
    const newScale = order4ScaleStart * e.scale;

    order4SetScale((newScale < 1) ? newScale : 1);
  };

  const onPinchEndOrder4  = () => {
    order4SetScaleStart(order4Scale);
    const correctScale = maskOrder4.current.offsetWidth / wrapperOrder4.current.offsetWidth;

    if((correctScale > (order4Scale - (correctScale / 5))) && (correctScale < (order4Scale + (correctScale / 5)))) {
      const correctScale = maskOrder4.current.offsetWidth / wrapperOrder4.current.offsetWidth;
      // console.log('Success');
      order4SetBoxClassName('completed');
      order4SetScale(correctScale);
      setShowCustomFeedback("success");
    }
  };

  const onPinchStartOrder4  = (e) => {
    order3SetBoxClassName('');
    order3SetScaleStart(order4Scale);
  };

  /// Order 5
  const onPinchOrder5  = (e) => {
    // console.log('pinch rotate', order5RotationStart, e.rotation, e.rotation - order5RotationStart);
    order5setRotation(e.rotation - order5RotationStart);
  };

  const onPinchEndOrder5  = (e) => {
    // console.log('end rotate', e.rotation, order5Rotation);
    order5setRotationStart(order5Rotation);
    const correctRotation = -45;
    if((correctRotation > (order5Rotation - 15)) && (correctRotation < (order5Rotation + 15))) {
      order5SetBoxClassName('completed');
      order5setRotation(-45);
      setShowCustomFeedback("success");
    }
  };

  const onPinchStartOrder5  = (e) => {
    // console.log('start rotate', e.rotation);
    order5SetBoxClassName('');
    order5setRotationStart(e.rotation);
  };

  const orderRender = [
    {
      render: () => (
        <GameContainer className="game-container-order1">

          <Hammer
            onPinch={onPinchOrder1}
            onPinchEnd={onPinchEndOrder1}
            onPinchStart={onPinchStartOrder1}
            options={options}
          >
            <div className="events-catcher">
              <div ref={wrapperOrder1} className="wrapper">
                <div ref={boxOrder1} className={`box ${order1BoxClassName}`}>
                  <img src={mapSample} alt="" />
                </div>
                <CustomFeedback show={showCustomFeedback} className="custom-feedback">
                  <div className={`icon-circle`}>
                    <Icon name="check" className="icon-success"/>
                    <Icon name="cross" className="icon-failed"/>
                  </div>
                </CustomFeedback>
              </div>
            </div>
          </Hammer>
          

        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order1 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order2">

          <Hammer
            onPinch={onPinchOrder2}
            onPinchEnd={onPinchEndOrder2}
            onPinchStart={onPinchStartOrder2}
            options={options}
          >
            <div className="events-catcher">
              <div ref={wrapperOrder2} className="wrapper">
                <div ref={boxOrder2} className={`box ${order2BoxClassName}`}>
                  <img src={mapSample} alt="" />
                  <svg className="image-map" width="344px" height="341px" viewBox="0 0 344 341">
                    <path onClick={onClickAreaOrder2} className="area-click" fill="red" d="M 170.8125 167.238281 L 183.535156 175.53125 L 187.976562 168.722656 L 175.253906 160.429688 Z M 170.8125 167.238281 "/>
                  </svg>
                </div>
                <CustomFeedback show={showCustomFeedback} className="custom-feedback">
                  <div className={`icon-circle`}>
                    <Icon name="check" className="icon-success"/>
                    <Icon name="cross" className="icon-failed"/>
                  </div>
                </CustomFeedback>
              </div>
            </div>
          </Hammer>
          

        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order2 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order3">

          <Hammer
            onPinch={onPinchOrder3}
            onPinchEnd={onPinchEndOrder3}
            onPinchStart={onPinchStartOrder3}
            options={options}
          >
            <div className="events-catcher">
              <div ref={wrapperOrder3} className="wrapper">
                <div ref={boxOrder3} className={`box ${order3BoxClassName}`}>
                  <img src={visualSample} alt="" />
                </div>
                <CustomFeedback show={showCustomFeedback} className="custom-feedback">
                  <div className={`icon-circle`}>
                    <Icon name="check" className="icon-success"/>
                    <Icon name="cross" className="icon-failed"/>
                  </div>
                </CustomFeedback>
              </div>
            </div>
          </Hammer>

        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order3 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order4">

          <Hammer
            onPinch={onPinchOrder4}
            onPinchEnd={onPinchEndOrder4}
            onPinchStart={onPinchStartOrder4}
            options={options}
          >
            <div className="events-catcher">
              <div ref={wrapperOrder4} className="wrapper">
                <div ref={boxOrder4} className={`box ${order4BoxClassName}`}>
                  <img src={visualSample} alt="" />
                  <CustomFeedback show={showCustomFeedback} className="custom-feedback">
                    <div className={`icon-circle`}>
                      <Icon name="check" className="icon-success"/>
                      <Icon name="cross" className="icon-failed"/>
                    </div>
                  </CustomFeedback>
                </div>
                <div ref={maskOrder4} className="mask"></div>
                
              </div>
            </div>
          </Hammer>

        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order4 rended');
      }
    },
    {
      render: () => (
        <GameContainer className="game-container-order5">

          <Hammer
            // onRotate={onPinchOrder5}
            // onRotateEnd={onPinchEndOrder5}
            // onRotateStart={onPinchStartOrder5}

            onPinch={onPinchOrder5}
            onPinchEnd={onPinchEndOrder5}
            onPinchStart={onPinchStartOrder5}
            options={options}
          >
            <div className="events-catcher">
              <div ref={wrapperOrder5} className="wrapper">
                <div ref={boxOrder5} className={`box ${order5BoxClassName}`}>
                  <img src={visualSample} alt="" />
                  <CustomFeedback show={showCustomFeedback} className="custom-feedback">
                    <div className={`icon-circle`}>
                      <Icon name="check" className="icon-success"/>
                      <Icon name="cross" className="icon-failed"/>
                    </div>
                  </CustomFeedback>
                </div>
              </div>
            </div>
          </Hammer>

        </GameContainer>
      ),
      afterRender: () => {
        // console.log('order5 rended');
      }
    },
  ];

  return (
    <>
      {orderRender[orderIndex].render()}
    </>
  );
};

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .events-catcher {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 42px);
    height: auto;
    background: white;
    border: 1px solid ${colors.line};
    border-radius: 15px;
    box-shadow: inset 5px 5px 0 rgba(29, 60, 138, 0.2);

    &::before {
      content: '';
      padding-bottom: 100%;
    }

    .custom-feedback {
      border-radius: 15px;
    }
  }

  &.game-container-order1 {

    .wrapper {
      border: 0;
      width: 70%;
      box-shadow: none;
      overflow: hidden;
    }

    .box {
      width: 100%;
      overflow:hidden;
      
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }

      &.completed {
        transition: all .4s ease-in-out;
      }
    }
  }

  &.game-container-order2 {

    .wrapper {
      border: 0;
      width: 100%;
      height: 100%;
      box-shadow: none;

      .custom-feedback {
        border-radius: 0;
      }
    }

    .box {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      
      img,
      svg.image-map {
        display: block;
        width: 100%;
        height:100%;
        border-radius: 15px;
        overflow: hidden;
        object-fit: contain;
      }

      .area-click {
        fill: ${colors.lightblue};
        box-shadow: 0 0 2px rgba(29, 60, 138, 0.2);
      }

      svg.image-map {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }

      &.completed {
        transition: all .4s ease-in-out;
      }
    }
  }
  
  &.game-container-order3 {
    .box {
      width: 59%;
      overflow:hidden;
      
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }

      &.completed {
        transition: all .4s ease-in-out;
      }
    }
  }

  &.game-container-order4 {
    .wrapper {
      .mask {
        box-sizing: border-box;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 59%;
        height: 59%;
        overflow:hidden;
        border-radius: 4.5%;
        border: 1px solid ${colors.line};
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
      } 
    }
    .box {
      width: 100%;
      overflow:hidden;
      
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
      }

      &.completed {
        transition: all .4s ease-in-out;
      }
    }
  }

  &.game-container-order5 {
    .wrapper {
      width: calc(75% - 42px);
      /* transform: rotate(-45deg); */

      .mask {
        box-sizing: border-box;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 59%;
        height: 59%;
        overflow:hidden;
        border-radius: 4.5%;
        border: 1px solid ${colors.line};
        box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
      } 
    }
    .box {
      width: 100%;
      
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        transform: rotate(45deg);
      }

      &.completed {
        transition: all .4s ease-in-out;
      }

      .custom-feedback {
        transform: rotate(45deg);
        .icon-circle {
          //transform: rotate(135deg);
        }
      }
    }
  }
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game1c;
import React from 'react';

const clickableArea = {
    "order1Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="428" width="375" height="205" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="417" width="375" height="170" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="49" y="169" width="89" height="46" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="196" y="164" width="179" height="48" fill="#D9D9D9"/>
            <rect y="67" width="375" height="45" fill="#D9D9D9" data-none="none" class="area"></rect>
        </svg>
    ),
    "order4Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="137" y="212" width="138" height="44" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="153" y="466" width="196" height="76" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="417" width="375" height="170" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="321" y="56" width="54" height="73" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="424" width="375" height="79" fill="#D9D9D9"/>
        </svg>
    ),
    
};

const Area = (props) => {
    const child = clickableArea[props.name];
    const newChild = React.cloneElement(child, {
        className: `clickable-area`,
        preserveAspectRatio: 'xMinYMin meet',
        onClick: props.clickOutOfArea,
        children: React.Children.map(child.props.children, (subchild => {
            return React.cloneElement(subchild, {
                className: 'area',
                onClick: props.handleClick
            })
        }))
    });
    
    return newChild;
}

export default Area;
import { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import styled from 'styled-components';


import { colors } from '../../../utils/variables';
import { arrayMove, insertAtIndex, removeAtIndex } from '../../../utils/array';
import Icon from '../../../components/Icon';
import Button from '../../../components/button';


const Game3a = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentIdVideoPlaylist, setCurrentIdVideoPlaylist] = useState(0);

  const [order2StartTime, order2SetStartTime] = useState(0);
  const [order2EndTime, order2SetEndTime] = useState(0);

  const [order3Current, order3SetCurrent] = useState(0);

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [controlPlaylist, setControlPlaylist] = useState(null);

  const ordersPlaylist = [
    [
      {
        title: '',
        src: '/videos/sample.mp4',
        type: 'video/mp4'
      }
    ],
    [
      {
        title: '',
        src: '/videos/sample.mp4',
        type: 'video/mp4'
      }
    ],
    [
      {
        title: 'Vidéo 1/3',
        src: '/videos/sample.mp4',
        type: 'video/mp4'
      },
      {
        title: 'Vidéo 2/3',
        src: '/videos/sample-long.mp4',
        type: 'video/mp4'
      },
      {
        title: 'Vidéo 3/3',
        src: '/videos/sample-2.mp4',
        type: 'video/mp4'
      }
    ],
    [
      {
        title: '',
        src: '/videos/sample-long.mp4',
        type: 'video/mp4'
      }
    ],
    [
      {
        title: '',
        src: '/videos/sample-long.mp4',
        type: 'video/mp4'
      }
    ]
  ];

  useEffect(() => {
    if(orderIndex === 1 && order2StartTime > 0 && order2EndTime > 0 ) {
      // console.log('Check order 2', (order2EndTime - order2StartTime)/1000, (((order2EndTime - order2StartTime)/1000) > 5));
      if((((order2EndTime - order2StartTime)/1000) > 4) && ((order2EndTime - order2StartTime)/1000 < 7)) {
        // console.log('SUCCESS');
        setShowCustomFeedback("success");
      } else {
        // console.log('FAILED');
        setShowCustomFeedback("failed");
      } 
    }
  }, [order2EndTime]);
  

  const basePlayerOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    breakpoints: {
      tiny: 100,
      xsmall: 100,
      small: 100,
      medium: 100,
      large: 1000,
      xlarge: 1000,
      huge: 1000
    },
    sources: [{
      src: '/videos/sample.mp4',
      type: 'video/mp4'
    }]
  };

  const playerOptionsByOrder = [
    {
      ...basePlayerOptions,
    },
    {
      ...basePlayerOptions,
    },
    {
      ...basePlayerOptions,
    },
    {
      ...basePlayerOptions,
    },
    {
      ...basePlayerOptions,
    },
  ];

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.volume(0);

    const SeekBar = videojs.getComponent("SeekBar");

    SeekBar.prototype.getPercent = function getPercent() {
      const time = this.player_.currentTime();
      const percent = time / this.player_.duration();
      return percent >= 1 ? 1 : percent;
    };

    SeekBar.prototype.handleMouseMove = function handleMouseMove(event) {
      let newTime = this.calculateDistance(event) * this.player_.duration();
      if (newTime === this.player_.duration()) {
        newTime = newTime - 0.1;
      }
      this.player_.currentTime(newTime);
      this.update();
      let currentTime = player.currentTime();
      let minutes = Math.floor(currentTime / 60);
      let seconds = Math.floor(currentTime - minutes * 60);
      let x = minutes < 10 ? "0" + minutes : minutes;
      let y = seconds < 10 ? "0" + seconds : seconds;
      let format = x + ":" + y;
      player.controlBar.currentTimeDisplay.el_.innerHTML = format;
    };

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });

    orderRender[orderIndex].afterRender(player);
  };

  useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      playerOptionsByOrder[orderIndex].sources[0] = {
        src: ordersPlaylist[orderIndex][currentIdVideoPlaylist].src,
        type: ordersPlaylist[orderIndex][currentIdVideoPlaylist].type
      };

      const player = playerRef.current = videojs(videoElement, playerOptionsByOrder[orderIndex], () => {
        videojs.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      // const player = playerRef.current;

      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [playerOptionsByOrder, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    setCurrentVideo(ordersPlaylist[orderIndex][0]);
  }, [orderIndex]);

  useEffect(() => {
    if(controlPlaylist) {
      const player = playerRef.current;
      let targetVideoId;

      if(controlPlaylist === "previous") {
        targetVideoId = currentIdVideoPlaylist - 1 > -1 ? currentIdVideoPlaylist - 1 : currentIdVideoPlaylist;
      } else if(controlPlaylist === "next") {
        targetVideoId = currentIdVideoPlaylist + 1 < ordersPlaylist[orderIndex].length ? currentIdVideoPlaylist + 1 : currentIdVideoPlaylist;
      }

      setCurrentIdVideoPlaylist(targetVideoId);
      setCurrentVideo(ordersPlaylist[orderIndex][targetVideoId]);

      player.src({
        src: ordersPlaylist[orderIndex][targetVideoId].src,
        type: ordersPlaylist[orderIndex][targetVideoId].type
      });

      player.load();
      player.play();

      setControlPlaylist(null);
    }
  }, [controlPlaylist]);

  useEffect(() => {
    if(orderIndex === 2 && currentIdVideoPlaylist === 2) {
      setShowCustomFeedback("success");
    }
  }, [currentIdVideoPlaylist]);


  const orderRender = [
    {
      render: () => (
        <>
          <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' playsInline />
          </div>
          {currentVideo && currentVideo.title ? <div className="video-title">{currentVideo.title}</div> : null}
        </>
      ),
      afterRender: (player) => {
        addCustomFullscreenButton();

        player.on('play', () => {
          setShowCustomFeedback("success");
        });
      }
    },
    {
      render: () => (
        <>
          <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' playsInline />
          </div>
          {currentVideo && currentVideo.title ? <div className="video-title">{currentVideo.title}</div> : null}
        </>
      ),
      afterRender: (player) => {
        addCustomFullscreenButton();

        player.on('play', () => {
          order2SetStartTime(new Date().getTime());
        });
        player.on('pause', () => {
          order2SetEndTime(new Date().getTime());
        });
      }
    },
    {
      render: () => (
        <>
          <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' playsInline />
          </div>
          {currentVideo && currentVideo.title ? <div className="video-title">{currentVideo.title}</div> : null}
        </>
      ),
      afterRender: (player) => {

        addCustomFullscreenButton();

        const previousVideoButtonBase = videojs.getComponent('Button');
        const previousVideoButton = videojs.extend(previousVideoButtonBase, {
          constructor: function() {
            previousVideoButtonBase.apply(this, arguments);
            this.controlText("Previous video");
            this.addClass('vjs-previous-video-control');
          },
          handleClick: () => {
            setControlPlaylist("previous");
          }
        });
        videojs.registerComponent('previousVideoButton', previousVideoButton);
        player.getChild('controlBar').addChild('previousVideoButton', {});

        const nextVideoButtonBase = videojs.getComponent('Button');
        const nextVideoButton = videojs.extend(nextVideoButtonBase, {
          constructor: function() {
            nextVideoButtonBase.apply(this, arguments);
            this.controlText("Next video");
            this.addClass('vjs-next-video-control');
          },
          handleClick: () => {
            setControlPlaylist("next");
          }
        });
        videojs.registerComponent('nextVideoButton', nextVideoButton);
        player.getChild('controlBar').addChild('nextVideoButton', {});

        player.on('pause', () => {
          setShowCustomFeedback("failed");
        });

      }
    },
    {
      render: () => (
        <>
          <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' playsInline />
          </div>
          {currentVideo && currentVideo.title ? <div className="video-title">{currentVideo.title}</div> : null}
        </>
      ),
      afterRender: (player) => {
        addCustomFullscreenButton();

        player.on("seeking", (e) => {
          const currentTime = player.currentTime();
          if(currentTime > 42 && currentTime < 48) {
            player.currentTime(45);
            player.pause();
            setShowCustomFeedback("success");
          }
        });

        player.on("timeupdate", (e) => {
          const currentTime = player.currentTime();
          if(currentTime > 44 && currentTime < 45) {
            player.pause();
            setShowCustomFeedback("success");
          }
        });
      }
    },
    {
      render: () => (
        <>
          <div data-vjs-player>
            <video ref={videoRef} className='video-js vjs-big-play-centered' playsInline />
          </div>
          {currentVideo && currentVideo.title ? <div className="video-title">{currentVideo.title}</div> : null}
        </>
      ),
      afterRender: (player) => {
        addCustomFullscreenButton();

        player.on("seeking", (e) => {
          setShowCustomFeedback("failed");
        });
      }
    },
  ];

  useEffect(() => {
    setCurrentVideo(ordersPlaylist[orderIndex][0]);
  }, [orderIndex]);

  

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          // console.log('send fail');
          onFailOrder();
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  const addCustomFullscreenButton = () => {
    const player = playerRef.current;
    // console.log('add fullscreen');
    const fullscreenCustomButtonBase = videojs.getComponent('Button');
    const fullscreenCustomButton = videojs.extend(fullscreenCustomButtonBase, {
      constructor: function() {
        fullscreenCustomButtonBase.apply(this, arguments);
        this.controlText("Fullscreen");
        this.addClass('vjs-fullscreen-control');
        this.addClass('vjs-fullscreen-custom-control');
      },
      handleClick: switchCustomFullscreen
    });
    videojs.registerComponent('fullscreenCustomButton', fullscreenCustomButton);
    player.getChild('controlBar').addChild('fullscreenCustomButton', {});
  }

  const switchCustomFullscreen = () => {
    const player = playerRef.current;
    player.toggleClass('vjs-layout-fullscreen');

    if(orderIndex === 4) {
      setShowCustomFeedback("success");
    }
  }
  

  return (
    <GameContainer>
      
        {orderRender[orderIndex].render()}

      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;


  .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    .vjs-control,
    .vjs-time-divider {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .vjs-progress-control,
    .vjs-time-control,
    .vjs-fullscreen-control {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  .video-title {
    padding: 20px;
    width: calc(100% - 40px);
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: ${colors.darkblue};
  }

  .video-js {

    width: calc(100vw - 80px);
    margin: 40px 40px 0 40px;
    border-radius: 8px;

    .vjs-fullscreen-control:not(.vjs-fullscreen-custom-control) {
      display: none;
    }

    &:not(.vjs-has-started) {
      width: calc(100vw - 80px);
      height: 200px;
      overflow: hidden;
      border-radius: 15px;
      margin: 40px 40px 0 40px;

      + .video-title {
        display: none;
      }
    }

    .vjs-time-control {
      display: block;
      font-size: 15px;
      font-weight: 500;
      padding: 0 3px;
      width: auto;
      min-width: 0;

      > div {
        display: inline-block;
      }
    }
    .vjs-remaining-time {
      display: none;
    }
    .vjs-volume-panel {
      display: none;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }


    .vjs-control-bar {
      height: 100%;
      align-items: flex-end;
      background-color: transparent;

      .vjs-control {
        height: auto;
      }

      .vjs-play-control {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
        height: 70px;
        background: ${colors.lightblue};
      }

      .vjs-previous-video-control {
        position: absolute;
        top: 50%;
        left: calc(50% - 70px);
        background-color: transparent;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 12L21.5 22V2L11.5 12Z' fill='white' stroke='white'/%3E%3Cpath d='M0.833008 12L10.833 22V2L0.833008 12Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      .vjs-next-video-control {
        position: absolute;
        top: 50%;
        left: calc(50% + 70px);
        background-color: transparent;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background: transparent;
        background-image: url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 12L0.5 22V2L10.5 12Z' fill='white' stroke='white'/%3E%3Cpath d='M21.167 12L11.167 22V2L21.167 12Z' fill='white' stroke='white'/%3E%3C/svg%3E");
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }



      .vjs-progress-control {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        width: 100%;
        display: block !important;

        .vjs-progress-holder {
          margin: 0;
          height: 5px;
          background: ${colors.darkblue20};

          .vjs-load-progress {
            height: 5px;
            background: ${colors.darkblue20};
          }

          .vjs-play-progress {
            height: 5px;
            background: ${colors.lightblue};

            &::before {
              content: '';
              width: 13px;
              height: 13px;
              top: -4px;
              right: -4px;
              bottom: -4px;
              left: auto;
              background: ${colors.lightblue};
              border-radius: 50%;
            }
          }
        }
      }

      .vjs-fullscreen-control {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 40px;
      }
    }
  }

  .vjs-big-play-button,
  .vjs-play-control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background: ${colors.lightblue} none;
    border-radius: 50%;
    border: 0;
    margin: 0;

    .vjs-icon-placeholder {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
    }
    .vjs-icon-placeholder::before {
      position: absolute;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 28px;
    }
  }

  .video-js.vjs-layout-fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    background: ${colors.darkblue90};
    border-radius: 0;
  }

  
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game3a;
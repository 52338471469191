import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';

const Game1a = ({orderIndex, onWinOrder, onFailOrder}) => {
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);
  const [nbrRows, setNbrRows] = useState(false);
  const [nbrColumns, setNbrColumns] = useState(false);

  const [horizontalSpace, setHorizontalSpace] = useState(false);
  const [verticalSpace, setVerticalSpace] = useState(false);

  const [gridElements, setGridElements] = useState(false);
  const [memoElements, setMemoElements] = useState([]);

  const container = useRef(null);
  const containerScrollable = useRef(null);
  const compass = useRef(null);

  const orderIconCurrent = useRef(null);

  const itemSize = 95;

  const ordersIcons = [
    {
      icon: 'download',
    },
    {
      icon: 'home',
    },
    {
      icon: 'search',
    },
    {
      icon: 'settings',
    },
    {
      icon: 'profile',
    }
  ];

  useEffect(() => {
    const gameViewWidth = containerScrollable.current.offsetWidth;
    const gameViewHeight = containerScrollable.current.offsetHeight;
    const gameSpaceWidth = container.current.offsetWidth;
    const gameSpaceHeight = container.current.offsetHeight;

    setNbrRows(Math.floor(gameSpaceHeight / itemSize));
    setNbrColumns(Math.floor(gameSpaceWidth / itemSize));
  
    setHorizontalSpace(Math.floor(((gameSpaceWidth - gameViewWidth)/2)/itemSize));
    setVerticalSpace(Math.floor(((gameSpaceHeight - gameViewHeight)/2)/itemSize));

    containerScrollable.current.scrollTo({
      top: Math.ceil((gameSpaceHeight-gameViewHeight)/2), 
      left: Math.ceil((gameSpaceWidth-gameViewWidth)/2)
    });

    containerScrollable.current.addEventListener('scroll', handleScrollContainerScrollable);

    placeIcons();

    setTimeout(() => {
      containerScrollable.current.scrollTo({
        top: Math.ceil((gameSpaceHeight-gameViewHeight)/2), 
        left: Math.ceil((gameSpaceWidth-gameViewWidth)/2)
      });
    }, 200);

  }, [nbrRows, nbrColumns, horizontalSpace, verticalSpace]);

  useEffect(() => {
    setGridElements(renderGrid());
  }, [nbrRows, nbrColumns, horizontalSpace, verticalSpace]);

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          //onWinOrderWithoutFeedback();
          onWinOrder();
        } else if(showCustomFeedback === "failed") {
          //onFailOrderWithoutFeedback();
          onFailOrder();
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  const handleScrollContainerScrollable = (e) => {
    if(compass && compass.current && orderIconCurrent && orderIconCurrent.current) {
      const compassPos = compass.current.getBoundingClientRect();
      const iconPos = orderIconCurrent.current.getBoundingClientRect();

      // console.log('compassPos', compassPos.x, compassPos.y);
      // console.log('iconPos X', iconPos, container.current.offsetWidth/2);
      // console.log('iconPos Y', iconPos.y, container.current.offsetHeight/2);

      if (
        iconPos.x > 0 && 
        iconPos.y > -75 && 
        iconPos.x < (container.current.offsetWidth/2 - 75) && 
        iconPos.y < (container.current.offsetHeight/2 -75)
      ) {
        compass.current.style.opacity = 0.2;
      } else {
        compass.current.style.opacity = 1;
      }

      const angle = Math.atan2(
        iconPos.x - compassPos.x,
        -(iconPos.y - compassPos.y)
      ) *
      (180 / Math.PI);

      compass.current.style.transform = `rotate(${angle}deg)`;
    }
  }

  const initShowCompass = () => {
    // console.log('initShowCompass');
  }

  const placeIcons = () => {
    // console.log('=====================================');
    const memoArray = {};
    const memoList = [];
    if(nbrColumns !== false && nbrRows !== false) {
      ordersIcons.map((orderIconData, orderIconIndex) => {
        // console.log('---------------------------');
        let columnPosition = -1;
        let rowPosition = -1;

        // console.log('hum?',nbrColumns, nbrRows);

        while(
          (
            (
              (columnPosition === -1) || 
              (
                (columnPosition > horizontalSpace) && 
                (columnPosition <= nbrColumns - horizontalSpace)
              )
            ) && (
              (rowPosition === -1) || 
              (
                (rowPosition > verticalSpace) && 
                (rowPosition <= nbrRows - verticalSpace)
              )
            )
          )
          ||
          (memoList.indexOf(`${columnPosition}, ${rowPosition}`) > -1)
        ) {
          columnPosition = Math.floor(Math.random() * nbrColumns) + 1;
          rowPosition = Math.floor(Math.random() * nbrRows) + 1;
          // console.log('while', orderIconIndex, columnPosition, rowPosition);
          // console.log('while check', (memoList.indexOf(`${columnPosition}, ${rowPosition}`)));
        }

        memoList.push(`${columnPosition}, ${rowPosition}`);

        ordersIcons[orderIconIndex].columnPosition = columnPosition;
        ordersIcons[orderIconIndex].rowPosition = rowPosition;

        // console.log('after while', orderIconIndex, columnPosition, rowPosition);
        // console.log('---------------------------');
      });
    }

    // console.log('=====================================', memoList);
  }

  const renderGrid = () => {
    const htmlGrid = [];
    for(let rows = 1; rows < nbrRows+1; rows++) {
      const htmlCells = [];
      
      for(let columns = 1; columns < nbrColumns+1; columns++) {
        const inCenterViewVertical = ((columns > horizontalSpace) && (columns <= nbrColumns - horizontalSpace));
        const inCenterViewHorizontal = ((rows > verticalSpace) && (rows <= nbrRows - verticalSpace));
        const inCenterView = inCenterViewVertical && inCenterViewHorizontal;

        //let contentCell = (<>{rows}/{columns}</>);
        let contentCell = (<></>);
        let classCell = '';
        ordersIcons.map((orderIconData, orderIconIndex) => {
          if(orderIconData.rowPosition === rows && orderIconData.columnPosition === columns) {
            if(orderIconIndex === orderIndex) {
              contentCell = (<div ref={orderIconCurrent} onClick={() => {setShowCustomFeedback('success')}}><Icon name={orderIconData.icon} /></div>);
            } else {
              contentCell = (<div onClick={() => {setShowCustomFeedback('failed')}}><Icon name={orderIconData.icon} /></div>);
            }
            classCell = 'with-icon';
            ordersIcons[orderIconIndex].added = true;
          }
        });

        htmlCells.push(
          <div className={`circle ${classCell} ${inCenterView ? 'col-in-center-view' : ''}`} key={`game1a-${orderIndex}-circle-${rows}-${columns}`}>
            {contentCell}
          </div>
        );
      }

      htmlGrid.push(<div className={`rows`} key={`game1a-${orderIndex}-circle-${rows}`}>{htmlCells}</div>);
    }

    return htmlGrid;
  }

  return (
    <>
      <GameContainer ref={containerScrollable}>
        <div className="game-container" ref={container}>
          {gridElements}
        </div>
      </GameContainer>
      <Compass ref={compass}>
      <svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.829316 36.3298L13.5678 0.444853L29.0916 35.2157L14.7147 29.5384L0.829316 36.3298Z" fill="#1D3C8A"/>
      </svg>
      </Compass>
      <CustomFeedback show={showCustomFeedback}>
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </>
  );
};

const Compass = styled.div`
  position: fixed;
  top: 50%;
  right: 20px;
  width: 35px;
	height: 35px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .4s ease-in-out;

  svg {
    width: 35px;
    height: 35px;
  }
`;
const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .game-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 200%;
    height: 200%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .rows {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 73px;
    height: 73px;
    border-radius: 50%;
    border: 1px solid ${colors.line};
    margin: 10px;

    &.col-in-center-view {
      /* background: grey; */
    }

    &.with-icon {
      background: ${colors.lightblue};
      color: white;
    }

    svg {
      height: auto;
    }
  }
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  z-index: 50;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    `}
  }
`;

export default Game1a;
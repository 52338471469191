import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';


import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';
import Button from '../../../components/button';

import Area from './area';

import order1Screen1 from '../../../assets/images/game3b/order1/screen1.png';
import order1Screen2 from '../../../assets/images/game3b/order1/screen2.png';
import order1Screen3 from '../../../assets/images/game3b/order1/screen3.png';
import order1Screen4 from '../../../assets/images/game3b/order1/screen4.png';

import order2Screen1 from '../../../assets/images/game3b/order2/screen1.png';
import order2Screen2 from '../../../assets/images/game3b/order2/screen2.png';
import order2Screen3 from '../../../assets/images/game3b/order2/screen3.png';
import order2Screen4 from '../../../assets/images/game3b/order2/screen4.png';
import order2Screen5 from '../../../assets/images/game3b/order2/screen5.png';

import order3Screen1 from '../../../assets/images/game3b/order3/screen1.png';
import order3Screen1Bottom from '../../../assets/images/game3b/order3/screen1-bottom.png';
import order3Screen2 from '../../../assets/images/game3b/order3/screen2.png';
import order3Screen3 from '../../../assets/images/game3b/order3/screen3.png';
import order3Screen4 from '../../../assets/images/game3b/order3/screen4.png';
import order3Screen5 from '../../../assets/images/game3b/order3/screen5.png';

import order4Screen1 from '../../../assets/images/game3b/order4/screen1.png';
import order4Screen1Bottom from '../../../assets/images/game3b/order4/screen1-bottom.png';
import order4Screen2 from '../../../assets/images/game3b/order4/screen2.png';
import order4Screen3 from '../../../assets/images/game3b/order4/screen3.png';
import order4Screen4 from '../../../assets/images/game3b/order4/screen4.png';

import order5Screen1 from '../../../assets/images/game3b/order5/screen1.png';
import order5Screen1Bottom from '../../../assets/images/game3b/order5/screen1-bottom.png';
import order5Screen2 from '../../../assets/images/game3b/order5/screen2.png';
import order5Screen3 from '../../../assets/images/game3b/order5/screen3.png';
import order5Screen4 from '../../../assets/images/game3b/order5/screen4.png';


const Game3b = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {
  const GameContainerEl = useRef();
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [currentOrderScreen, setCurrentOrderScreen] = useState(0);

  useEffect(() => {
    if(GameContainerEl && GameContainerEl.current) {
      GameContainerEl.current.scrollTo(0, 0);
    }
  }, [orderIndex]);

  useEffect(() => {
    if(!orderScreens[orderIndex][currentOrderScreen].area) {
      setTimeout(() => {
        endGame();
      }, 500);
    }
  }, [currentOrderScreen]);

  const orderScreens = [
    [
      {
        screen: order1Screen1,
        screenBackgroundColor: '#F2F2F6',
        area: "order1Screen1"
      },
      {
        screen: order1Screen2,
        screenBackgroundColor: '#F2F2F6',
        area: "order1Screen2"
      },
      {
        screen: order1Screen3,
        screenBackgroundColor: '#F2F2F6',
        area: "order1Screen3"
      },
      {
        screen: order1Screen4,
        screenBackgroundColor: '#F2F2F6',
      }
    ],
    [
      {
        screen: order2Screen1,
        screenBackgroundColor: '#F2F2F6',
        area: "order2Screen1"
      },
      {
        screen: order2Screen2,
        screenBackgroundColor: '#F2F2F6',
        area: "order2Screen2"
      },
      {
        screen: order2Screen3,
        screenBackgroundColor: '#F2F2F6',
        area: "order2Screen3"
      },
      {
        screen: order2Screen4,
        screenBackgroundColor: '#F2F2F6',
        area: "order2Screen4"
      },
      {
        screen: order2Screen5,
        screenBackgroundColor: '#F2F2F6',
      }
    ],
    [
      {
        screen: order3Screen1,
        screenBottom: order3Screen1Bottom,
        screenBackgroundColor: '#7B848C',
        area: "order3Screen1"
      },
      {
        screen: order3Screen2,
        screenBackgroundColor: '#F2F2F6',
        area: "order3Screen2"
      },
      {
        screen: order3Screen3,
        screenBackgroundColor: '#F2F2F6',
        area: "order3Screen3"
      },
      {
        screen: order3Screen4,
        screenBackgroundColor: '#F2F2F6',
        area: "order3Screen4"
      },
      {
        screen: order3Screen5,
        screenBackgroundColor: '#fff',
      }
    ],
    [
      {
        screen: order4Screen1,
        screenBottom: order4Screen1Bottom,
        screenBackgroundColor: '#7B848C',
        area: "order4Screen1"
      },
      {
        screen: order4Screen2,
        screenBackgroundColor: '#EBECF0',
        area: "order4Screen2"
      },
      {
        screen: order4Screen3,
        screenBackgroundColor: '#F8F9FA',
        area: "order4Screen3"
      },
      {
        screen: order4Screen4,
        screenBackgroundColor: '#ffffff',
      }
    ],
    [
      {
        screen: order5Screen1,
        screenBottom: order5Screen1Bottom,
        screenBackgroundColor: '#7B848C',
        area: "order5Screen1"
      },
      {
        screen: order5Screen2,
        screenBackgroundColor: '#000000',
        area: "order5Screen2"
      },
      {
        screen: order5Screen3,
        screenBackgroundColor: '#000000',
        area: "order5Screen3"
      },
      {
        screen: order5Screen4,
        screenBackgroundColor: '#000000'
      },
    ]
  ];

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          // console.log('send fail');
          onFailOrder();
        }
      }, 1200);
    }
  }, [showCustomFeedback]);

  const nextScreen = (e) => {
    e.stopPropagation();
    const nbrScreen = orderScreens[orderIndex].length;

    if(GameContainerEl && GameContainerEl.current) {
      GameContainerEl.current.scrollTo(0, 0);
    }

    if(e.target && e.target.getAttribute('data-none')) {

    } else {
      if(currentOrderScreen + 1 < nbrScreen) {
        setCurrentOrderScreen(currentOrderScreen + 1);
      }
    }
    
  }

  const endGame = () => {
    setShowCustomFeedback("success");
  }

  const clickOutOfArea = () => {
    setShowCustomFeedback("failed");
  }
  
  return (
    <GameContainer ref={GameContainerEl}>
      
      {orderScreens[orderIndex][currentOrderScreen] ? (
        <div className="screen-full" style={{backgroundColor: orderScreens[orderIndex][currentOrderScreen].screenBackgroundColor}}>
          <div className="screen-wrapper" style={{backgroundColor: orderScreens[orderIndex][currentOrderScreen].screenBackgroundColor}}>
            <img src={orderScreens[orderIndex][currentOrderScreen].screen} className="screen-image" />
            {orderScreens[orderIndex][currentOrderScreen].screenBottom ? <img src={orderScreens[orderIndex][currentOrderScreen].screenBottom} className="screen-image-bottom" /> : null}
            {orderScreens[orderIndex][currentOrderScreen].area ? (
              <Area name={orderScreens[orderIndex][currentOrderScreen].area} handleClick={nextScreen} clickOutOfArea={clickOutOfArea}/>
            ) : null}
          </div>
        </div>
      ) : null}

      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;

  .screen-full {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .screen-wrapper {
    position: relative;
    width: 100%;
  }

  .screen-image {
    display: block;
    width: 100%;
  }

  .screen-image-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  .clickable-area {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;

    .area {
      fill: rgba(255,0,0, 0);
    }
  }

`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game3b;
import { createContext } from 'react';

let originalGameProgress = {};

export const getGameProgression = () => {
    originalGameProgress = JSON.parse(JSON.stringify(contextDefaultValue.gameProgress))
    const existingGameProgression = localStorage.getItem('gameProgression');
    let output = contextDefaultValue;
    if(existingGameProgression) {
        output.gameProgress = JSON.parse(existingGameProgression);
    }
    return output;
}

const saveGameProgression = () => {
    // console.log('SAVE', contextDefaultValue.gameProgress);
    localStorage.setItem('gameProgression', JSON.stringify(contextDefaultValue.gameProgress));
}

const resetGameProgression = () => {
    // console.log('reset game progress');
    localStorage.setItem('formSocioSubmitted', 'false');
    localStorage.setItem('gameProgression', JSON.stringify(originalGameProgress));
    contextDefaultValue.gameProgress = originalGameProgress;
}

const updateStatus = (newStatus, chapterIndex, gameIndex, orderIndex, timeLeft, remainingTries) => {
    // console.log('updateStatus', chapterIndex, gameIndex, orderIndex, timeLeft);
    if(chapterIndex !== undefined && gameIndex !== undefined && orderIndex !== undefined) {
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].status = newStatus;
        if(timeLeft) {
            if(timeLeft === "reset") {
                contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].timeLeft = null;
            } else {
                contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].timeLeft = timeLeft;
            }
        }
        if(remainingTries) {
            contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries = remainingTries;
        }
    } else if(chapterIndex !== undefined && gameIndex !== undefined) {
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].status = newStatus;
    } else if(chapterIndex !== undefined) {
        contextDefaultValue.gameProgress[chapterIndex].status = newStatus;
    }
    
    saveGameProgression();
}

const reduceTries = (chapterIndex, gameIndex, orderIndex) => {
    if(
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries && 
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries > 0) {
            // console.log('tries - 1');
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries = contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries - 1;
    } else if (contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries === 0) {
        // console.log('tries = 0');
    } else {
        contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries = 2;
        // console.log('tries set to 2');
    }

    saveGameProgression();

    // console.log('contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries', contextDefaultValue.gameProgress[chapterIndex].games[gameIndex].orders[orderIndex].remainingTries);
}

export const contextDefaultValue = {
    updateStatus,
    reduceTries,
    resetGameProgression,
    gameProgress: [
        { // Chapter
            status: 'none', // completed, progress, none
            games: [
                { // Game
                    status: 'none', // completed, progress, none
                    orders: [
                        { // Order
                            status: 'none', // success, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none',
                    orders: [
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none',
                    orders: [
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
            ]
        },
        {
            status: 'none',
            games: [
                {
                    status: 'none',
                    orders: [
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none',
                    orders: [
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none',
                    orders: [
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
            ]
        },
        {
            status: 'none',
            games: [
                {
                    status: 'none', // completed, progress, none
                    orders: [
                        {
                            status: 'none', // completed, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none', // completed, progress, none
                    orders: [
                        {
                            status: 'none', // completed, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none', // completed, progress, none
                    orders: [
                        {
                            status: 'none', // completed, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                }
            ]
        },
        {
            status: 'none',
            games: [
                {
                    status: 'none', // completed, progress, none
                    orders: [
                        {
                            status: 'none', // completed, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
                {
                    status: 'none', // completed, progress, none
                    orders: [
                        {
                            status: 'none', // completed, failed, none
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                        {
                            status: 'none',
                            remainingTries: 1,
                        },
                    ]
                },
            ]
        }
    ]
};

const Context = createContext(contextDefaultValue);

export default Context;
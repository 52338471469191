import {useState} from 'react';
import styled from 'styled-components';

import OrientationBlocker from '../components/checkOrientation';

import { AppStyled, Top, Middle, Bottom, Container } from '../utils/common-styles';
import { colors } from '../utils/variables';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';
import Icon from '../components/Icon';

import partnerLaniche from '../assets/images/partner-laniche.png';
import partnerMiil from '../assets/images/partner-miil.png';
import partnerUCL from '../assets/images/partner-ucl.png';
import partnerClick from '../assets/images/partner-click.png';

const Ressources = () => {
  const [categoryShowed, setCategoryShowed] = useState('debutants');

  return (
    <OrientationBlocker>
      <Top>
        <Container>
          <Header />
        </Container>
      </Top>
      <Middle>
        <Container>
          <h1 className="page-title">Centre de ressources</h1>

          <TabsList>
            <div 
              className={`tab ${categoryShowed === "debutants" ? 'active' : ''}`}
              onClick={() => {
                setCategoryShowed('debutants');
              }}
            >
              Débutants
            </div>
            <div 
              className={`tab ${categoryShowed === "avances" ? 'active' : ''}`}
              onClick={() => {
                setCategoryShowed('avances');
              }}
            >
              Avancés
            </div>
          </TabsList>

          <CategoriesContainer>
            <CategoryContainer
              showed={categoryShowed === "debutants"}
            >

              <Item href="https://start-digital.be/ld/cssp/digic1D.html" target="_blank">
                <div className="content">
                  <h3>1. Prise en main</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic1D.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic2D.html" target="_blank">
                <div className="content">
                  <h3>2. Connaissances numériques</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic2D.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic3D.html" target="_blank">
                <div className="content">
                  <h3>3. Manipulations numériques</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic3D.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic4D.html" target="_blank">
                <div className="content">
                  <h3>4. Résolution de problèmes</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic4D.html</span>
                </div>
              </Item>
            </CategoryContainer>
            <CategoryContainer
              showed={categoryShowed === "avances"}
            >
              <Item href="https://start-digital.be/ld/cssp/digic1A.html" target="_blank">
                <div className="content">
                  <h3>1. Prise en main</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic1A.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic2A.html" target="_blank">
                <div className="content">
                  <h3>2. Connaissances numériques</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic2A.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic3A.html" target="_blank">
                <div className="content">
                  <h3>3. Manipulations numériques</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic3A.html</span>
                </div>
              </Item>

              <Item href="https://start-digital.be/ld/cssp/digic4A.html" target="_blank">
                <div className="content">
                  <h3>4. Résolution de problèmes</h3>
                  <Icon name="arrow-right" className="icon-arrow" />
                </div>
                <div className="footer">
                  <span>https://start-digital.be/ld/cssp/digic4A.html</span>
                </div>
              </Item>
            </CategoryContainer>
          </CategoriesContainer>
          

          <Partners>
            <h2 className="page-title">Les partenaires:</h2>
            <div className="inner">
              <img src={partnerMiil} />
              <img src={partnerUCL} />
              <img src={partnerLaniche} />
              <img src={partnerClick} />
            </div>
          </Partners>

        </Container>
      </Middle>
      <Bottom>
        <Container>
          <Navigation />
        </Container>
      </Bottom>
      
    </OrientationBlocker>
  );
};

const Partners = styled.div`
  padding-top: 100px;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
    @media only screen and (max-width: 1280px) {
      flex-direction: column;
    }
  }

  img {
    display: block;
    height: 50px;
    max-width: 300px;
    margin: 10px;
    object-fit: contain;
  }
`;

const TabsList = styled.div`
  display: flex;
  background: ${colors.line} none;
  border-radius: 10px;
  padding: 3px;
  margin-bottom: 30px;

  .tab {
    padding: 10px 10px;
    flex: 1;
    text-align: center;
    border-radius: 10px;
    color: ${colors.darkblue};
    font-weight: 500;

    &.active {
      background: ${colors.darkblue};
      color: white;
    }
  }
`;

const CategoriesContainer = styled.div`
`;

const CategoryContainer = styled.div`
  display: ${props => props.showed ? 'block' : 'none'};
`;

const Item = styled.a`
  display: block;
  background: ${colors.yellow};
  border-radius: 15px;
  text-decoration: none;
  margin-bottom: 20px;

  h3 {
    font-size: 17px;
    font-weight: 500;
    color: ${colors.darkblue};
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.darkblue90};
    margin: 10px 0 0 0;
  }

  .content {
    position: relative;
    background: white none;
    border-radius: 15px;
    border: 1px solid ${colors.line};
    padding: 15px 40px 15px 20px;

    .icon-arrow {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
    }
  }

  .footer {
    padding: 7px 20px 10px;
  }
  .footer a,
  .footer span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    color: ${colors.darkblue};
    text-decoration: none;
  }
`;

export default Ressources;

import React from 'react';

const clickableArea = {
    "order1Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="106" width="338" height="43" fill="#D9D9D9"/>
        </svg>    
    ),
    "order1Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="244" y="83" width="116" height="48" fill="#D9D9D9"/>
        </svg>
    ),
    "order1Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16" y="182" width="300" height="52" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="164" width="340" height="51" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12" y="107" width="134" height="192" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="321" width="54" height="58" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen4": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="180" y="275" width="84" height="108" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="15" y="16" width="90" height="101" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16" y="158" width="343" height="44" fill="#D9D9D9" data-none="true"/>
            <rect x="16" y="336" width="343" height="44" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16" y="482" width="343" height="47" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen4": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="18" y="79" width="341" height="47" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="14" y="19" width="93" height="104" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="2" width="369" height="68" fill="#D9D9D9"/>
            <rect x="281" y="568" width="88" height="86" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="40" y="60" width="305" height="60" fill="#D9D9D9" data-none="true"/>
            <rect y="159" width="375" height="64" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="23" width="80" height="103" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="61" width="158" height="193" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="220" width="375" height="59" fill="#D9D9D9"/>
        </svg>
    ),
};

const Area = (props) => {
    const child = clickableArea[props.name];
    const newChild = React.cloneElement(child, {
        className: `clickable-area`,
        preserveAspectRatio: 'xMinYMin meet',
        onClick: props.clickOutOfArea,
        children: React.Children.map(child.props.children, (subchild => {
            return React.cloneElement(subchild, {
                className: 'area',
                onClick: props.handleClick
            })
        }))
    });
    
    return newChild;
}

export default Area;
import React from 'react';

const clickableArea = {
    "order1Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="47" y="355" width="275" height="48" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="270" width="270" height="43" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="51" y="298" width="271" height="48" fill="#D9D9D9"/>
        </svg>
    ),
    "order2Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="52" y="270" width="271" height="53" fill="#D9D9D9"/>
        </svg>
    ),
    "order3Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="54" y="387" width="269" height="41" fill="#D9D9D9" data-target="8"/>
            <rect x="54" y="430" width="269" height="41" fill="#D9D9D9" data-target="12"/>
            <rect x="54" y="473" width="269" height="41" fill="#D9D9D9" data-target="4"/>
        </svg>        
    ),
    "order3Screen1b": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="54" y="387" width="269" height="41" fill="#D9D9D9" data-target="9"/>
            <rect x="54" y="430" width="269" height="41" fill="#D9D9D9" data-target="12"/>
            <rect x="54" y="473" width="269" height="41" fill="#D9D9D9" data-target="7"/>
        </svg>        
    ),
    "order3Screen1c": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="54" y="467" width="269" height="41" fill="#D9D9D9" data-target="11"/>
        </svg>        
    ),
    "order3Screen1d": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="54" y="467" width="269" height="41" fill="#D9D9D9" data-target="11"/>
        </svg>        
    ),
    "order3Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="276" width="270" height="47" fill="#D9D9D9" data-target="0"/>
        </svg>
    ),
    "order3Screen2b": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="2"/>
        </svg>        
    ),
    "order3Screen2c": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="1"/>
        </svg>        
    ),
    "order3Screen2d": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="1"/>
        </svg>        
    ),
    "order3Screen2e": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="0"/>
        </svg>        
    ),
    "order3Screen2e2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="1"/>
        </svg>        
    ),
    "order3Screen2f": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="53" y="268" width="270" height="55" fill="#D9D9D9" data-target="3"/>
        </svg>        
    ),
    "order3Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="50" y="266" width="272" height="48" fill="#D9D9D9" data-target="8"/>
        </svg>  
    ),
    "order3Screen4": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="50" y="383" width="272" height="42" fill="#D9D9D9" data-target="10"/>
            <rect x="50" y="425" width="272" height="42" fill="#D9D9D9" data-target="5"/>
            <rect x="50" y="468" width="272" height="45" fill="#D9D9D9" data-target="6"/>
        </svg>  
    ),
    "order4Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="44" width="375" height="45" fill="#D9D9D9" data-none="none"></rect>
            <rect y="165" width="375" height="65" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen2": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="136" width="246" height="57" fill="#D9D9D9"/>
        </svg>
    ),
    "order4Screen3": (
        <svg width="375" height="656" viewBox="0 0 375 656" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="10" y="373" width="105" height="55" fill="#D9D9D9"/>
            <rect x="0" y="428" width="375" height="227" fill="#D9D9D9" data-none="none"/>
        </svg>
    ),
    "order5Screen1": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="209" width="340" height="57" fill="#D9D9D9"/>
            <rect y="62" width="375" height="45" fill="#D9D9D9" data-none="none"></rect>
        </svg>
    ),
    "order5Screen2": (
        <svg width="375" height="653" viewBox="0 0 375 653" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="358" width="375" height="69" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen3": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="181" width="246" height="47" fill="#D9D9D9" data-target="4"/>
            <rect x="20" y="406" width="246" height="46" fill="#D9D9D9" data-target="3"/>
        </svg>
    ),
    "order5Screen3b": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="163" width="340" height="43" fill="#D9D9D9" data-target="9"/>
        </svg>
    ),
    "order5Screen4": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="52" y="336" width="273" height="54" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen5": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="354" width="375" height="73" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen6": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="406" width="246" height="46" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen7": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="163" width="340" height="43" fill="#D9D9D9"/>
        </svg>
    ),
    "order5Screen8": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="52" y="338" width="270" height="60" fill="#D9D9D9" data-target="10"/>
        </svg>
    ),
    "order5Screen8b": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="52" y="342" width="270" height="60" fill="#D9D9D9" data-target="2"/>
        </svg>
    ),
    "order5Screen9": (
        <svg width="375" height="654" viewBox="0 0 375 654" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="20" y="160" width="340" height="47" fill="#D9D9D9"/>
        </svg>
    )
};

const Area = (props) => {
    const child = clickableArea[props.name];
    const newChild = React.cloneElement(child, {
        className: `clickable-area`,
        preserveAspectRatio: 'xMinYMin meet',
        onClick: props.clickOutOfArea,
        children: React.Children.map(child.props.children, (subchild => {
            return React.cloneElement(subchild, {
                className: 'area',
                onClick: props.handleClick
            })
        }))
    });
    
    return newChild;
}

export default Area;
import React, { useState, useEffect, useRef } from 'react';

const listLines = {
    "1-1": (
        <svg name="1-1" key="1-1" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10120)">
<path d="M-1 46.5219L0.636322 46.0486M0.636322 46.0486L13.0751 42.4509C24.4361 39.1649 36.558 39.1835 47.908 42.5044L60 46.0423M0.636322 46.0486C0.424737 46.0444 0.212623 46.0423 0 46.0423" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10120">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>
    ),
    "1-2": (
        <svg name="1-2" key="1-2" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10126)">
<path d="M62 46.5219C62 46.5219 61.0027 46.2334 60.3637 46.0486C60.5753 46.0444 60.7874 46.0423 61 46.0423M60.3637 184.143C47.9249 184.143 30.5 177 30.5 161V75.2928C30.5 54 20.5 46 0.999969 46" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10126">
<rect width="61" height="490" fill="white" transform="matrix(-1 0 0 1 61 0)"/>
</clipPath>
</defs>
</svg>
    ),
    "1-3": (
        <svg name="1-3" key="1-3" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10120)">
<path d="M-1 46.5219L0.636322 46.0486M0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M0.636322 46.0486C17.1872 46.3735 30.5 59.3422 30.5 75.2929V154.893V268.058C30.5 284.213 44.1553 297.309 61 297.309" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10120">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>
    ),
    "1-4": (
        <svg name="1-4" key="1-4" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10120)">
<path d="M-1 46.5219L0.636322 46.0486M0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M0.636322 46.0486C17.1872 46.3735 30.5 59.3422 30.5 75.2929V154.893V268.058V415.749C30.5 431.904 44.1553 445 61 445" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10120">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>


    ),
    "2-1": (
        <svg name="2-1" key="2-1" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10158)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0423 0 46.0423M0.636322 184.143C13.0751 184.143 30.5 177 30.5 161V75.2928C30.5 54 40.5 46 60 46" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10158">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "2-2": (
        <svg name="2-2" key="2-2" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10158)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M0.636353 184.049L13.0751 180.451C24.4361 177.165 36.558 177.184 47.908 180.504L60 184.042" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10158">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "2-3": (
        <svg name="2-3" key="2-3" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10158)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M61 297.309C44.1553 297.309 30.5 284.213 30.5 268.058L30.5 213.293C30.5 197.342 17.1873 184.373 0.636353 184.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10158">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "2-4": (
        <svg name="2-4" key="2-4" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10158)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M61 445C44.1553 445 30.5 431.904 30.5 415.75L30.5 213.293C30.5 197.342 17.1873 184.373 0.636353 184.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10158">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "3-1": (
        <svg name="3-1" key="3-1" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10163)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0423 0 46.0423M0.636353 297.143C13.0751 297.143 30.5 290 30.5 274L30.5 161V69.904C30.5 53.9533 47.908 46 60 46" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10163">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "3-2": (
        <svg name="3-2" key="3-2" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10126)">
<path d="M62 46.5219C62 46.5219 61.0027 46.2334 60.3637 46.0486C60.5753 46.0444 60.7874 46.0424 61 46.0424M0 297.309C16.8447 297.309 30.5 284.213 30.5 268.058L30.5 213.293C30.5 197.342 43.8127 184.373 60.3636 184.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10126">
<rect width="61" height="490" fill="white" transform="matrix(-1 0 0 1 61 0)"/>
</clipPath>
</defs>
</svg>

    ),
    "3-3": (
        <svg name="3-3" key="3-3" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10163)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M0.636353 297.049L13.0751 293.451C24.4361 290.165 36.558 290.184 47.908 293.504L60 297.042" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10163">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "3-4": (
        <svg name="3-4" key="3-4" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10163)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M61 445C44.1553 445 30.5 431.904 30.5 415.75L30.5 326.293C30.5 310.342 17.1873 297.373 0.636353 297.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10163">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    "4-1": (
        <svg name="4-1" key="4-1" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10153)">
<path d="M62 46.5219L60.3637 46.0486M60.3637 46.0486C60.5753 46.0444 60.7874 46.0424 61 46.0424M60.3637 46.0486C43.8128 46.3735 30.5 59.3422 30.5 75.2929V154.893V268.058V415.749C30.5 431.904 16.8447 445 0 445" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10153">
<rect width="61" height="490" fill="white" transform="matrix(-1 0 0 1 61 0)"/>
</clipPath>
</defs>
</svg>

    ),
    "4-2": (
        <svg name="4-2" key="4-2" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10126)">
<path d="M62 46.5219C62 46.5219 61.0027 46.2334 60.3637 46.0486C60.5753 46.0444 60.7874 46.0424 61 46.0424M0 445C16.8447 445 30.5 431.904 30.5 415.75L30.5 213.293C30.5 197.342 43.8127 184.373 60.3636 184.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10126">
<rect width="61" height="490" fill="white" transform="matrix(-1 0 0 1 61 0)"/>
</clipPath>
</defs>
</svg>

    ),
    "4-3": (
        <svg name="4-3" key="4-3" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10142)">
<path d="M62 46.5219C62 46.5219 61.0027 46.2334 60.3637 46.0486C60.5753 46.0444 60.7874 46.0424 61 46.0424M0 445C16.8447 445 30.5 431.904 30.5 415.75L30.5 326.293C30.5 310.342 43.8127 297.373 60.3636 297.049" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10142">
<rect width="61" height="490" fill="white" transform="matrix(-1 0 0 1 61 0)"/>
</clipPath>
</defs>
</svg>

    ),
    "4-4": (
        <svg name="4-4" key="4-4" width="61" height="490" viewBox="0 0 61 490" fill="none" preserveAspectRatio="none">
<g clipPath="url(#clip0_807_10168)">
<path d="M-1 46.5219C-1 46.5219 -0.00270134 46.2334 0.636322 46.0486C0.424737 46.0444 0.212623 46.0424 0 46.0424M0.636353 445.049L13.0751 441.451C24.4361 438.165 36.558 438.184 47.908 441.504L60 445.042" stroke="#4BA7F7" strokeWidth="1.5" strokeDasharray="1 3"/>
</g>
<defs>
<clipPath id="clip0_807_10168">
<rect width="61" height="490" fill="white"/>
</clipPath>
</defs>
</svg>

    )
};

export const svgLines = ({allowedLeftIndex, allowedRightIndex, leftIndex, rightIndex}) => {
    // console.log('svgLines', leftIndex, rightIndex);
    const output = [];

    if(leftIndex !== false && rightIndex === false) {
        // console.log('allowedRightIndex', allowedRightIndex);
        allowedRightIndex.map((item) => {
            // output.push(listLines[`${leftIndex + 1}-${item + 1}`]);
            output.push(React.cloneElement((listLines[`${leftIndex + 1}-${item + 1}`]), {
                className: ``
              }));
        });
        // for(let i=1; i <= 4; i++) {
        //     output.push(listLines[`${leftIndex + 1}-${i}`]);
        // }
    }

    if(rightIndex !== false && leftIndex === false) {
        // console.log('allowedRightIndex', allowedLeftIndex);
        allowedLeftIndex.map((item) => {
            // output.push(listLines[`${item + 1}-${rightIndex + 1}`]);

            output.push(React.cloneElement((listLines[`${item + 1}-${rightIndex + 1}`]), {
                className: ``
              }));
        });
        // for(let i=1; i <= 4; i++) {
        //     output.push(listLines[`${i}-${rightIndex + 1}`]);
        // }
    }

    if(leftIndex !== false && rightIndex !== false) {
            // listLines[`${leftIndex + 1}-${rightIndex + 1}`]
            output.push(React.cloneElement((listLines[`${leftIndex + 1}-${rightIndex + 1}`]), {
                className: `plainstroke`
              }));
    }
    
    // console.log('output', output);

    return (<>{output}</>);
};
import {useContext} from 'react';
import styled from 'styled-components';

import { AppStyled, Top, Middle, Bottom, Container } from '../utils/common-styles';
import { colors } from '../utils/variables';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';

import Context from '../utils/context';
import gameData from '../utils/gameData';
import OrientationBlocker from '../components/checkOrientation';

import accuracy1 from '../assets/images/challenges/accuracy-1.svg';
import accuracy2 from '../assets/images/challenges/accuracy-2.svg';
import accuracy3 from '../assets/images/challenges/accuracy-3.svg';
import accuracy4 from '../assets/images/challenges/accuracy-4.svg';
import completion1 from '../assets/images/challenges/completion-1.svg';
import completion2 from '../assets/images/challenges/completion-2.svg';
import completion3 from '../assets/images/challenges/completion-3.svg';
import completion4 from '../assets/images/challenges/completion-4.svg';
import speed1 from '../assets/images/challenges/speed-1.svg';
import speed2 from '../assets/images/challenges/speed-2.svg';
import speed3 from '../assets/images/challenges/speed-3.svg';
import speed4 from '../assets/images/challenges/speed-4.svg';

const Challenges = () => {

  const { gameProgress } = useContext(Context);

  const checkCompletition = (chapterIndex) => {
    let output = false;

    output = gameProgress[chapterIndex].games.filter(game => {
      // console.log(chapterIndex, '===> ', game.orders.filter(order => order.status !== "success"));
      return game.orders.filter(order => order.status !== "success").length < 3 ? false : true;
    }).length === 0 ? true : false;

    // console.log('checkCompletition output',output);

    return output;
  };

  const checkSpeed = (chapterIndex) => {
    let output = false;

    output = gameProgress[chapterIndex].games.filter((game, gameIndex) => {
      
      return game.orders.filter((order, orderIndex) => {
        return !(order.status === "success" && order.timeLeft > (gameData[chapterIndex].games[gameIndex].orders[orderIndex].allowedTime / 4 * 2))
      }).length === 0 ? false : true;
    }).length === 0 ? true : false;

    return output;
  };

  const checkAccuracy = (chapterIndex) => {
    let output = false;

    output = gameProgress[chapterIndex].games.filter(game => {
      return game.orders.filter(order => order.status !== "success").length === 0 ? false : true;
    }).length === 0 ? true : false;

    return output;
  };

  return (
    <OrientationBlocker>
      <Top>
        <Container>
          <Header />
        </Container>
      </Top>
      <Middle>
        <Container>
          <h1 className="page-title">Défis & Achèvements</h1>
          <h2 className="section-title">Défis de complétion</h2>
          <ChallengeList>
            <div className="challenge-item">
              <ChallengeItem completed={checkCompletition(0)}>
                <img src={completion1} />
                <span className="challenge-title">Débutant</span>
                <span className="challenge-text">Complète avec succès le chapitre 1</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkCompletition(1)}>
                <img src={completion2} />
                <span className="challenge-title">Initié</span>
                <span className="challenge-text">Complète avec succès le chapitre 2</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkCompletition(2)}>
                <img src={completion3} />
                <span className="challenge-title">Confirmé</span>
                <span className="challenge-text">Complète avec succès le chapitre 3</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkCompletition(3)}>
                <img src={completion4} />
                <span className="challenge-title">Expert</span>
                <span className="challenge-text">Complète avec succès le chapitre 4</span>
              </ChallengeItem>
            </div>
          </ChallengeList>

          <h2 className="section-title">Défis de rapidité</h2>
          <ChallengeList>
            <div className="challenge-item">
              <ChallengeItem completed={checkSpeed(0)}>
                <img src={speed1} />
                <span className="challenge-title">Véloce I</span>
                <span className="challenge-text">Complète le chapitre 1 avec -50% du temps</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkSpeed(1)}>
                <img src={speed2} />
                <span className="challenge-title">Véloce II</span>
                <span className="challenge-text">Complète le chapitre 2 avec -50% du temps</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkSpeed(2)}>
                <img src={speed3} />
                <span className="challenge-title">Véloce III</span>
                <span className="challenge-text">Complète le chapitre 3 avec -50% du temps</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkSpeed(3)}>
                <img src={speed4} />
                <span className="challenge-title">Véloce IV</span>
                <span className="challenge-text">Complète le chapitre 4 avec -50% du temps</span>
              </ChallengeItem>
            </div>
          </ChallengeList>

          <h2 className="section-title">Défis de précision</h2>
          <ChallengeList>
            <div className="challenge-item">
              <ChallengeItem completed={checkAccuracy(0)}>
                <img src={accuracy1} />
                <span className="challenge-title">Droit au but I</span>
                <span className="challenge-text">Complète le chapitre 1 sans erreur</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkAccuracy(1)}>
                <img src={accuracy2} />
                <span className="challenge-title">Droit au but II</span>
                <span className="challenge-text">Complète le chapitre 2 sans erreur</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkAccuracy(2)}>
                <img src={accuracy3} />
                <span className="challenge-title">Droit au but III</span>
                <span className="challenge-text">Complète le chapitre 3 sans erreur</span>
              </ChallengeItem>
            </div>
            <div className="challenge-item">
            <ChallengeItem completed={checkAccuracy(3)}>
                <img src={accuracy4} />
                <span className="challenge-title">Droit au but IV</span>
                <span className="challenge-text">Complète le chapitre 4 sans erreur</span>
              </ChallengeItem>
            </div>
          </ChallengeList>

        </Container>
      </Middle>
      <Bottom>
        <Container>
          <Navigation />
        </Container>
      </Bottom>
      
    </OrientationBlocker>
  );
};

const ChallengeList = styled.div`
  overflow: auto;
  white-space: nowrap;
  margin: 0 -20px;
  padding: 0 20px;

  .challenge-item {
    display: inline-block;
    width: 155px;
    height: 200px;
    overflow: hidden;
    
    & + .challenge-item {
      margin-left: 20px;
    }
  }
`;

const ChallengeItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 12px);
  height: calc(100% - 17px);
  white-space: normal;
  text-align: center;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 5px 0 ${colors.darkblue};
  margin-bottom: 5px;
  border: 1px solid ${colors.darkblue};
  background-color: ${props => props.completed ? colors.yellow : "transparent"};
  opacity: ${props => props.completed ? "1" : ".3"};

  img {
    display: block;
    margin: 0 auto 15px;
  }

  .challenge-title {
    font-size: 17px;
    font-weight: 500;
    color: ${colors.darkblue};
    margin-bottom: 5px;
  }

  .challenge-text {
    font-size: 14px;
    font-weight: 400;
    color: ${colors.darkblue};
    line-height: 1.3;
  }
`;

export default Challenges;

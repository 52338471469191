import { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import {sendEvent} from '../utils/ga';
import OrientationBlocker from '../components/checkOrientation';

import Context from '../utils/context';
import { colors } from '../utils/variables';
import gameData from '../utils/gameData';

import { AppStyled, EndGameTop, EndGameMiddle, Container } from '../utils/common-styles';
import EndGameHeader from '../components/Layout/EndGameHeader';
import Button from '../components/button';
import Icon from '../components/Icon';

import endGameSuccess from '../assets/images/endgame-success.png';
import endGameFailed from '../assets/images/endgame-failed.png';
import endChapter from '../assets/images/endchapter.png';


const EndGame = () => {
    const navigate = useNavigate();
    let {state} = useLocation();
    // console.log('state', state, !state);

    const { gameProgress } = useContext(Context);
    const [totalScore, setTotalScore] = useState(0);
    const [totalMaxScore, setTotalMaxScore] = useState(0);
    const [scores, setScores] = useState({});
    const [gaFull, setGaFull] = useState({});
    const [scoresCategories, setScoresCategories] = useState({});
    const [totalGameScoreState, setTotalGameScoreState] = useState(0);
    const [skillsToShowState, setSkillsToShowState] = useState([]);
    const [newScoreGame, setNewScoreGame] = useState({})
    const [gameSuccess, setGameSuccess] = useState(false);

    useEffect(() => {
        if(scores && skillsToShowState.length > 0) {
            const gaScores = {};
            // skillsToShowState.map(skill => gaScores[`score_${skill}`] = scores[skill].points);
            skillsToShowState.map(skill => gaScores[`score_${skill}`] = newScoreGame[skill]);
            // console.log('skillsToShowState', skillsToShowState);
            // console.log('newScoreGame', newScoreGame);

            const gaFull = {
                event:"finish",
                chapter_id:state.currentChapter,
                game_id:state.currentGame,
                success: gameSuccess,
                ...gaScores
            };

            // console.log('gameSuccess', gameSuccess);

            // console.log('gaFull', gaFull);

            setGaFull(gaFull);

            sendEvent(gaFull);
        }
    }, [scores, skillsToShowState]);

    useEffect(() => {
        if(!state) {
        // if(false) { // DEBUG ONLY
            navigate('/', { replace: true });
        }

        computeScore(computeMaxScore());

        // console.log('current', state.currentChapter, state.currentGame, gameData[state.currentChapter].games[state.currentGame].orders);

        const newScores = {};
        const skillsToShow = [];
        let totalGameScore = 0;
        let countSuccesOrder = 0;

        gameData[state.currentChapter].games[state.currentGame].orders.map((order, orderIndex) => {
            // console.log('order.status', order.status, gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex]);
            if(gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex].status === "success"){

                const timeTotal = order.allowedTime;
                const timeLeft = gameProgress[state.currentChapter].games[state.currentGame].orders[orderIndex].timeLeft;
                const timeUsed = timeTotal - timeLeft;
                countSuccesOrder = countSuccesOrder + 1;
                const scoreDivisor = (timeUsed < timeTotal / 6) ? 1 : (timeUsed < timeTotal / 4) ? 1.5 : 3;

                // console.log('scoreDivisor', scoreDivisor);

                order.score.map(score => {
                    // console.log('score test', skillsToShow.indexOf(score.label) === -1);
                    if(skillsToShow.indexOf(score.label) === -1) {
                        // console.log('add skill',score.label );
                        skillsToShow.push(score.label)
                    }
                    newScores[score.label] = (newScores[score.label]) ? newScores[score.label] + (score.points / scoreDivisor) : (score.points / scoreDivisor);
                    totalGameScore = totalGameScore + (score.points / scoreDivisor);
                });
            }
        });
        // console.log('newScores', newScores, skillsToShow, totalGameScore);
        setTotalGameScoreState(totalGameScore);
        setSkillsToShowState(skillsToShow);
        setNewScoreGame(newScores);
        setGameSuccess(countSuccesOrder > 2);

        // console.log('totalGameScore', totalGameScore);
        // console.log('newScores', newScores);
        // console.log('skillsToShow', skillsToShow);
        // console.log('scores', scores);
        
    }, []);


    const computeMaxScore = () => {
        let totalMaxScoreTemp = 0;
        let totalMaxScoreObjectTemp = {
          "rechercher": {
            "max": 0,
            "points": 0,
            "label": "Rechercher"
          },
          "naviguer": {
            "max": 0,
            "points": 0,
            "label": "Naviguer"
          },
          "gerer": {
            "max": 0,
            "points": 0,
            "label": "Gérer"
          },
          "interagir": {
            "max": 0,
            "points": 0,
            "label": "Interagir"
          },
          "partager": {
            "max": 0,
            "points": 0,
            "label": "Partager"
          },
          "creer": {
            "max": 0,
            "points": 0,
            "label": "Créer"
          },
          "modifier": {
            "max": 0,
            "points": 0,
            "label": "Modifier"
          },
          "creatif": {
            "max": 0,
            "points": 0,
            "label": "Usage créatif"
          },
          "critique": {
            "max": 0,
            "points": 0,
            "label": "Usage critique"
          },
          "numerique": {
            "max": 0,
            "points": 0,
            "label": "Connaissances numériques"
          }
        };
    
        gameData.map(chapter => {
          chapter.games.map(game => {
            game.orders.map(order => {
              order.score.map(score => {
                totalMaxScoreObjectTemp[score.label].max = totalMaxScoreObjectTemp[score.label].max + score.points;
                totalMaxScoreTemp = totalMaxScoreTemp + score.points;
              });
            });
          });
        });
    
        setTotalMaxScore(totalMaxScoreTemp);
        // console.log('totalMaxScoreTemp', totalMaxScoreTemp, totalMaxScoreObjectTemp);
        return totalMaxScoreObjectTemp;
    }

    const computeScore = (maxScoreObj) => {
    // console.log('computeScore gameProgress', gameProgress);
    // console.log('computeScore gameData', gameData);
    let totalScoreTemp = 0;
    let totalMaxScoreTemp = 0;

    const categoriesScore = {
        "informations": {
        "max": 0,
        "points": 0
        },
        "communication": {
        "max": 0,
        "points": 0
        },
        "production": {
        "max": 0,
        "points": 0
        },
        "resolution": {
        "max": 0,
        "points": 0
        },
        "connaissance": {
        "max": 0,
        "points": 0
        }
    };
    const computedScore = maxScoreObj;
    
    gameProgress.map((chapter, chapterIndex) => {
        if(chapter.status === "progress" || chapter.status === "completed") {
        chapter.games.map((game, gameIndex) => {
            if(game.status === "progress" || game.status === "completed") {
            game.orders.map((order, orderIndex) => {
                if(order.status === "success") {
                const orderData = gameData[chapterIndex].games[gameIndex].orders[orderIndex];
                const orderProgress = order;

                const timeTotal = orderData.allowedTime;
                const timeLeft = orderProgress.timeLeft;
                const timeUsed = timeTotal - timeLeft;

                const scoreDivisor = (timeUsed < timeTotal / 6) ? 1 : (timeUsed < timeTotal / 4) ? 1.5 : 3;

                const orderScores = orderData.score;

                orderScores.map((orderScoreItem) => {
                    computedScore[orderScoreItem.label].points = computedScore[orderScoreItem.label].points + (orderScoreItem.points / scoreDivisor);
                    totalScoreTemp = totalScoreTemp + (orderScoreItem.points / scoreDivisor);
                });
                
                }
            })
            }
        });
        }

        setTotalScore(totalScoreTemp);
    });

    categoriesScore["informations"].points = computedScore["rechercher"].points + computedScore["naviguer"].points +  computedScore["gerer"].points;
    categoriesScore["informations"].max = computedScore["rechercher"].max + computedScore["naviguer"].max + computedScore["gerer"].max;

    categoriesScore["communication"].points = computedScore["interagir"].points + computedScore["partager"].points;
    categoriesScore["communication"].max = computedScore["interagir"].max + computedScore["partager"].max;

    categoriesScore["production"].points = computedScore["creer"].points + computedScore["modifier"].points;
    categoriesScore["production"].max = computedScore["creer"].max + computedScore["modifier"].max;

    categoriesScore["resolution"].points = computedScore["creatif"].points + computedScore["critique"].points;
    categoriesScore["resolution"].max = computedScore["creatif"].max + computedScore["critique"].max;

    categoriesScore["connaissance"].points = computedScore["numerique"].points;
    categoriesScore["connaissance"].max = computedScore["numerique"].max;

    setScoresCategories(categoriesScore);
    setScores(computedScore);
    };

  return (
    <OrientationBlocker>
      <EndGameTop>
        <Container>
          <EndGameHeader />
        </Container>
      </EndGameTop>
      <EndGameMiddle>
        <EndGameContainer>
            {state ? (
                <>
                    <div className="content">
                        {
                            (gameSuccess) ? (
                                <>
                                    <h1>Félicitations, tu as validé le jeu "{gameData[state.currentChapter].games[state.currentGame].gameTitle}" !</h1>
                                    <img src={endGameSuccess} className="game-visual" />
                                </>
                            ) : (
                                <>
                                    <h1>Dommage, tu y étais presque !</h1>
                                    <img src={endGameFailed} className="game-visual" />
                                </>
                            )
                        }
                        
                    </div>
                    <div className="footer">
                    
                    <ScoreCategory showDetail={true}>
                        <div className="header">
                            <div className="item">
                                <div className="item-inner">
                                    <h3 className="item-title">Tu as {(!gameSuccess && totalGameScoreState > 0) ? "quand même" : ""} obtenu: <strong>{totalGameScoreState}</strong><Icon name="star" /></h3>
                                </div>
                            </div>
                        </div>
                        <div className="detail">
                            {skillsToShowState.map((skill) => {
                                
                                
                                return (
                                    <div className="item">
                                        
                                        <div className="item-inner">
                                        <h3 className="item-title">{scores[skill].label}<span className="additionnal">+{newScoreGame[skill]}</span></h3>
                                        <span className="item-score"><span>{(scores[skill].points)}</span> <Icon name="star" /></span>
                                        <span className="item-bar">
                                            <span className="item-progress" style={
                                            {width: (scores[skill].points/scores[skill].max*100) + '%' }
                                            }></span>
                                        </span>
                                        </div>
                                    </div> 
                                )
                            })}

                        </div>
                    </ScoreCategory>
                    
                        



                        {/* {state.nextGame && typeof(state.nextGame.chapter) !== "undefined" && typeof(state.nextGame.game) !== "undefined" ? (
                            <Button 
                                fn={() => {
                                    navigate(`${gameData[state.nextGame.chapter].games[state.nextGame.game].routeName}`)
                                }}
                            >
                                Passer au jeu suivant
                            </Button>
                        ) : null} */}


                        <Button 
                            fn={() => {
                                if(state.chapterEnded) {
                                    navigate("/endchapter", {
                                        replace: true, 
                                        state
                                      });
                                }else if(state.nextGame && typeof(state.nextGame.chapter) !== "undefined" && typeof(state.nextGame.game) !== "undefined"){
                                    navigate(`${gameData[state.nextGame.chapter].games[state.nextGame.game].routeName}`)
                                }
                            }}
                        >
                            {state.chapterEnded ? 'Suivant' : 'Passer au jeu suivant'}
                        </Button>
                        {/* {state.chapterEnded ? (<>
                            <Button 
                                fn={() => {
                                    navigate(`/ressources`, { replace: true })
                                }}
                            >
                                Aller vers le centre de ressources
                            </Button>
                            <Button 
                                fn={() => {
                                    navigate(`/`, { replace: true })
                                }}
                            >
                                Retourner à l'accueil
                            </Button>
                        </>) : null} */}
                        
                    </div>
                </>
            ) : null} 
                
        </EndGameContainer>
      </EndGameMiddle>
      
    </OrientationBlocker>
  );
};

const EndGameContainer = styled.div` 
    padding: 30px 20px;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
        .game-visual {
            display: block;
            width: 70%;
            margin: 0 auto 30px;
        }
    }

    .footer {

    }

    h1 {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        color: ${colors.darkblue};
        line-height: 120%;
    }

    .btn {
        margin-top: 20px;
    }
`;

const ScoreCategory = styled.div`
  border-radius: 15px;
  border: 1px solid ${colors.line};
  box-shadow: 0 5px 0 ${colors.line};
  margin-bottom: 20px;
  overflow: hidden;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    &-icon {
      display: block;
      width: 50px;
      height: 50px;
      background: ${colors.line};
      margin-right: 10px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      flex: 1;
    }

    &-title {
      font-size: 16px;
      font-weight: 500;
      color: ${colors.darkblue};
      margin: 0;
      text-transform: capitalize;

      .additionnal {
        color: ${colors.lightblue};
        font-weight: 600;
        margin-left: 10px;
      }

      svg {
        height: auto;
        fill: ${colors.lightblue};
        margin-left: 4px;
        margin-bottom: -3px;
      }
    }

    &-bar {
      position: relative;
      display: block;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      background: ${colors.line} none;
      margin-top: 15px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
        background: white;
      }

      &::before {
        left: calc(100% / 3);
      }

      &::after {
        right: calc(100% / 3);
      }
    }

    &-progress {
      display: block;
      border-radius: 4px;
      background: ${colors.yellow} none;
      height: 8px;
      width: 0%;
      transition: all .4s .3s ease-in-out;
    }

    &-score {
      display: inline-flex;
      align-items: center;
      color: ${colors.darkblue};

      > span {
        margin-top: 3px;

        &:empty {
          &::before {
            content: '---';
            color: ${colors.darkblue20};
          }
        }
      }

      svg {
        height: auto;
        fill: ${colors.lightblue};
        margin-left: 4px;
      }
    }
  }

  .header {
    border-bottom: 1px solid ${colors.line};
    padding: 20px 20px 0;
    background: ${colors.yellow};
    
    .item-title {
      font-size: 17px;
      text-transform: none;

      strong {
        font-weight: 600;
      }
    }

    .item-inner {
        justify-content: center;
    }
  }

  .detail {
    padding: 20px 20px 0;
    overflow: hidden;
    transition: all .4s ease-in-out;
    max-height: ${props => props.showDetail ? '600px' : '0px'};
    padding-top: ${props => props.showDetail ? '20px' : '0px'};
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.darkblue};
    border-top: 1px solid ${colors.line};
    margin-top: -1px;

    svg {
      height: 12px;
      margin-left: 5px;

      transform: rotate(${props => props.showDetail ? '180deg' : '0deg'});
    }

    span {
      margin-right: 4px;
    }

    .hided {
      display: ${props => props.showDetail ? 'none' : 'inline-block'};
    }

    .showed {
      display: ${props => props.showDetail ? 'inline-block' : 'none'};
    }
  }
`;

export default EndGame;

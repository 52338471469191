import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from './button';
import { colors } from '../utils/variables';

const Toast = ({large, show, onHide, children, titleText, buttonText, className}) => {
    const [showed, setShowed] = useState(false);

    useEffect(() => {
        setShowed(show);
    });

    return (
        <ToastStyled large={large} showed={showed} className={className}>
            <div className="backdrop"/>
            <div className="box">
                {titleText ? (<h2 className="title">{titleText}</h2>) : null}
                <div className="content">
                    {children}
                    {buttonText ? (
                        <Button fn={() => {
                            setShowed(false);
                            onHide();
                        }}>
                            {buttonText}
                        </Button>
                    ) : null}
                    
                </div>
            </div>
        </ToastStyled>
    );
}

const ToastStyled = styled.div`
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #1D3C8A;
        transition: all .4s ease-in-out;

        ${props => props.showed === false ? `
             opacity: 0;
             z-index: -1;
             pointer-events: none;
        ` : `
            opacity: .9;
             z-index: 1000;
             pointer-events: all;
        `}
    }

    .title {
        font-size: ${props => props.large ? `32px` : '26px'};
        font-weight: 600;
        color: ${colors.darkblue};
        text-align: ${props => props.large ? `center` : 'left'};
        line-height: 1.5em;
    }

    .box {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - 40px);
        flex-direction: column;
        justify-content: space-between;

        height: ${props => props.large ? `90%` : 'auto'};

        background: #fff;
        border-radius: 8px 8px 0 0;
        padding: 20px;

        transition: all .4s ease-in-out;

        ${props => props.showed === false ? `
             opacity: 0;
             z-index: -1;
             pointer-events: none;
             transform: translate(0, 100%);
        ` : `
            opacity: 1;
             z-index: 1000;
             pointer-events: all;
             transform: translate(0, 0);
        `}

        .content {
            
        }

        p {
            font-size: 17px;
            color: ${colors.darkblue};
            margin: 0 0 30px;

            svg {
                color: ${colors.lightblue}
            }
        }
    }

    &.blocker {
        
        .backdrop {
            opacity: 1;
        }
        .box {
            bottom: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 60px);
            max-width: 450px;
            border-radius: 8px;
            text-align: center;

            h2 {
                text-align: center;
            }
        }
    }
`;

export default Toast;

import ReactGA from "react-ga4";

// console.log('REACT_APP_GA_ID', process.env.REACT_APP_GA_ID);

ReactGA.initialize([
    {
        // trackingId: "G-F95MG0K2B0",// stg
        // trackingId: "G-N9Y4P6Z7M3",// prod
        trackingId: process.env.REACT_APP_GA_ID,
        
        gaOptions: {
            'debug_mode':true
        }
    },
]);

// gtag('set', 'user_properties', {
//     favorite_composer: 'Mahler',
//     favorite_instrument: 'double bass',
//     season_ticketholder: 'true'
//   });

export const sendSetUP = (objectToSend) => {
    // const eventName = objectToSend.event;
    // const eventParams = objectToSend;
    // delete eventParams.event;

    // ReactGA.event(eventName, eventParams);
    // ReactGA.gtag();
    ReactGA.gtag('set', 'user_properties', objectToSend);
    // console.log('objectToSend user_properties', objectToSend);
}


export const sendEvent = (objectToSend) => {
    const eventName = objectToSend.event;
    const eventParams = objectToSend;
    delete eventParams.event;
    
    // if(!objectToSend.action) {
    //     objectToSend.action = "none";
    // }
    // if(!objectToSend.category) {
    //     objectToSend.category = "none";
    // }
    ReactGA.event(eventName, eventParams);
    // console.log('objectToSend', eventName, eventParams);
}

export default ReactGA;
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';

import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';

import {svgLines} from './svgLines';

import zoom from '../../../assets/images/game2b/zoomus-ar21.png';
import messenger from '../../../assets/images/game2b/fb-messenger.png';
import whatsapp from '../../../assets/images/game2b/whatsapp.png';
import google from '../../../assets/images/game2b/google.png';
import instagram from '../../../assets/images/game2b/logo-instagram.png';
import word from '../../../assets/images/game2b/Logo-Word-1.jpeg';
import movieMaker from '../../../assets/images/game2b/movie-maker.jpeg';
import imovie from '../../../assets/images/game2b/imovie.png';
import powerpoint from '../../../assets/images/game2b/Powerpoint-logo-1.jpeg';
import waze from '../../../assets/images/game2b/waze.png';
import alexa from '../../../assets/images/game2b/alexa.png';
import chrome from '../../../assets/images/game2b/chrome.png';
import doctissimo from '../../../assets/images/game2b/doctissimo.png';
import excel from '../../../assets/images/game2b/Excel-logo.png';


const Game2b = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {

  const orderCanvas = useRef();
  const centerArea = useRef();
  const leftArea = useRef();
  const rightArea = useRef();
  const leftListEls = useRef([]);
  const rightListEls = useRef([]);

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);
  const [showIntermediateFeedback, setShowIntermediateFeedback] = useState(false);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(4);

  const [linesToShow, setLinesToShow] = useState(<></>);

  const [orderListLeft, setOrderListLeft] = useState([]);
  const [orderListRight, setOrderListRight] = useState([]);

  const [selectedLeftItem, setSelectedLeftItem] = useState(false);
  const [selectedRightItem, setSelectedRightItem] = useState(false);

  const [selectedLeftIndex, setSelectedLeftIndex] = useState(false);
  const [selectedRightIndex, setSelectedRightIndex] = useState(false);

  const [allowedLeftIndex, setAllowedLeftIndex] = useState([0,1,2,3]);
  const [allowedRightIndex, setAllowedRightIndex] = useState([0,1,2,3]);

  const [clickCurrentInitialIndex, setClickCurrentInitialIndex] = useState(false);
  const [clickCurrentStartEl, setClickCurrentStartEl] = useState(false);
  const [clickCurrentList, setClickCurrentList] = useState(false);

  const [pairCompleted, setPairCompleted] = useState(0);

  const orders = [
    [
      {
        left: 'Site générique',
        right: '.com, .biz, .net'
      },
      {
        left: 'Site d’une organisation',
        right: '.gov, .org, .edu'
      },
      {
        left: 'Site national, international',
        right: '.be, .fr, .eu'
      },
      {
        left: 'Site de services',
        right: '.io, .blog, .jobs'
      }
    ],
    [
      {
        left: 'Fichier texte',
        right: '.docx ou .pdf ou .txt'
      },
      {
        left: 'Fichier vidéo',
        right: '.mp4 ou .mov ou .flv'
      },
      {
        left: 'Fichier audio',
        right: '.mp3 ou .ogg ou .wav'
      },
      {
        left: 'Fichier image',
        right: '.jpg ou .png ou .gif'
      }
    ],
    [
      {
        left: 'Application de géolocalisation',
        right: (<img src={waze} alt="Waze" />)
      },
      {
        left: 'Navigateur',
        right: (<img src={chrome} alt="Chrome" />)
      },
      {
        left: 'Moteur de recherche',
        right: (<img src={google} alt="Google" />)
      },
      {
        left: 'Application de visioconférence',
        right: (<img src={zoom} alt="Zoom" />)
      }
    ],
    [
      {
        left: 'Traitement de texte',
        right: (<img src={word} alt="Microsoft Word" />)
      },
      {
        left: 'Tableur',
        right: (<img src={excel} alt="Microsoft Excel" />)
      },
      {
        left: 'Présentation',
        right: (<img src={powerpoint} alt="Microsoft PowerPoint" />)
      },
      {
        left: 'Montage vidéo',
        right: (<img src={imovie} alt="iMovie" />)
      }
    ],
    [
      {
        left: 'Assistant vocal',
        right: (<img src={alexa} alt="Amazon Alexa" />)
      },
      {
        left: 'Réseau social',
        right: (<img src={instagram} alt="Instagram" />)
      },
      {
        left: 'Messagerie',
        right: (<img src={whatsapp} alt="What'app" />)
      },
      {
        left: 'Forum',
        right: (<img src={doctissimo} alt="Doctissimo" />)
      }
    ]
  ]

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          onFailOrder();
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  useEffect(() => {
    renderLists()
  }, [orderIndex]);

  useEffect(() => {
    // console.log('useEffect selectedLeftItem', selectedLeftItem);
    // console.log('useEffect selectedRightItem', selectedRightItem);

    if((selectedLeftItem === selectedRightItem) && selectedLeftItem !== false && selectedRightItem !== false) {
      setShowIntermediateFeedback("success");
      setTimeout(() => {
        // console.log('correct selection');
        completedPair(true);
      }, 1000);
    } else {
      if(selectedLeftItem !== false && selectedRightItem !== false) {
        setShowIntermediateFeedback("failed");
        setTimeout(() => {
          setShowCustomFeedback("failed");
        }, 1000);
      }
    }
  }, [selectedLeftItem, selectedRightItem]);

  useEffect(() => {
    if(clickCurrentInitialIndex !== false && clickCurrentStartEl !== false && clickCurrentList !== false) {
      const initialIndex = clickCurrentInitialIndex;
      const startEl = clickCurrentStartEl;
      const list = clickCurrentList;
      
      if(centerArea && centerArea.current && leftArea && leftArea.current){
        centerArea.current.style.height = leftArea.current.getBoundingClientRect().height + 'px';

        const originList = startEl.parentElement;
        const originListElements = originList.querySelectorAll('.item');

        [...originListElements].map((item) => {
          item.classList.remove('selected');
          item.classList.add('unselected');
        });
        startEl.classList.remove('unselected');
        startEl.classList.add('selected');

        const selectedItemIndex = [...originListElements].indexOf(startEl);

        if(list === 'left') {
          setSelectedLeftIndex(selectedItemIndex);
          setSelectedLeftItem(initialIndex);
        } else {
          setSelectedRightIndex(selectedItemIndex);
          setSelectedRightItem(initialIndex);
        } 
        
      }
    }

    setClickCurrentInitialIndex(false);
    setClickCurrentStartEl(false);
    setClickCurrentList(false);
  }, [clickCurrentInitialIndex, clickCurrentStartEl, clickCurrentList]);

  useEffect(() => {
    if(selectedLeftIndex !== false && selectedRightIndex !== false) {
      setMinValue(Math.min(selectedLeftIndex, selectedRightIndex));
      setMaxValue(Math.max(selectedLeftIndex, selectedRightIndex));
    }
    setLinesToShow(svgLines({
      allowedLeftIndex: allowedLeftIndex,
      allowedRightIndex, allowedRightIndex,
      leftIndex: selectedLeftIndex,
      rightIndex: selectedRightIndex,
    }));
  }, [selectedLeftIndex, selectedRightIndex]);

  useEffect(() => {
    // console.log('pairCompleted === orders[orderIndex].length', pairCompleted,orders[orderIndex].length, pairCompleted === orders[orderIndex].length);
    if(pairCompleted === orders[orderIndex].length){
      setShowCustomFeedback("success");
    }
  }, [pairCompleted]);



  const renderLists = () => {
    // console.log('render lists');
    const listLeft = [];
    const listRight = [];
    orders[orderIndex].map((item, itemIndex) => {
      listLeft.push(
        <div 
          key={`col-left-${itemIndex}`}
          ref={(element) => {leftListEls.current[itemIndex] = element}}
          className={`item`}
          onClick={(e) => {
            e.stopPropagation();
            const target = e.target.nodeName !== 'DIV' ? e.target.parentNode : e.target;
            if(!target.classList.contains('completed')){
              setClickCurrentInitialIndex(itemIndex);
              setClickCurrentStartEl(target);
              setClickCurrentList('left');
            }
          }}
        >
          {item.left}
        </div>
      );

      listRight.push(
        <div
          key={`col-right-${itemIndex}`}
          ref={(element) => {rightListEls.current[itemIndex] = element}}
          className={`item`}
          onClick={(e) => {
            e.stopPropagation();
            const target = e.target.nodeName !== 'DIV' ? e.target.parentNode : e.target;
            if(!target.classList.contains('completed')){
              setClickCurrentInitialIndex(itemIndex);
              setClickCurrentStartEl(target);
              setClickCurrentList('right');
            }
          }}
        >
          {item.right}
        </div>
      );
    });

    setOrderListLeft(listLeft.sort(() => (Math.random() > .5) ? 1 : -1));
    setOrderListRight(listRight.sort(() => (Math.random() > .5) ? 1 : -1));
  }

  const completedPair = (validate) => {
    if(validate){
      const newAllowedLeft = allowedLeftIndex.filter((item) => item !== selectedLeftIndex);
      const newAllowedRight = allowedRightIndex.filter((item) => item !== selectedRightIndex);
      setAllowedLeftIndex(newAllowedLeft);
      setAllowedRightIndex(newAllowedRight);

      leftListEls.current[selectedLeftItem].classList.add('completed');
      rightListEls.current[selectedRightItem].classList.add('completed');
      setPairCompleted(pairCompleted + 1);

    } else {

    }

    resetPair();
  }

  const resetPair = () => {
    setClickCurrentInitialIndex(false);
    setClickCurrentStartEl(false);
    setClickCurrentList(false);
    setSelectedLeftItem(false);
    setSelectedRightItem(false);
    setLinesToShow(<></>);
    setSelectedLeftIndex(false);
    setSelectedRightIndex(false);
    setShowIntermediateFeedback(false);

    leftListEls.current.map((item) => {
      item.classList.remove('selected');
      item.classList.remove('unselected');
    });

    rightListEls.current.map((item) => {
      item.classList.remove('selected');
      item.classList.remove('unselected');
    });
  }


  
  const orderRender = () => (
    <GameContainer className="game-container-order1">
      <div ref={leftArea} className="left">
        {orderListLeft.length > 0 ? orderListLeft : null}
      </div>
      <div className="center" ref={centerArea}>
        {linesToShow}
        <CustomIntermediateFeedback 
          show={showIntermediateFeedback} 
          className="custom-feedback" 
          minValue={minValue} 
          maxValue={maxValue}
        >
          <div className={`icon-circle`}>
            <Icon name="check" className="icon-success"/>
            <Icon name="cross" className="icon-failed"/>
          </div>
        </CustomIntermediateFeedback>
      </div>
      <div ref={rightArea} className="right">
        {orderListRight.length > 0 ? orderListRight : null}
      </div>
    </GameContainer>
  );

  return (
    <GameContainer className="game-container-order1">
      {orderRender()}
      <ScreenBlocker show={showIntermediateFeedback} />
      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .left, 
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(42% - 20px);
    padding: 0 20px;
  }

  .left {
    padding-right: 0;
  }

  .right {
    padding-left: 0;
  }

  .center {
    position: relative;
    display: flex;
    align-items: center;
    width: 16%;
    height: 100%;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      fill: none;

      &.plainstroke {
        path {
          stroke-dasharray: 0;
        }
      }
    }
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: 40px;
    border: 1px solid ${colors.line};
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.darkblue};
    background: white;
    box-shadow: 0 5px 0 ${colors.line};
    transition: all .3s ease-in-out;
    margin: 15px 0 20px;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      transition: opacity .3s ease-in-out;
    }

    /* & + .item {
      margin-top: 35px;
    } */

    &.selected {
      background: ${colors.lightblue};
      color: white;
    } 

    &.unselected {
      opacity: 0.3;
    }

    &.completed {
      background: #D1D8EA;
      color: #D1D8EA;
      box-shadow: 0 5px 0 #D1D8EA;
      border-color: #D1D8EA;
      text-indent: -9999px;

      img {
        opacity: 0;
      }
    } 

  }
`;

const ScreenBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0) none;
  z-index: ${props => props.show !== false ? "900" : "-1"};
  pointer-events: ${props => props.show !== false ? "all" : "none"};

`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

const CustomIntermediateFeedback = styled.div`
  position: absolute;
  top: ${props => (parseInt(props.minValue, 10) / 4) * 100}%;
  bottom: ${props => 100 - (((parseInt(props.maxValue, 10)+1) / 4) * 100)}%;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 10px;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
        fill: ${colors.darkblue};
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
        fill: ${colors.darkblue};
      }
    ` : ''};
  }
`;

export default Game2b;
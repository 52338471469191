import { useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import OrientationBlocker from '../components/checkOrientation';

import Context from '../utils/context';
import gameData from '../utils/gameData';
import { AppStyled, Top, Middle, Bottom, Container } from '../utils/common-styles';
import { colors } from '../utils/variables';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';
import ToastWelcome from '../components/toastWelcome';
import Toast from '../components/toast';
import Icon from '../components/Icon';
import Button from '../components/button';
import GameProgress from '../components/gameProgress';
import {sendEvent, sendSetUP} from '../utils/ga';

const App = () => {
  const middleScreen = useRef();
  const { gameProgress, resetGameProgression, updateStatus } = useContext(Context);
  const navigate = useNavigate();
  
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const [showEraseGame, setShowEraseGame] = useState(false);
  
  const [showGameToast, setShowGameToast] = useState(false);
  const [gameToastData, setGameToastData] = useState(false);

  useEffect(() => {
    const showWelcomeScreenStorage = localStorage.getItem('showWelcomeScreen');

    if(showWelcomeScreenStorage === "false") {
      setShowWelcomeScreen(false);
    }
    
  }, []);

  const startGameToast = (chapterIndex, currentGameIndex) => {
    // console.log('startGameToast', chapterIndex, currentGameIndex);
    setGameToastData(gameData[chapterIndex].games[currentGameIndex]);
    setShowGameToast(true);
  }

  const renderProgress = () => {
    
    return (gameProgress.map((chapterProgressData, chapterIndex) => {
      const chapterStatus = chapterProgressData.status;
      let currentGameData = 0;
      let currentGameIndex = 0;
      // console.log('chapterStatus', chapterStatus, chapterProgressData);
      if(chapterStatus === 'progress') {
        // console.log('test', chapterProgressData.games.map((game, gameIndex) => game.status === 'progress' ? gameIndex : false).filter((game) => game !== false)[0]);
        currentGameIndex = parseInt(chapterProgressData.games.map((game, gameIndex) => game.status === 'progress' ? gameIndex : false).filter((game) => game !== false)[0], 10);
        currentGameData = gameData[chapterIndex].games[currentGameIndex];
      }

      return (
        <HomeGameItem status={chapterStatus} key={`chapter-${chapterIndex}`}>
          {chapterStatus === 'progress' ? (
            <div className="inner">
              <div className="header">
                {gameData[chapterIndex] && gameData[chapterIndex].chapterTitle ? (<h2 className="title-chapter">{gameData[chapterIndex].chapterTitle}: </h2>) : null}
                {currentGameData && currentGameData.gameTitleShort ? (<h3 className="title-game">{currentGameData.gameTitleShort}</h3>): null}
              </div>
              <div className="progress">
                <GameProgress theme="dark" chapterIndex={chapterIndex} gameIndex={currentGameIndex} />
              </div>

              <Button fn={() => {
                startGameToast(chapterIndex, currentGameIndex);
              }}>
                Poursuivre le jeu
              </Button>
            </div>
          ) : (
            <div onClick={chapterStatus === "none" ? () => {
              startGameToast(chapterIndex, 0);
            } : () => {
              // resetAllGamesInChapter(chapterIndex); // reset chapter progression
              // startGameToast(chapterIndex, 0); // start first game
            }} className="inner">
              <div className="icon"><Icon name="check" /></div>
              <h2 className="title">{gameData[chapterIndex].chapterTitle}</h2>
            </div>
          )}
          
        </HomeGameItem>
      )
    }))
  }

  const resetAllGamesInChapter = (chapterIndex) => {
    gameProgress[chapterIndex].games.map((game, gameIndex) => {
      gameProgress[chapterIndex].games[gameIndex].orders.map((order, orderIndex) => {
        updateStatus('none', chapterIndex, gameIndex, orderIndex, "reset", 1);
      });
      updateStatus('none', chapterIndex, gameIndex);
    });
    updateStatus('none', chapterIndex)
  };

  return (
    <OrientationBlocker>
      <Top>
        <Container>
          <Header />
        </Container>
      </Top>
      <Middle ref={middleScreen}>
        <Container>

          <h1 className="page-title">Digi challenge</h1>

          {renderProgress()}

          <ToastWelcome 
            large={true} 
            show={showWelcomeScreen}
            onHide={() => {
              setShowWelcomeScreen(false);
              localStorage.setItem('showWelcomeScreen', 'false');
              // sendSetUP({
              //   // event:"profile-update",
              //   age: 'n/a',
              //   gender: 'n/a',
              //   occupation: 'n/a',
              //   formation: 'n/a',
              //   transition: 'n/a',
              // });
            }}
          />

          {showGameToast ? (
            <Toast 
              show={true}
              titleText="Prêt à jouer ?"
              buttonText="J’ai compris"
              onHide={() => {
                setShowGameToast(false);
                
                navigate(gameToastData.routeName);
              }}
            >
              <p>{gameToastData.gameText}</p>

              <p>
                <em>Un temps limite t'es octroyé pour répondre à chaque consigne. Il te sera possible de recommencer une consigne non réussie.</em>
              </p>
              <p>
                <em>Si besoin, utilise le <Icon name="help-circle" /> en haut à droite pour relire la consigne</em>
              </p>
            </Toast>
          ) : null}
          <div style={{marginTop: '100px'}}>
            <Button btnStyle="text" fn={() => {
              setShowEraseGame(true);
            }}>
              <Icon name="trash" />
              Recommencer dès le début
            </Button>
          </div>

          <HomeFooter>
            <a href="https://start-digital.be/ld/cssp/digic_cgu.html" target="_blank">Mentions légales</a> - <a href="mailto:pascal.balancier@adn.be">Signaler un problème</a>
          </HomeFooter>

          {showEraseGame ? (
            <Toast 
              show={true}
              titleText="Recommencer dès le début ?"
              buttonText="Non, revenir à l'application"
              onHide={() => {
                setShowEraseGame(false);
              }}
            >
              <p>Bravo ! Cette action supprimera les données de ta tentative précédente et te permettra de re-tenter ta chance.</p>
              <div style={{marginBottom: '15px'}}>
                <Button fn={() => {
                  resetGameProgression();
                  setShowEraseGame(false);
                  middleScreen.current.scrollTo(0, 0);
                  window.location.reload();
                  navigate('/', { replace: true });
                }}>
                  Oui, tout effacer
                </Button>
              </div>
            </Toast>
          ) : null}

        </Container>
      </Middle>
      <Bottom>
        <Container>
          <Navigation />
        </Container>
      </Bottom>
      
    </OrientationBlocker>
  );
};



const HomeGameItem = styled.div`
  margin-bottom: 20px;
  padding: 0;

  > .inner {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid ${colors.line};
    border-radius: 15px;
    text-decoration: none;

    > .icon {
      opacity: 0;
      margin: 0 15px 0 0;

      svg {
        
      }
    }
  }

  .title {
    font-size: 17px;
    font-weight: 500;
    color: ${colors.darkblue};
    
  }

  ${props => props.status === 'completed' ? `
    > .inner {
      background: ${colors.darkblue};
      border-color:  ${colors.darkblue};
      svg {
        width: 12px;
        height: 10px;
        color: ${colors.darkblue};
      }

      > .icon {
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: ${colors.lightblue};
        color: white;

        svg {
          width: 12px;
          height: 10px;
          color: white;
        }
      
      }
      
    }

    

    .title {
      color: white;
    }
  ` : null}

${props => props.status === 'progress' ? `
    .inner {
      background: ${colors.yellow};
      border-color:  ${colors.yellow};
      flex-direction: column;
      align-items: unset;
    }

    .header {
      display: block;
      // justify-content: space-between;
      // width: 100%;

      .title-chapter {
        display: inline;
        font-size: 17px;
        font-weight: 500;
        color: ${colors.darkblue};
        margin: 0;
      }

      .title-game {
        display: inline;
        font-size: 17px;
        font-weight: 400;
        color: ${colors.darkblue};
        
        // text-align: right;
        // width: 100%;
        // white-space: nowrap;
        // overflow: hidden;
        // flex: 1;
        // text-overflow: ellipsis;
        // margin: 0 0 0 20px;
        line-height: 1.3em;
      }
    }
  ` : null}
  
  .progress {
    margin: 10px auto;
    > div {
      margin: 15px auto;
    }
  }

`;

const HomeFooter = styled.div`
  margin-top: 35px;
  text-align: center;

  a {
    text-decoration: underline;
    color: ${colors.darkblue};
  }
`;

export default App;

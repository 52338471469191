import Icon from '../components/Icon';
const gameData = [
    {
        chapterTitle: "1. Prise en main",
        games: [
            {
                gameTitleShort: "a. Cherche l’icône",
                gameTitle: "a. Trouve l’icône",
                gameText: "Trouve la bonne icône",
                routeName: "/game1a",
                orders: [
                    {
                        title: (<>Déplace l'écran pour trouver et toucher l’icône <Icon name="download" /> qui permet de télécharger un élément. </>),
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: (<>Déplace l'écran pour trouver et toucher l’icône <Icon name="home" /> qui permet de retourner à la page d'accueil.</>),
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: (<>Déplace l'écran pour trouver et toucher l’icône <Icon name="search" /> qui permet de faire une recherche.</>),
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: (<>Déplace l'écran pour trouver et toucher l’icône <Icon name="settings" /> qui permet de modifier les paramètres de l'application.</>),
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: (<>Déplace l'écran pour trouver et toucher l’icône <Icon name="profile" /> qui permet de consulter la page de profil.</>),
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "b. Déverrouille l’écran",
                gameTitle: "b. Déverrouille l’écran",
                gameText: " Fait l’action qui permet de débloquer l’écran",
                routeName: "/game1b",
                orders: [
                    {
                        title: "Ferme la fenêtre",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                            }
                        },
                        score: [
                            {
                                "label": "interagir",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Débloque l'écran en utilisant ton doigt",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                            }
                        },
                        score: [
                            {
                                "label": "interagir",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Débloque l'écran grâce à ton empreinte",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                            }
                        },
                        score: [
                            {
                                "label": "interagir",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Débloque par commande vocale en disant \"Déverrouille mon téléphone\"",
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                            }
                        },
                        score: [
                            {
                                "label": "interagir",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Débloque par reconnaissance faciale",
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                            }
                        },
                        score: [
                            {
                                "label": "interagir",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "c. Manipulations à 2 doigts",
                gameTitle: "c. Manipulations à 2 doigts",
                gameText: "Manipulations à 2 doigts",
                routeName: "/game1c",
                orders: [
                    {
                        title: "Zoom sur la carte",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Zoom sur la piscine et clique dessus",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Agrandi l'image pour qu'elle remplisse le carré",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Rétréci l'image pour qu'elle remplisse le carré",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Pivote l'image pour qu'elle se trouve dans le carré",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Jeu suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        chapterTitle: "2. Connaissances numériques",
        games: [
            {
                gameTitleShort: "a. Trouve l'intrus",
                gameTitle: "a. Trouve l'intrus",
                gameText: "Trouve l'intrus parmis les différentes images",
                routeName: "/game2a",
                orders: [
                    {
                        title: "Lequel parmi ces objets ne s'appelle pas \"ordinateur\"?",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lequel parmi ces objets n’est pas un périphérique numérique ?",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lequel parmi ces logos ne représente pas une application ?",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lequel parmi ces logos ne représente pas une application permettant de communiquer avec d'autres personnes ?",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lequel parmi ces logos ne représente pas un système d'exploitation (operating system en anglais) ?",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "b. Appariement",
                gameTitle: "b. Appariement",
                gameText: "Relie les bonnes propositions ensemble",
                routeName: "/game2b",
                orders: [
                    {
                        title: "Relie le type de site web avec l'extension (à la fin de l'adresse web) la plus adaptée",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Relie le type de fichier avec le format adapté",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Relie le type d'application avec le logo adapté",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Relie le type de logiciel avec le logo adapté",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Relie le type d'outil de communication avec le logo adapté",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "c. Complète, compose ou choisis",
                gameTitle: "c. Complète, compose ou choisis",
                gameText: "Complète, compose ou choisis",
                routeName: "/game2c",
                orders: [
                    {
                        title: "Recompose l'adresse web de ce site en utilisant le \"glisser-déposer\"",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "critique",
                                "points": 3
                            },
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Recompose l'adresse e-mail de cette personne en utilisant le \"glisser-déposer\"",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "critique",
                                "points": 3
                            },
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Choisi le mot de passe le plus fort en utilisant le \"glisser-déposer\"",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "critique",
                                "points": 3
                            },
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lise doit envoyer les consignes à Rose en son absence, en mettant Carl en copie cachée. Complète les différents champs en utilisant le \"glisser-déposer\"",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "critique",
                                "points": 3
                            },
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Complète ce formulaire de demande avec les informations minimales requises en utilisant le \"glisser-déposer\"",
                        allowedTime: 60000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "critique",
                                "points": 3
                            },
                            {
                                "label": "numerique",
                                "points": 3
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        chapterTitle: "3. Manipulations numériques",
        games: [
            {
                gameTitleShort: "a. Lecteur vidéo",
                gameTitle: "a. Simulation d'un player vidéo",
                gameText: "Utiliser un lecteur vidéo",
                routeName: "/game3a",
                orders: [
                    {
                        title: "Lance la vidéo",
                        allowedTime: 10000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lance la vidéo pendant 5 secondes et mets-là en pause",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lance la vidéo et va ensuite deux pistes plus loin",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lance la vidéo et avance celle-ci jusque 45 secondes",
                        allowedTime: 20000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Lance la vidéo et mets-là en plein écran",
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "b. Ecran de smartphone",
                gameTitle: "b. Interface simulée d'un smartphone",
                gameText: "Interface simulée d’un écran de smartphone avec plusieurs appli classiques",
                routeName: "/game3b",
                orders: [
                    {
                        title: "Active le wifi \"Mon réseau Wi-Fi\"",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Trouve la photo \"Albert Einstein\" sur ton téléphone et partage la via email",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Ajoute un numéro dans ta liste de numéros bloqués",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Ouvre la carte et recherche l'itinéraire entre ta position et ton domicile",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Ouvre Spotify, clique sur la playlist \"mix Stromae\" et clique sur le titre \"Alors on danse\". ",
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "c. Moteur de recherche",
                gameTitle: "c. Simulation d'un moteur de recherche",
                gameText: "Interface simulée d'un moteur de recherche avec résultats de recherche en dessous",
                routeName: "/game3c",
                orders: [
                    {
                        title: "Sélectionne le résultat géolocalisé", // old 1 - new 1
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Clique sur le premier résultat qui n'est pas une annonce",  // old 5 - new 2
                        allowedTime: 15000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Affiche les images en lien avec la recherche", // old 2 - new 3
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Affine la recherche pour ne présenter que les résultats de moins d'un mois.",  // old 4 - new 4
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Ouvre le site digital wallonia, et trouve l'agenda", // old 3 - new 5
                        allowedTime: 45000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            }
                        ]
                    },
                    
                ]
            }
        ]
    },
    {
        chapterTitle: "4. Résolution de problèmes",
        games: [
            {
                gameTitleShort: "a. Détecte les abus",
                gameTitle: "a. Détecte les abus",
                gameText: "Résolutions de cas réels",
                routeName: "/game4a",
                orders: [
                    {
                        title: "Clique sur l’élément suspect dans cet e-mail",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Clique sur l’élément qui présente un risque",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Clique sur la donnée sensible qui ne devrait pas être demandée",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Clique sur le message problématique",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Désactive les notifications de Messenger",
                        allowedTime: 30000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    }
                ]
            },
            {
                gameTitleShort: "b. Résolution de problèmes de base",
                gameTitle: "b. Résolution de problèmes de base",
                gameText: "Résolution de problèmes de base",
                routeName: "/game4b",
                orders: [
                    {
                        title: "Vous êtes en train de remplir un formulaire long et important, et vous souhaitez quitter celui-ci pour le reprendre plus tard. Comment quitter la page sans perdre vos données?",
                        allowedTime: 120000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creer",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Comment faire disparaître ce message sans risque alors que tu souhaites imprimer un document ?",
                        allowedTime: 120000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creer",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Envoie cette image par message (maximum 200 ko)",
                        allowedTime: 120000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creer",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Renomme le fichier \"image_018\" pour qu'il se situe entre \"image_03\" et \"image_05\"",
                        allowedTime: 120000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creer",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    },
                    {
                        title: "Place le fichier \"Image_05\" qui se trouve dans ton téléphone dans un dossier partagé",
                        allowedTime: 120000,
                        feedbacks: {
                            success: {
                                title: 'Bravo, tu as réussi !',
                                text: 'Astuce: pour aller plus loin, un centre de documentation est disponible via l’accueil.',
                                buttonNext: 'Jeu suivant'
                            },
                            failed: {
                                title: "Dommage, ce n’est pas réussi.",
                                text: 'Astuce: un centre de documentation est disponible via l’accueil. Celui-ci te permettra de mieux comprendre tes erreurs.',
                                buttonNext: 'Suivant (passer)',
                                buttonRetry: 'Recommencer',
                            }
                        },
                        score: [
                            {
                                "label": "rechercher",
                                "points": 3
                            },
                            {
                                "label": "naviguer",
                                "points": 3
                            },
                            {
                                "label": "gerer",
                                "points": 3
                            },
                            {
                                "label": "interagir",
                                "points": 3
                            },
                            {
                                "label": "partager",
                                "points": 3
                            },
                            {
                                "label": "creer",
                                "points": 3
                            },
                            {
                                "label": "modifier",
                                "points": 3
                            },
                            {
                                "label": "creatif",
                                "points": 3
                            },
                            {
                                "label": "critique",
                                "points": 3
                            }
                        ]
                    }
                ]
            }
        ]
    },
];

export default gameData;
import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { colors } from '../utils/variables';

const Button = ({to, fn, children, marginTop, disabled, btnStyle, btnColor, type}) => {
  return (
    <ButtonStyled marginTop={marginTop} btnStyle={btnStyle} btnColor={btnColor}>
        {to ? 
            <Link to={to} className="btn" disabled={disabled} type={type}>
                {children}
            </Link>
        : 
            <button onClick={fn} className="btn" disabled={disabled} type={type}>
                {children}
            </button>
        }
        
    </ButtonStyled>
  );
}

const ButtonStyled = styled.div`
    overflow:hidden;
    padding: 0;
    margin-top: ${props => props.marginTop ? '15px' : '0'};

    a.btn,
    button.btn {
        display: flex;
        justify-content: center;
        width: 100%;
        border: 1px solid ${colors.darkblue};
        border-radius: 15px;
        padding: 20px;
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;
        color: ${colors.darkblue};
        background: white;
        box-shadow: 0 5px 0 ${colors.darkblue};
        margin-bottom: 5px;
        transition: all .3s ease-in-out;

        svg {
            margin-right: 20px;
            margin-top: -0.3em;
        }

        ${props => props.btnColor === "danger" ? `
            color: ${colors.failed};
            box-shadow: 0 5px 0 ${colors.failed};
            border-color: ${colors.failed};
        ` : ``}

        &:hover,
        &:focus {
            box-shadow: 0 0 0 ${colors.darkblue};
            margin-top: 5px;
            margin-bottom: 0;
        }

        &[disabled] {
            opacity: .4;
            box-shadow: 0 5px 0 ${colors.darkblue};
            margin-top: 0;
            margin-bottom: 5px;
        }

        ${props => props.btnStyle === "text" ? `
            background: transparent;
            border: 0;
            box-shadow: none;
            
            &:hover,
            &:focus {
                margin-top: 0;
                margin-bottom: 5px;
            }

        ` : ``}
    }
`;

export default Button;

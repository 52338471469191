import styled from 'styled-components';
import { colors } from './variables';

let windowHeight = 0;
const getWindowHeight = () => {
  windowHeight = window.innerHeight;
}
getWindowHeight();


export const Container = styled.div`
    width: calc(100% - 40px);
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 20px;

    .page-title {
        font-size: 26px;
        font-weight: 600;
        color: ${colors.darkblue};
    }

    .section-title {
      font-size: 20px;
      font-weight: 600;
      color: ${colors.darkblue};
    }
`;

export const AppStyled = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: ${windowHeight}px;
`;

export const Top = styled.div`
  height: 64px;
`;

export const GameTop = styled.div`
  height: 90px;
  background: ${colors.darkblue};
`;

export const EndGameTop = styled.div`
  height: 46px;
  background: ${colors.darkblue};
`;

export const Middle = styled.div`
  flex: 1;
  overflow: auto;
  height: calc(${windowHeight}px - 64px - 86px);
  padding: 30px 0;
`;

export const GameMiddle = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 90px);
  padding: 0;
`;

export const EndGameMiddle = styled.div`
  position: relative;
  flex: 1;
  overflow: auto;
  height: calc(${windowHeight}px - 46px);
  padding: 0;
`;

export const Bottom = styled.div`
  height: 86px;
  border-top: 2px solid #E9ECF4;
`;
import React from 'react';
import {useDraggable} from '@dnd-kit/core';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { colors } from '../../../utils/variables';

export function Draggable(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    //transition
  } = useSortable({
    id: props.id,
    data: props.data
  });
  const style = {
    transform,
    border: '1px solid blue',
    borderColor: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue20,
    color: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue,
    boxShadow: attributes['aria-pressed'] === true ? `0 5px 0 ${colors.lightblue}` : `0 5px 0 ${colors.darkblue20}`
  } ? {
    transform: CSS.Translate.toString(transform),
    //transition,
    border: '1px solid blue',
    borderColor: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue20,
    color: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue,
    boxShadow: attributes['aria-pressed'] === true ? `0 5px 0 ${colors.lightblue}` : `0 5px 0 ${colors.darkblue20}`
  } : undefined;
  
  // const style = {
  //   transform: CSS.Translate.toString(transform),
  //   transition,
  //   border: '1px solid blue',
  //   borderColor: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue20,
  //   color: attributes['aria-pressed'] === true ? colors.lightblue : colors.darkblue,
  //   boxShadow: attributes['aria-pressed'] === true ? `0 5px 0 ${colors.lightblue}` : `0 5px 0 ${colors.darkblue20}`
  // };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} className="droppable-item">
      {props.children}
    </div>
  );
}

import { useState, useEffect, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

import OrientationBlocker from '../components/checkOrientation';

import Context from '../utils/context';
import gameData from '../utils/gameData';
import { AppStyled, Top, Middle, Bottom, Container } from '../utils/common-styles';
import { colors } from '../utils/variables';
import Header from '../components/Layout/Header';
import Navigation from '../components/Layout/Navigation';
import ToastWelcome from '../components/toastWelcome';
import Toast from '../components/toast';
import Icon from '../components/Icon';
import Button from '../components/button';
const NotFound = () => {
  const middleScreen = useRef();
  const navigate = useNavigate();


  return (
    <OrientationBlocker>
      <Top>
        <Container>
          <Header />
        </Container>
      </Top>
      <Middle ref={middleScreen}>
        <Container>

          <h1 className="page-title" style={{textAlign: "center"}}>Cette page n'existe pas</h1>
          

          <HomeFooter>
            <Button to="/">Retourner à l'accueil</Button>
          </HomeFooter>

        </Container>
      </Middle>
      <Bottom>
        <Container>
          <Navigation />
        </Container>
      </Bottom>
      
    </OrientationBlocker>
  );
};

const HomeFooter = styled.div`
  margin-top: 65px;
  text-align: center;

  a {
    width: auto !important;
  }
`;

export default NotFound;

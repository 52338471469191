import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';


import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';
import Button from '../../../components/button';

import Area from './area';

import order1Screen1 from '../../../assets/images/game4b/order1/screen1.png';
import order1Screen2 from '../../../assets/images/game4b/order1/screen2.png';

import order2Screen1 from '../../../assets/images/game4b/order2/screen1.png';
import order2Screen2 from '../../../assets/images/game4b/order2/screen2.png';
import order2Screen3 from '../../../assets/images/game4b/order2/screen3.png';

import order3Screen1 from '../../../assets/images/game4b/order3/screen1.png';
import order3Screen1b from '../../../assets/images/game4b/order3/screen1b.png';
import order3Screen1c from '../../../assets/images/game4b/order3/screen1c.png';
import order3Screen1d from '../../../assets/images/game4b/order3/screen1d.png';
import order3Screen2 from '../../../assets/images/game4b/order3/screen2.png';
import order3Screen2b from '../../../assets/images/game4b/order3/screen2b.png';
import order3Screen2c from '../../../assets/images/game4b/order3/screen2c.png';
import order3Screen2d from '../../../assets/images/game4b/order3/screen2d.png';
import order3Screen2e from '../../../assets/images/game4b/order3/screen2e.png';
import order3Screen2e2 from '../../../assets/images/game4b/order3/screen2e2.png';
import order3Screen2f from '../../../assets/images/game4b/order3/screen2f.png';
import order3Screen3 from '../../../assets/images/game4b/order3/screen3.png';
import order3Screen4 from '../../../assets/images/game4b/order3/screen4.png';

import order4Screen1 from '../../../assets/images/game4b/order4/screen1.png';
import order4Screen2 from '../../../assets/images/game4b/order4/screen2.png';
import order4Screen3 from '../../../assets/images/game4b/order4/screen3.png';
import order4Screen4 from '../../../assets/images/game4b/order4/screen4.png';

import order5Screen1 from '../../../assets/images/game4b/order5/screen1.png';
import order5Screen2 from '../../../assets/images/game4b/order5/screen2.png';
import order5Screen3 from '../../../assets/images/game4b/order5/screen3.png';
import order5Screen3b from '../../../assets/images/game4b/order5/screen3b.png';
import order5Screen4 from '../../../assets/images/game4b/order5/screen4.png';
import order5Screen5 from '../../../assets/images/game4b/order5/screen5.png';
import order5Screen6 from '../../../assets/images/game4b/order5/screen6.png';
import order5Screen7 from '../../../assets/images/game4b/order5/screen7.png';
import order5Screen8 from '../../../assets/images/game4b/order5/screen8.png';
import order5Screen8b from '../../../assets/images/game4b/order5/screen8b.png';
import order5Screen9 from '../../../assets/images/game4b/order5/screen9.png';


const Game4b = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {
  const GameContainerEl = useRef();
  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [currentOrderScreen, setCurrentOrderScreen] = useState(0);

  useEffect(() => {
    if(GameContainerEl && GameContainerEl.current) {
      GameContainerEl.current.scrollTo(0, 0);
    }
  }, [orderIndex]);

  useEffect(() => {
    if(!orderScreens[orderIndex][currentOrderScreen].area) {
      setTimeout(() => {
        endGame();
      }, 500);
    }
  }, [currentOrderScreen]);

  const [orderConditions, setOrderConditions] = useState([
    {
      "test1": false,
      "test2": false,
    },
    {},
    {},
    {},
    {},
  ]);

  let orderScreensConditions = [
    {
      "test1": false,
      "test2": false,
    },
    {},
    {},
    {},
    {},
  ];

  const orderScreens = [
    // [
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'red',
    //     area: "order1Screen1"
    //   },
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'blue',
    //     area: "order1Screen1"
    //   },
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'green',
    //     area: "order1Screen1"
    //   },
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'yellow',
    //     area: "order1Screen1"
    //   },
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'cyan',
    //     area: "order1Screen1"
    //   },
    //   {
    //     screen: order1Screen1,
    //     screenBackgroundColor: 'pink',
    //     conditionToShow: () => {
    //       // console.log('orderScreensConditions[orderIndex]', orderScreensConditions[orderIndex]);
    //       return (orderConditions[0].test1 === true && orderConditions[0].test2 === true)
    //     },
    //     failRedirect: 2
    //   }
    // ],
    [
      {
        screen: order1Screen1,
        screenBackgroundColor: '#ffffff',
        area: "order1Screen1"
      },
      {
        screen: order1Screen2,
        screenBackgroundColor: '#ffffff',
      }
    ],
    [
      {
        screen: order2Screen1,
        screenBackgroundColor: '#ffffff',
        area: "order2Screen1"
      },
      {
        screen: order2Screen2,
        screenBackgroundColor: '#ffffff',
        area: "order2Screen2"
      },
      {
        screen: order2Screen3,
        screenBackgroundColor: '#ffffff',
        area: "order2Screen3"
      },
      {
        screen: order2Screen3,
        screenBackgroundColor: '#ffffff',
      }
    ],
    [
      {
        screen: order3Screen1, // 0
        screenBackgroundColor: '#ffffff',
        area: "order3Screen1"
      },
      {
        screen: order3Screen1b, // 1
        screenBackgroundColor: '#ffffff',
        area: "order3Screen1b"
      },
      {
        screen: order3Screen1c, // 2
        screenBackgroundColor: '#ffffff',
        area: "order3Screen1c"
      },
      {
        screen: order3Screen1d, // 3
        screenBackgroundColor: '#ffffff',
        area: "order3Screen1d"
      },
      {
        screen: order3Screen2, // 3 -> 4
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2"
      },
      {
        screen: order3Screen2b, // 4 -> 5
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2b"
      },
      {
        screen: order3Screen2c, // 5 -> 6
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2c"
      },
      {
        screen: order3Screen2d, // 6 -> 7
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2d"
      },
      {
        screen: order3Screen2e, // 7 -> 8
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2e"
      },
      {
        screen: order3Screen2e2, // 9
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2e2"
      },
      {
        screen: order3Screen2f, // 10
        screenBackgroundColor: '#ffffff',
        area: "order3Screen2f"
      },
      {
        screen: order3Screen3, // 11
        screenBackgroundColor: '#ffffff',
      },
      {
        screen: order3Screen4, // 12
        screenBackgroundColor: '#ffffff',
        area: "order3Screen4"
      }
    ],
    [
      {
        screen: order4Screen1,
        screenBackgroundColor: '#000000',
        area: "order4Screen1"
      },
      {
        screen: order4Screen2,
        screenBackgroundColor: '#000000',
        area: "order4Screen2"
      },
      {
        screen: order4Screen3,
        screenBackgroundColor: '#000000',
        area: "order4Screen3"
      },
      {
        screen: order4Screen4,
        screenBackgroundColor: '#000000'
      }
    ],
    [
      {
        screen: order5Screen1, // 0
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen1"
      },
      {
        screen: order5Screen2, // 1
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen2"
      },
      {
        screen: order5Screen3, // 2
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen3"
      },
      {
        screen: order5Screen3b, // 3
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen3b"
      },
      {
        screen: order5Screen4, // 4
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen4"
      },
      {
        screen: order5Screen5, // 5
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen5"
      },
      {
        screen: order5Screen6, // 6
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen6"
      },
      {
        screen: order5Screen7, // 7
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen7"
      },
      {
        screen: order5Screen8, // 8
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen8"
      },
      {
        screen: order5Screen8b, // 9
        screenBackgroundColor: '#F2F2F6',
        area: "order5Screen8b"
      },
      {
        screen: order5Screen9, // 10
        screenBackgroundColor: '#F2F2F6',
      }
    ],
  ];

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          // console.log('send fail');
          onFailOrder();
        }
      }, 1200);
    }
  }, [showCustomFeedback]);

  const nextScreen = (e) => {
    e.stopPropagation();
    if(e.target && e.target.getAttribute('data-none')) {

    } else {

      if(GameContainerEl && GameContainerEl.current) {
        GameContainerEl.current.scrollTo(0, 0);
      }
      const nbrScreen = orderScreens[orderIndex].length;
      // console.log('click Target', e.target);
      if(e.target && e.target.getAttribute('data-var')) {
        // console.log('VAR');
        const newVars = orderConditions;
        if(typeof(orderConditions[orderIndex][e.target.getAttribute('data-var')]) !== "undefined") {
          // console.log('update var', newVars[orderIndex], e.target.getAttribute('data-var'), newVars[orderIndex][e.target.getAttribute('data-var')]);
          // orderConditions[orderIndex][e.target.getAttribute('data-var')] = true;
          
          newVars[orderIndex][e.target.getAttribute('data-var')] = true;
          // console.log('==========> ', newVars, newVars[orderIndex][e.target.getAttribute('data-var')]);
  
          // orderConditions = newVars;
          setOrderConditions(newVars);
          // console.log('orderConditions', orderConditions);
        }
      }
  
      if(e.target && e.target.getAttribute('data-target')) {
        if(
          parseInt(e.target.getAttribute('data-target'), 10) < nbrScreen && 
          orderScreens[orderIndex][parseInt(e.target.getAttribute('data-target'), 10)]
        ){
          // console.log('(a) --> ', parseInt(e.target.getAttribute('data-target'), 10));
          
          showScreen(parseInt(e.target.getAttribute('data-target'), 10));
        } else {
          // console.log('(XXX) --> target not exist', parseInt(e.target.getAttribute('data-target'), 10));
        }
      } else {
        if(currentOrderScreen + 1 < nbrScreen) {
          // console.log('(b) --> ', currentOrderScreen + 1);
          showScreen(currentOrderScreen + 1);
        }
      }

    }
    
  }

  const showScreen = (targetScreenId) => {
    // console.log('showScreen', orderScreens[orderIndex][targetScreenId].conditionToShow);
    if(!orderScreens[orderIndex][targetScreenId].conditionToShow){
      // console.log('NO CONDITION');
      setCurrentOrderScreen(targetScreenId);
    } else if(orderScreens[orderIndex][targetScreenId].conditionToShow) {
      // console.log('CONDITION EXIST');

      if(orderScreens[orderIndex][targetScreenId].conditionToShow()) {
        // console.log('CONDITION EXIST -> OK');
        setCurrentOrderScreen(targetScreenId);
      } else {
        // console.log('CONDITION EXIST -> NO');
        setCurrentOrderScreen(orderScreens[orderIndex][targetScreenId].failRedirect || 0);
      } 
    }
  }


  const endGame = () => {
    setShowCustomFeedback("success");
  }

  const clickOutOfArea = () => {
    setShowCustomFeedback("failed");
  }
  
  return (
    <GameContainer ref={GameContainerEl}>
      {orderScreens[orderIndex][currentOrderScreen] ? (
        <div className="screen-full" style={{backgroundColor: orderScreens[orderIndex][currentOrderScreen].screenBackgroundColor}}>
          <div className="screen-wrapper" style={{backgroundColor: orderScreens[orderIndex][currentOrderScreen].screenBackgroundColor}}>
            <img src={orderScreens[orderIndex][currentOrderScreen].screen} className="screen-image" />
            {orderScreens[orderIndex][currentOrderScreen].screenBottom ? <img src={orderScreens[orderIndex][currentOrderScreen].screenBottom} className="screen-image-bottom" /> : null}
            {orderScreens[orderIndex][currentOrderScreen].area ? (
              <Area name={orderScreens[orderIndex][currentOrderScreen].area} handleClick={nextScreen} clickOutOfArea={clickOutOfArea}/>
            ) : null}
          </div>
        </div>
      ) : null}
       
      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;

  .screen-full {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .screen-wrapper {
    position: relative;
    width: 100%;
  }

  .screen-image {
    display: block;
    width: 100%;
  }

  .screen-image-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
  }

  .clickable-area {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;

    .area {
      fill: rgba(255,0,0,0);
    }
  }

`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game4b;
import styled from 'styled-components';

import { colors } from '../utils/variables';

const GameTimerBar = ({purcentageLeft, timeLeft, purcentageHurryUp}) => {

    return (
        <TimerBarStyled purcentageLeft={purcentageLeft} timeLeft={timeLeft} purcentageHurryUp={purcentageHurryUp}>
            <div className="bar">
                <span className="value">{timeLeft}</span>
            </div>
        </TimerBarStyled>
    )
};

const TimerBarStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: white;

  .bar {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 8px;
    background: ${props => props.purcentageLeft < props.purcentageHurryUp ? colors.failed : colors.lightblue };
    width: ${props => props.purcentageLeft}%;
    transition: width .2s ease-in-out, background-color .8s ease-in-out;

    .value {
        position: absolute;
        top: 100%;
        right: 0;
        transform: translate(50%, 0);
        transition: opacity .8s ease-in-out;
        color: ${colors.failed};
        opacity: ${props => props.purcentageLeft < 40 ? 1 : 0 };
    }
  }
`;

export default GameTimerBar;
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';

import { colors } from '../../../utils/variables';
import Icon from '../../../components/Icon';

import order1Computer from '../../../assets/images/game2a/1/computer.jpg';
import order1Laptop from '../../../assets/images/game2a/1/laptop.jpg';
import order1SecurityCam from '../../../assets/images/game2a/1/security-cam.jpg';
import order1Smartphone from '../../../assets/images/game2a/1/smartphone.jpg';

import order2Mousepad from '../../../assets/images/game2a/2/mousepad.jpg';
import order2Keyboard from '../../../assets/images/game2a/2/keyboard.jpg';
import order2Mouse from '../../../assets/images/game2a/2/mouse.jpg';
import order2Screen from '../../../assets/images/game2a/2/screen.jpg';

import order3Apple from '../../../assets/images/game2a/3/apple.png';
import order3Linux from '../../../assets/images/game2a/3/linux.png';
import order3Windows from '../../../assets/images/game2a/3/windows.jpg';
import order3Safari from '../../../assets/images/game2a/3/safari.png';

import order4Messenger from '../../../assets/images/game2a/4/messenger.png';
import order4Youtube from '../../../assets/images/game2a/4/youtube.png';
import order4Instagram from '../../../assets/images/game2a/4/instagram.png';
import order4Android from '../../../assets/images/game2a/4/android.png';

import order5Messenger from '../../../assets/images/game2a/5/messenger.png';
import order5Tiktok from '../../../assets/images/game2a/5/tiktok.png';
import order5Facebook from '../../../assets/images/game2a/5/facebook.png';
import order5Instagram from '../../../assets/images/game2a/5/instagram.png';
import order5Netflix from '../../../assets/images/game2a/5/netflix.png';

import order3Tiktok from '../../../assets/images/game2a/3/tiktok.png';
import order3Youtube from '../../../assets/images/game2a/3/youtube.png';
import order3Instagram from '../../../assets/images/game2a/3/instagram.png';
import order3Android from '../../../assets/images/game2a/4/android.png';


const Game2a = ({orderIndex, onWinOrder, onWinOrderWithoutFeedback, onFailOrder, onFailOrderWithoutFeedback}) => {

  const [showCustomFeedback, setShowCustomFeedback] = useState(false);

  const [orderItemsArray, setOrderItemsArray] = useState([]);

  useEffect(() => {
    renderChoices();
  }, [orderIndex]);

  useEffect(() => {
    if(showCustomFeedback !== false) {
      setTimeout(() => {
        setShowCustomFeedback(false);
        if(showCustomFeedback === "success") {
          // console.log('send win');
          onWinOrderWithoutFeedback();
        } else if(showCustomFeedback === "failed") {
          onFailOrder();
          // console.log('send fail');
        }
      }, 1000);
    }
  }, [showCustomFeedback]);

  const orders = [
    {
      invalid: [
        {
          label: 'Un ordinateur de bureau',
          visual: order1Computer
        },
        {
          label: 'Un PC portable',
          visual: order1Laptop
        },
        {
          label: 'Un smartphone',
          visual: order1Smartphone
        },
      ],
      valid: [
        {
          label: 'Une camera IP',
          visual: order1SecurityCam
        }
      ]
    },
    {
      invalid: [
        {
          label: 'Un clavier',
          visual: order2Keyboard
        },
        {
          label: 'Une souris',
          visual: order2Mouse
        },
        {
          label: 'Un écran',
          visual: order2Screen
        },
      ],
      valid: [
        {
          label: 'Un tapis de souris',
          visual: order2Mousepad
        }
      ]
    },
    {
      invalid: [
        {
          label: 'TikTok',
          visual: order3Tiktok
        },
        {
          label: 'YouTube',
          visual: order3Youtube
        },
        {
          label: 'Instagram',
          visual: order3Instagram
        },
      ],
      valid: [
        {
          label: 'Android',
          visual: order3Android
        }
      ]
    },
    {
      invalid: [
        {
          label: 'Facebook Messenger',
          visual: order5Messenger
        },
        {
          label: 'Facebook',
          visual: order5Facebook
        },
        {
          label: 'Instagram',
          visual: order5Instagram
        },
      ],
      valid: [
        {
          label: 'Netflix',
          visual: order5Netflix
        }
      ]
    },
    {
      invalid: [
        {
          label: 'Windows',
          visual: order3Windows
        },
        {
          label: 'Apple Mac OS',
          visual: order3Apple
        },
        {
          label: 'Linux',
          visual: order3Linux
        },
      ],
      valid: [
        {
          label: 'Safari',
          visual: order3Safari
        }
      ]
    },
  ];

  const renderChoices = () => {
    const output = [];

    orders[orderIndex].invalid.map((item, itemIndex) => {
      output.push(
        <div key={itemIndex} className="item" onClick={() => {
          setShowCustomFeedback("failed");
        }}>
          <div className="picture">
            <img src={item.visual} alt={item.label} />
          </div>
          <span className="label">{item.label}</span>
        </div>
      );
    });

    output.push(
      <div key={orders[orderIndex].invalid.length} className="item" onClick={() => {
        setShowCustomFeedback("success");
      }}>
        <div className="picture">
          <img src={orders[orderIndex].valid[0].visual} alt={orders[orderIndex].valid[0].label} />
        </div>
        <span className="label">{orders[orderIndex].valid[0].label}</span>
      </div>
    )

    setOrderItemsArray(output.sort(() => (Math.random() > .5) ? 1 : -1));
  };

  return (
    <GameContainer className="game-container-order1">
      <div className="items">
        {orderItemsArray}
      </div>
      <CustomFeedback show={showCustomFeedback} className="custom-feedback">
        <div className={`icon-circle`}>
          <Icon name="check" className="icon-success"/>
          <Icon name="cross" className="icon-failed"/>
        </div>
      </CustomFeedback>
    </GameContainer>
  );
};

const GameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .items {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    justify-content: space-between;

    .item {
      width: calc(50% - 8px);
      margin-bottom: 15px;

      .label {
        font-size: 16px;
        font-weight: 500;
        color: ${colors.darkblue};
      }

      .picture {
        position: relative;
        margin-bottom: 10px;
        border-radius: 15px;
        overflow: hidden;

        &::before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
`;

const CustomFeedback = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.darkblue90};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => props.show !== false ? "all" : "none"};
  opacity: ${props => props.show !== false ? 1 : 0};
  transition: opacity .4s ease-in-out;

  .icon-circle {
    position: relative;
    display: flex;
    width: 68px;
    height: 68px;
    border-radius: 50%;

    .icon-success,
    .icon-failed {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: none;
    }

    ${props => props.show === "success" ? `
      background-color: ${colors.success};
      color: ${colors.darkblue};

      .icon-success {
        opacity: 1;
      }
    ` : ''}; 
    ${props => props.show === "failed" ? `
      background-color: ${colors.failed};
      color: white;

      .icon-failed {
        opacity: 1;
      }
    ` : ''};
  }
`;

export default Game2a;